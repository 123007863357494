import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { LoggingService } from '../../services/LoggingService/logging.service';
import { ErrorService } from '../../services/ErrorService/error.service';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {

    // Error handling is important and needs to be loaded first.
    // Because of this we should manually inject the services with Injector.
    constructor(private injector: Injector, private loggerService :LoggingService) { }

    handleError(error: Error | HttpErrorResponse) {

        const errorService = this.injector.get(ErrorService);
        const logger = this.injector.get(LoggingService);
        //const notifier = this.injector.get(NotificationService);

        let message;
        let stackTrace;

        if (error instanceof HttpErrorResponse) {
            // Server Error
            // message = errorService.getServerMessage(error);
            // stackTrace = errorService.getServerStack(error);
            // console.log(message)
            // console.log(stackTrace)
            // notifier.showError(message);

          } else {
            // Client Error
            message = errorService.getClientMessage(error);
            stackTrace = errorService.getClientStack(error);
            //notifier.showError(message);
        }

        // Always log errors
        console.log(message, stackTrace)
        // const errorMessage =  typeof(stackTrace) == 'object' ? JSON.stringify(stackTrace) : `message:::: ${message}, stackTrace:::: ${stackTrace}`
        // let reqData = {
        //     "source": "SurveyorPanel",
        //     "subSource": "Consolelog",
        //     "surveyorId": 0,
        //     "serviceName": typeof(stackTrace) == 'object' ? stackTrace.serviceName : null,
        //     "methodName": typeof(stackTrace) == 'object' ? stackTrace.methodName : null,
        //     "errorMessage": errorMessage
        // }
        // logger.postErrorOnServer(reqData).subscribe(resp=>{
        //   console.log(resp);
        // })
        //this.loggerService.postErrorOnServer(message, stackTrace)
        //console.error(`message:${message}, stackTrace: ${stackTrace}`);
    }
}
