<header class="main-header">
<div>
  <a  data-toggle="push-menu" class="logo"> <span class="logo-mini"><img [src]="LogoSmall" style="max-width: 50px;width: 100%;height: 48px;" /></span> <span class="logo-lg"><img [src]="Logo" style="width: 225px; height: 50px;"/></span> </a>
  <nav class="navbar navbar-static-top">
      <a class="sidebar-toggle" data-toggle="push-menu" role="button"> <span class="sr-only">Toggle navigation</span> </a>
      <div class="navbar-custom-menu">
          <ul class="nav navbar-nav">
              <!-- <li class="dropdown user user-menu notify_count">
                  <a  class="dropdown-toggle" data-toggle="dropdown">
                      <span class="notification_count">{{Notifications.length}}</span>
                      <i class="glyphicon glyphicon-bell"></i>
                  </a>
                  <ul class="dropdown-menu alert_i">
                      <li *ngFor="let Notification of Notifications" (click)="OpenClaimDetails(Notification.ClaimID);">
                          <label>{{Notification.Title}} ({{Notification.ClaimID}})</label> <span>{{Notification.Time}}</span>
                          <p>{{Notification.Body}}</p>
                          <font><a >Open</a></font>
                      </li>
                  </ul>
              </li> -->
              <li class="dropdown user user-menu">
                  <a class="dropdown-toggle" data-toggle="dropdown"> <i class="glyphicon glyphicon-user"></i> <span class="hidden-xs">{{LoginDetails.Insurer}}</span> </a>
                  <ul class="dropdown-menu">
                      <li class="user-footer">
                          <div class="pull-right"> <a  (click)="Logout();"><i class="fa fa-sign-out"></i> Sign out</a> </div>
                      </li>
                  </ul>
              </li>
          </ul>
      </div>
  </nav>
</div>
</header>
<aside class="main-sidebar">
  <app-sidebar [LoginDetails]="LoginDetails"></app-sidebar>
</aside>

