import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { LoginService } from './../../services/LoginService/login.service';
import { SharedService } from './../../services/SharedService/sharedService';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  UserNameForm! : FormGroup;
  otpSent: boolean = false;
  OTPText: string = 'Send OTP';
  disableOTPButtonOnSent: boolean = false;
  otpVerify: boolean = false;
  OtpData: any;
  respMessage: string = '';
  OTPForm! : FormGroup;
  userDetails:  any;
  userForm: boolean = true;
  constructor(private fb: FormBuilder, private loginService: LoginService, private sharedService: SharedService, private router: Router, private cookieService: CookieService) { }

  ngOnInit(): void {
    this.UserNameForm = this.fb.group({
      UserName: ['', [Validators.required]]
    });

    this.OTPForm = this.fb.group({
      otp: ['', [Validators.required]]
    });

    this.sharedService.userDetailsSubject$.subscribe(data => {
      if(typeof data == 'string'){
        this.userDetails = JSON.parse(data);
      }
      else{
        this.userDetails = data;
      }

    });

    if(Object.keys(this.userDetails).length > 0){
      this.router.navigate(['/dashboard']);
    }

  }

  GetInsurerDetail(){
    this.otpSent = true;
    this.disableOTPButtonOnSent = true;
    let reqData = {
      UserName: btoa(this.UserNameForm.controls['UserName'].value)
    }

    this.loginService.GetInsurerLoginDetails_v2(reqData).subscribe((res)=>{
      //res = {"Data":{"StatusCode":1,"StatusText":"OTP has been sent on your entered mobile no.","MaskMobileNo":"0","MaskEmail":"vik******@policybazaar.com"},"Error":null,"ErrorCode":0}
      if(res.Data!=null){
        this.disableOTPButtonOnSent = false;
        this.otpSent = false;
        this.OtpData = res.Data;
        if (this.OtpData.StatusCode == 1) {
            this.userForm = false;
            this.OTPText = 'Re-Send OTP';
            this.respMessage = "OTP Send Successfully";
        }
        else {
            this.respMessage = this.OtpData.StatusText;
        }
      }
      else{
        this.disableOTPButtonOnSent = false;
        this.otpSent = false;
        this.respMessage = 'User not exist';
      }
    })

  }

  enterKeyPressed(event: any, formType: any){
    if(event.keyCode === 13){
      if(formType == 'userNameForm'){
        this.GetInsurerDetail()
      }
      else{
        this.VerifyOTP()
      }

    }
  }


  VerifyOTP(){
    let reqData = {
      OTP: this.OTPForm.controls['otp'].value,
      Username: btoa(this.UserNameForm.controls['UserName'].value)
    }
    this.loginService.VerifyOtp(reqData).subscribe((res)=>{
      //res = {"Data":{"InsurerId":12,"UserName":"NIA_Rahul","UserType":1,"ProductID":117,"LoginID":16395,"UserId":273,"IsLocked":false,"RemainingAttempt":5,"ValidationKey":"654465e3-2512-43c5-ad19-4b4ac264b353","EncUserTypeID":"PBCLAIM7917","EncUserType":"bjxACIJ/7stIgllEs955BA==","EncUserId":"H99vWjaSV1UaWlK/mbzlAA=="},"Error":null,"ErrorCode":0}
      if (res.Data != null) {
          this.userDetails = {
            ProductID : res.Data.ProductID,
            InsurerId : res.Data.InsurerId,
            UserType : res.Data.UserType,
            EncUserTypeID : res.Data.EncUserTypeID,
            EncUserType : res.Data.EncUserType,
            EncUserID : res.Data.EncUserId,
            Insurer : res.Data.UserName,
            LoginID : res.Data.LoginID,
            UserId : res.Data.UserId,
            Source : 'Insurer'
          };

        this.cookieService.set("LoginKey", res.Data.LoginID, { secure: true, path: '/', domain: window.origin.includes('localhost') ? 'localhost':'.policybazaar.com' });
        this.cookieService.set("UserKey", res.Data.UserId, { secure: true, path: '/', domain: window.origin.includes('localhost') ? 'localhost':'.policybazaar.com' });
        this.cookieService.set("ValidationKey", res.Data.ValidationKey, { secure: true, path: '/', domain: window.origin.includes('localhost') ? 'localhost':'.policybazaar.com' });
        this.sharedService.setUserDetails(this.userDetails)
        localStorage.setItem('currentUser', JSON.stringify(this.userDetails));
        this.router.navigate(['/dashboard']);
      }
      else {
          this.respMessage = "Authentication Failed";
      }
    })
  }

}
