import { Component } from '@angular/core';
import { LoaderService } from './services/LoaderService/loader.service';
import { SharedService } from './services/SharedService/sharedService';
import { Router, ActivatedRoute, NavigationEnd, NavigationStart } from '@angular/router';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  constructor(private router: Router, private loaderService: LoaderService, private sharedService: SharedService, private activatedRoute: ActivatedRoute){ }
  title = 'surveyor-panel';
  loader$ = this.loaderService.loader$
  LoginDetails: any;
  loggedIn: boolean = false;

  ngOnInit(){
    this.sharedService.userDetailsSubject$.subscribe(data => {
      if(typeof data == 'string'){
        this.LoginDetails = JSON.parse(data);
      }
      else{
        this.LoginDetails = data;
      }
    });

      if(Object.keys(this.LoginDetails).length > 0){
        // this.GetDashboard();
        // this.GetDashboardCount();
        // this.GetInsurerOpenClaimsCount();
        this.loggedIn = true;
      }else{
        this.loggedIn = false;
        if(!window.location.href.includes('feedback') && !window.location.href.includes('dynamic-forms')){
          this.router.navigate(['/login'])
        }
      }
  }
  ngOnChanges(){
    if(Object.keys(this.LoginDetails).length > 0){
      this.loggedIn = true;
    }else{
      this.loggedIn = false;
      if(!window.location.href.includes('feedback') && !window.location.href.includes('dynamic-forms')){
        this.router.navigate(['/login'])
      }
    }

  }
}
