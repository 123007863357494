import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-previous-year-policy',
  templateUrl: './previous-year-policy.component.html',
  styleUrls: ['./previous-year-policy.component.css']
})
export class PreviousYearPolicyComponent implements OnInit {

  constructor() { }
  @Input() LoginDetails: any;
  @Input() ClaimDetail: any;
  ngOnInit(): void {
  }

}
