import { Component, OnInit, Input, SimpleChanges } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { SurveyorService } from './../../../services/SurveyorService/surveyor.service';

@Component({
  selector: 'app-comment-log',
  templateUrl: './comment-log.component.html',
  styleUrls: ['./comment-log.component.css']
})
export class CommentLogComponent implements OnInit {

  constructor(private surveyorService: SurveyorService, private fb: FormBuilder) { }
  @Input() LoginDetails: any;
  @Input() ClaimDetail: any;
  userImg = 'assets/images/user.png';
  ClaimHistory: any;
  CommentForm!: FormGroup;
  ErrorMsg: string = '';
  ngOnInit(): void {
    if(this.ClaimDetail){
      this.CommentForm = this.fb.group({
        comment: ['', [Validators.required]]
      })
      // this.GetClaimHistory()
    }
  }

  ngOnChanges(changes: SimpleChanges){
    if(changes['ClaimDetail'] && !this.ClaimHistory){
      this.GetClaimHistory()
    }
  }

  SetComments(){

    let Comments = {
      ClaimId: this.ClaimDetail.ClaimId,
      Comment: "",
      UserId: this.LoginDetails.UserId,
      CommentSource: this.LoginDetails.Source,
      CommentType: 6
  };
    if(this.CommentForm.controls['comment'].value){
      Comments.Comment = this.CommentForm.controls['comment'].value
      this.surveyorService.SetComments(Comments).subscribe((res: any)=>{
        if(res.Data){
          this.CommentForm.patchValue({
            comment: ''
          })
          this.GetClaimHistory()
          //this.GetClaimDetails
        }
      })
    }
    else{
      alert("Please enter comments!")
    }


  }

  GetClaimHistory(){
    let reqData = `${this.ClaimDetail.ClaimId}/${this.LoginDetails.Source}/2`
    if(this.ClaimDetail.ClaimId != 0 && this.LoginDetails?.Source) {
      this.surveyorService.GetClaimHistory(reqData).subscribe((res: any)=>{
        if(res){
          this.ClaimHistory = res.Data
        }
        else{
          this.ErrorMsg = "Error: Something Went Wrong!"
        }
      })
    }
  }
}
