import { Input } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Observable } from 'rxjs';


@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.css']
})
export class LoaderComponent implements OnInit {
  @Input() loader$!: Observable<boolean>;
  IsLoading!: boolean;
  subscription!: Subscription;

  constructor() {

  }

  ngOnInit(): void {
    this.subscription = this.loader$.subscribe(res => {
      this.IsLoading = res;
    })
  }


}
