import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SharedService {
  private userDetailsSubject: BehaviorSubject<boolean> = new BehaviorSubject<any>(localStorage.getItem('currentUser') || {});
  userDetailsSubject$!: Observable<any>;
  private encClaimIdSubject: BehaviorSubject<boolean> = new BehaviorSubject<any>('');
  encClaimIdSubject$!: Observable<any>;
  private estimateDetailsSubject: BehaviorSubject<boolean> = new BehaviorSubject<any>({});
  estimateDetailsSubject$!: Observable<any>;
  private paymentDetailSubject: BehaviorSubject<boolean> = new BehaviorSubject<any>({});
  paymentDetailSubject$!: Observable<any>;
  private garageDetailSubject: BehaviorSubject<boolean> = new BehaviorSubject<any>({});
  garageDetailSubject$!: Observable<any>;
  private statusMasterSubject: BehaviorSubject<boolean> = new BehaviorSubject<any>({});
  statusMasterSubject$!: Observable<any>;
  private willCallSubject: BehaviorSubject<boolean> = new BehaviorSubject<any>({});
  willCallSubject$!: Observable<any>;


  constructor() {
    this.userDetailsSubject$ = this.userDetailsSubject.asObservable();
    this.encClaimIdSubject$ = this.encClaimIdSubject.asObservable();
    this.estimateDetailsSubject$ = this.estimateDetailsSubject.asObservable();
    this.paymentDetailSubject$ = this.paymentDetailSubject.asObservable();
    this.garageDetailSubject$ = this.garageDetailSubject.asObservable();
    this.statusMasterSubject$ = this.statusMasterSubject.asObservable();
    this.willCallSubject$ = this.willCallSubject.asObservable();
  }

  setUserDetails(value: any) {
    this.userDetailsSubject.next(value);
  }

  setEncClaimId(value: any){
    this.encClaimIdSubject.next(value);
  }

  setEstimateDetails(value: any){
    this.estimateDetailsSubject.next(value)
  }

  setPaymentDetails(value: any){
    this.paymentDetailSubject.next(value)
  }
  setGarageDetails(value: any){
    this.garageDetailSubject.next(value)
  }

  setStatusMaster(value: any){
    this.statusMasterSubject.next(value)
  }

  setWillCall(value: any) {
    this.willCallSubject.next(value)
  }
}
