import {
  AfterViewInit,
  Component,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { EventEmitter } from '@angular/core';
import { FormControl } from '@angular/forms';
import * as moment from 'moment';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-pb-table',
  templateUrl: './pb-table.component.html',
  styleUrls: ['./pb-table.component.css'],
})
export class PbTableComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() tableData: any = [];
  @Input() displayColumns: any;
  finalColumns: any = [];
  @Input() labels;
  @Input() isPagination: boolean;
  @Input() isScroll: boolean;
  @Input() dateFormat: any;
  @Input() exportFunction: boolean;
  @Input() sortByColumn: any;
  @Input() maintainSelection: any;
  @Input() sortDirection: any;
  @Input() isSearchable: any;
  @Input() fullWidthSearch: any;
  @Input() pageSizeArray: any;
  @Input() maxPageSize: any;
  @Input() addLengthToPageArray: any;
  @Input() highPerformanceExport: boolean;
  @Input() sortingActive: boolean = true;
  @Output() iconClicked = new EventEmitter();
  @Output() linkClicked = new EventEmitter();
  @Output() selectionChanged = new EventEmitter();
  @Output() exportTable = new EventEmitter();
  @Output() buttonClick = new EventEmitter();
  isDone: boolean = false;
  keys: any;
  selectedData: any = [];
  allCheck: boolean = false;
  exportTableData: any;
  tableDataNew: any;

  extraLabelData: any = {};

  tableSearch: FormControl = new FormControl('');

  selectedPageSize: any = 20;
  selectedPageIndex: any = 0;

  @Input() selectOnlyDisplayed: boolean;
  @Input() refreshTable: boolean;

  @ViewChild('paginator') paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  constructor() {}

  ngOnInit(): void {
    this.highPerformanceExport = this.highPerformanceExport ? this.highPerformanceExport : false;
    this.refreshTable = this.refreshTable ? this.refreshTable : false;
    this.addLengthToPageArray = this.addLengthToPageArray ? this.addLengthToPageArray : false;
    this.maxPageSize = this.maxPageSize ? this.maxPageSize : true;
    this.pageSizeArray = this.pageSizeArray ? this.pageSizeArray : [5, 10, 20];
    if(!this.maxPageSize) {
      this.selectedPageSize = this.pageSizeArray[0];
    }
    else {
      this.selectedPageSize = this.pageSizeArray[this.pageSizeArray.length-1]
    }
    this.isSearchable = this.isSearchable ? this.isSearchable : false;
    this.fullWidthSearch = this.fullWidthSearch ? this.fullWidthSearch : false;
    this.sortDirection = this.sortDirection ? this.sortDirection : 'desc';
    this.selectOnlyDisplayed = this.selectOnlyDisplayed
      ? this.selectOnlyDisplayed
      : false;
    this.maintainSelection = this.maintainSelection
      ? this.maintainSelection
      : false;
    this.isPagination = this.isPagination ? this.isPagination : false;
    this.isScroll = this.isScroll ? this.isScroll : false;
    this.dateFormat = this.dateFormat ? this.dateFormat : 'mediumDate';
    this.exportFunction = this.exportFunction ? this.exportFunction : false;
    this.formColumns();
    this.formExtraColumnData();

    this.tableSearch.valueChanges.subscribe((changes) => {
      this.tableDataNew = this.tableData.filter((tableItem: any) => {
        if (this.labels.findIndex((item) => item.includes('date')) == -1) {
          if (
            Object.values(tableItem)
              .toString()
              .toLowerCase()
              .replaceAll(',', ' ')
              .includes(changes.toLowerCase())
          ) {
            return tableItem;
          }
        } else {
          let dateOccurence = this.labels.reduce((array, item, index) => {
            if (item.toLowerCase().includes('date')) {
              array.push(index);
            }
            return array;
          }, []);
          let itemValues = tableItem;
          dateOccurence.forEach((occurence) => {
            itemValues[this.finalColumns[occurence]] = moment(
              itemValues[this.finalColumns[occurence]]
            )
              .format('LLLL')
              .toString();
          });
          if (
            Object.values(itemValues)
              .toString()
              .toLowerCase()
              .replaceAll(',', ' ')
              .includes(changes.toLowerCase())
          ) {
            return tableItem;
          }
        }
      });
      if(this.addLengthToPageArray) {
        this.pageSizeArray.push(this.tableDataNew.length)
      }
      this.tableDataNew = new MatTableDataSource(this.tableDataNew);
      this.tableDataNew.paginator = this.paginator;
      this.tableDataNew.sort = this.sort;
    });
  }

  linkClick(element) {
    this.linkClicked.emit(element);
  }

  formExtraColumnData() {
    for (let i = 0; i < this.labels.length; i++) {
      let splitLabel = this.labels[i].split('/');
      if (splitLabel.length > 1) {
        this.extraLabelData[this.finalColumns[i]] = splitLabel;
      } else {
        this.extraLabelData[this.finalColumns[i]] = [];
      }
    }
  }

  checkSplitLength(label) {
    return label.split('/').length;
  }

  checkExtraLabel(extraLabel, number, isGreater) {
    if (extraLabel) {
      if (isGreater) {
        return extraLabel.length > number;
      } else {
        return extraLabel.length <= number;
      }
    } else {
      return false;
    }
  }

  updatePageSize(event) {
    this.selectedPageSize = event.pageSize;
    this.selectedPageIndex = event.pageIndex;
    if (this.selectOnlyDisplayed) {
      // let event = {
      //   checked: this.allCheck,
      // };
      // this.changeAll(event);
      this.tableDataNew.sortData(this.tableDataNew.data, this.tableDataNew.sort)
      let isCheckAll = Array.from(new Set(this.tableDataNew.filteredData.slice(
        this.selectedPageIndex * this.selectedPageSize,
        this.selectedPageIndex * this.selectedPageSize +
          this.selectedPageSize
      ).map(item => item.isSelected)));
      if(isCheckAll.length == 1 && isCheckAll[0]) {
        this.allCheck = true;
      }
      else {
        this.allCheck = false
      }
    }
  }

  selectionChange(event, element, isArray = false, index = 0) {
    if (isArray) {
      element.isSelected[index] = event.checked;
    } else {
      element.isSelected = event.checked;
    }
    if (event.checked) {
      if (!isArray) {
        this.checkForAll();
        this.selectedData.push(element);
      } else {
        if (this.selectedData.indexOf(element) == -1) {
          this.selectedData.push(element);
        }
      }
    } else {
      let findIndex = this.selectedData.indexOf(element);
      if (!isArray) {
        this.allCheck = false;
        if (findIndex > -1) {
          this.selectedData.splice(findIndex, 1);
        }
      } else {
        let isRemove = Array.from(new Set(element.isSelected));
        if (!isRemove[0] && isRemove.length == 1) {
          this.selectedData.splice(findIndex, 1);
        }
      }
    }
    this.selectedData = Array.from(new Set(this.selectedData));
    this.selectionChanged.emit(this.selectedData);

    // if (this.maintainSelection) {
    //   let dataToSend = {
    //     removeIndex: event.checked ? null : element,
    //     selectedData: this.selectedData,
    //   };
    //   this.selectionChanged.emit(dataToSend);
    // } else {
    //   this.selectionChanged.emit(this.selectedData);
    // }
  }

  checkForAll() {
    let allBool = true;
    if (this.selectOnlyDisplayed) {
      let checkForAll = Array.from(new Set(this.tableDataNew.filteredData.slice(this.selectedPageIndex * this.selectedPageSize, this.selectedPageIndex * this.selectedPageSize + this.selectedPageSize).map(item => item.isSelected)));
      if(checkForAll.length == 1 && checkForAll[0]) {
        this.allCheck = true;
      }
      else {
        this.allCheck = false;
      }
    } else {
      this.tableDataNew.filteredData.forEach((item: any) => {
        if (!item.isSelected) {
          allBool = false;
        }
      });
      this.allCheck = allBool;
    }
  }

  formColumns() {
    this.finalColumns = [];
    for (let i = 0; i < this.displayColumns.length; i++) {
      if (typeof this.displayColumns[i] == 'object') {
        this.finalColumns.push(this.displayColumns[i][0]);
      } else {
        this.finalColumns.push(this.displayColumns[i]);
      }
    }
  }

  buttonClicked(element, label) {
    let buttonData = {
      label: label,
      element: element,
    };

    this.buttonClick.emit(buttonData);
  }

  ngAfterViewInit(): void {
    if(this.paginator) {
      this.paginator._formFieldAppearance = 'outline';
    }
    this.tableDataNew = new MatTableDataSource(this.tableData);
    this.exportTableData = new MatTableDataSource(this.tableData);
    this.addSelectionColumns(
      this.tableData,
      this.labels.toString().includes('multiselect')
    );
    this.customMultiSelectSorting();
    this.tableDataNew.sort = this.sort;
    this.isDone = true;
    if (this.exportFunction) {
      setTimeout(() => {
        this.exportExcel();
      }, 1000);
    }
    if (this.isPagination) {
      if (this.paginator) {
        this.tableDataNew.paginator = this.paginator;
      } else {
        setTimeout(() => {
          this.tableDataNew.paginator = this.paginator;
        }, 50);
      }
    }
  }

  addSelectionColumns(data, addMulti = false) {
    if (data && !addMulti) {
      if (data[0]?.isSelected == undefined) {
        data = data.filter((item: any) => {
          item.isSelected = false;
          item.isView = true;
          return item;
        });
      } else {
        data.forEach((item: any) => {
          if (item.isSelected && this.selectedData.indexOf(item) == -1) {
            this.selectedData.push(item);
          }
        });
        this.selectedData = Array.from(new Set(this.selectedData));
        this.selectionChanged.emit(this.selectedData);

        // if (this.maintainSelection) {
        //   let dataToSend = {
        //     removeIndex: null,
        //     selectedData: this.selectedData,
        //   };
        //   this.selectionChanged.emit(dataToSend);
        // } else {
        //   this.selectionChanged.emit(this.selectedData);
        // }
        if (this.tableDataNew) {
          this.checkForAll();
        }
      }
    } else if (data && addMulti) {
      if (data[0]?.isSelected == undefined) {
        data = data.filter((item: any) => {
          item.isSelected = [];
          let length =
            item[
              this.displayColumns[
                this.labels.findIndex((item) =>
                  item.toString().includes('multiselect')
                )
              ]
            ].length;
          for (let i = 0; i < length; i++) {
            item.isSelected.push(false);
          }
          item.isView = true;
          return item;
        });
      } else {
        data.forEach((item: any) => {
          if (item.isSelected.toString().includes('true')) {
            this.selectedData.push(item);
          }
        });
        this.selectedData = Array.from(new Set(this.selectedData));
        this.selectionChanged.emit(this.selectedData);

        // if (this.maintainSelection) {
        //   let dataToSend = {
        //     removeIndex: null,
        //     selectedData: this.selectedData,
        //   };
        //   this.selectionChanged.emit(dataToSend);
        // } else {
        //   this.selectionChanged.emit(this.selectedData);
        // }
      }
    }
  }

  iconClick(element, label) {
    let iconData = {
      label: label,
      element: element,
    };
    this.iconClicked.emit(iconData);
  }

  checkLabels(checkItem, toCheck) {
    if (typeof toCheck == 'object') {
      let foundToCheck = false;
      for (let i = 0; i < toCheck.length; i++) {
        if (checkItem.toString().toLowerCase().includes(toCheck[i])) {
          foundToCheck = true;
          break;
        }
      }
      return foundToCheck;
    } else {
      if(checkItem) {
        return checkItem.toString().toLowerCase().includes(toCheck);
      }
      else {
        return false;
      }
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.isDone) {
      this.allCheck = false;
      if(!this.maintainSelection || this.refreshTable) {
        this.selectedData = [];
      }
      else {
        this.updateMaintaintedData() 
      }
      this.formColumns();
      this.formExtraColumnData();
      this.tableDataNew = new MatTableDataSource(this.tableData);
      this.exportTableData = new MatTableDataSource(this.tableData);
      this.customMultiSelectSorting();
      this.tableDataNew.sort = this.sort;
      if (this.exportFunction) {
        setTimeout(() => {
          this.exportExcel();
        }, 1000);
      }
      if (this.isPagination) {
        if (this.paginator) {
          this.tableDataNew.paginator = this.paginator;
        } else {
          setTimeout(() => {
            this.tableDataNew.paginator = this.paginator;
          }, 50);
        }
      }
    }
    if ((changes['sortDirection'] || changes['sortByColumn']) && this.sort) {
      this.tableDataNew.sort.active = changes['sortByColumn'] ? changes['sortByColumn'].currentValue : this.tableDataNew.sort.active;
      this.tableDataNew.sortData(this.tableDataNew.data, this.tableDataNew.sort);
      this.tableDataNew.data = this.tableDataNew.data;
    }
    if (this.tableData && this.tableData?.length != 0) {
      this.addSelectionColumns(
        this.tableData,
        this.labels.toString().includes('multiselect')
      );
      this.keys = Object.keys(this.tableData[0]);
    }
  }

  exportExcel() {
    if(this.highPerformanceExport) {
      let exportData = [];
      this.tableData.forEach((item: any) => {
        let objToPush = {};
        this.displayColumns.forEach((subItem, index) => {
          if(this.checkLabels(this.labels[index], 'date')) {
            objToPush[subItem] = item[subItem] > 0 ? moment(item[subItem]).format('YYYY-MM-DD').toString() : "--";
          }
          else {
            objToPush[subItem] = item[subItem] ? item[subItem] : !Number.isNaN(parseInt(item[subItem])) ? item[subItem] : '--';
          }
        })
        exportData.push(objToPush);
      })
      const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(exportData);
      const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
      const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
      const data: Blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      this.exportTable.emit(data);
    }
    else {
      {
        var table = <HTMLTableElement>document.getElementById('exportTable');
        var tab_text = "<table border='2px'><tr bgcolor='#87AFC6'>";
        var textRange;
        var j = 0;
  
        if (table.rows.length > 1) {
          for (j = 0; j < table.rows.length; j++) {
            tab_text = tab_text + table.rows[j].innerHTML + '</tr>';
            //tab_text=tab_text+"</tr>";
          }
  
          tab_text = tab_text + '</table>';
          tab_text = tab_text.replace(/<A[^>]*>|<\/A>/g, ''); //remove if u want links in your table
          tab_text = tab_text.replace(/<img[^>]*>/gi, ''); //remove if u want images in your table
          tab_text = tab_text.replace(/<input[^>]*>|<\/input>/gi, ''); //reomves input params
  
          var ua = window.navigator.userAgent;
          var msie = ua.indexOf('MSIE ');
          var sa =
            'data:application/vnd.ms-excel,' + encodeURIComponent(tab_text);
        }
        this.exportTable.emit(sa);
      }
    }


  }

  changeAll(event) {
    this.allCheck = event.checked;
    if (this.selectOnlyDisplayed) {
      let shouldSkip = false;
      this.tableDataNew.filteredData.forEach((item: any, index) => {
        if (shouldSkip) {
          return;
        }
        //console.log('checked');
        if(index >= (this.selectedPageIndex * this.selectedPageSize) && index < ((this.selectedPageIndex * this.selectedPageSize) + this.selectedPageSize)) {
          item.isSelected = event.checked;
        }
        else if(index >= ((this.selectedPageIndex * this.selectedPageSize) + this.selectedPageSize)) {
          shouldSkip = true;
        }
      });
    } else {
      this.tableDataNew.filteredData.forEach((item: any) => {
        item.isSelected = event.checked;
      });
    }

    if (event.checked) {
      if(this.selectOnlyDisplayed) {
        this.selectedData.push(this.tableDataNew.filteredData.slice(
          this.selectedPageIndex * this.selectedPageSize,
          this.selectedPageIndex * this.selectedPageSize +
            this.selectedPageSize
        ))
        this.selectedData = this.selectedData.flat();
      }
      else {
        this.selectedData = this.tableDataNew.filteredData.slice();
      }
    } else {
      if(this.selectOnlyDisplayed) {
        let values = this.tableDataNew.filteredData.slice(
          this.selectedPageIndex * this.selectedPageSize,
          this.selectedPageIndex * this.selectedPageSize +
            this.selectedPageSize
        );
        let toRemoveLength = values.length;
        let startingIndex = this.selectedData.indexOf(values[0]);
       this.selectedData.splice(startingIndex, toRemoveLength)
      }
      else {
        this.selectedData = [];
      } 
    }
    this.selectedData = Array.from(new Set(this.selectedData));
    this.selectionChanged.emit(this.selectedData);

    // if (this.maintainSelection) {
    //   let dataToSend = {
    //     removeIndex: event.checked ? 'none' : 'all',
    //     selectedData: this.selectedData,
    //   };
    //   this.selectionChanged.emit(dataToSend);
    // } else {
    //   this.selectionChanged.emit(this.selectedData);
    // }
  }

  checkColumn(toCheck) {
    if (toCheck.toString().toLowerCase().includes('/')) {
      return toCheck.split('/');
    } else {
      return [toCheck];
    }
  }

  checkAvail(toCheck) {
    return typeof toCheck;
  }

  customMultiSelectSorting() {
    let multiSelectIndex = this.labels.findIndex((item) =>
      item.includes('multiselect')
    );
    if (multiSelectIndex != -1) {
      this.tableDataNew.sortData = (data: any, sort: MatSort) => {
        return data.sort((a: any, b: any) => {
          let comparatorResult = 0;
          switch (sort.active) {
            case this.finalColumns[multiSelectIndex]:
              let aPoints = 0;
              let bPoints = 0;
              if (a.isSelected.length > b.isSelected.length) {
                aPoints += 1;
              } else if (a.isSelected.length < b.isSelected.length) {
                bPoints += 1;
              }

              if (
                this.countInstances(a.isSelected, true) >
                this.countInstances(b.isSelected, true)
              ) {
                aPoints += 3;
              } else if (
                this.countInstances(a.isSelected, true) <
                this.countInstances(b.isSelected, true)
              ) {
                bPoints += 3;
              }
              if (
                Array.from(new Set(a.isSelected)).length == 1 &&
                Array.from(new Set(a.isSelected))[0]
              ) {
                aPoints += 4;
              } else if (
                Array.from(new Set(b.isSelected)).length == 1 &&
                Array.from(new Set(b.isSelected))[0]
              ) {
                bPoints += 4;
              }
              comparatorResult =
                aPoints < bPoints ? 1 : aPoints == bPoints ? 0 : -1;
              break;
            default:
              comparatorResult = !Number.isNaN(parseInt(a[sort.active]))
                ? parseInt(a[sort.active]) < parseInt(b[sort.active])
                  ? 1
                  : a[sort.active] == b[sort.active]
                  ? 0
                  : -1
                : a[sort.active].localeCompare(b[sort.active]);
              break;
          }
          return comparatorResult * (sort.direction == 'asc' ? 1 : -1);
        });
      };
    }
  }

  countInstances(data, toCount) {
    let count = 0;
    data.forEach((item) => {
      if (item == toCount) {
        count += 1;
      }
    });
    return count;
  }

  updateMaintaintedData() {
    this.selectedData.forEach((item: any) => {
      let index = this.tableData.indexOf(item);
      if(index != -1) {
        this.tableData[index].isSelected = true;
      }
    })
  }
}
