import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { degrees, PDFDocument, rgb, StandardFonts } from 'pdf-lib';
import { MatDialog } from '@angular/material/dialog';
import Cropper from 'cropperjs';

declare let html2pdf: any;
import * as download from 'downloadjs';
import { DomSanitizer } from '@angular/platform-browser';
import { CropperComponent } from 'angular-cropperjs';
@Component({
  selector: 'app-dynamic-digital-forms',
  templateUrl: './dynamic-digital-forms.component.html',
  styleUrls: ['./dynamic-digital-forms.component.css'],
})
export class DynamicDigitalFormsComponent implements OnInit {
  exportPDF: boolean = false;
  mainFormSelectionControl = new FormControl('united');
  unitedFormGroup = new FormGroup({
    policyNo: new FormControl('', [
      Validators.required,
      Validators.maxLength(20),
    ]),
    insuredName: new FormControl('', Validators.required),
    insuredAddress: new FormControl('', [
      Validators.required,
      Validators.maxLength(260),
    ]),
    insuredPincode: new FormControl('', [
      Validators.required,
      Validators.minLength(6),
      Validators.maxLength(6),
      Validators.pattern(/^-?(0|[1-9]\d*)?$/)
    ]),
    insuredMobile: new FormControl('', [
      Validators.required,
      Validators.minLength(10),
      Validators.maxLength(10),
      Validators.pattern(/^-?(0|[1-9]\d*)?$/)
    ]),
    insuredSTDCode: new FormControl('', [Validators.maxLength(5), Validators.pattern(/^-?(0|[1-9]\d*)?$/)]),
    insuredLandline: new FormControl('', [Validators.maxLength(8), Validators.pattern(/^-?(0|[1-9]\d*)?$/)]),
    insuredEmail: new FormControl('', Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$")),
    vehicleReg: new FormControl('', [
      Validators.maxLength(13),
      Validators.required,
    ]),
    vehicleChassis: new FormControl('', [Validators.required]),
    vehicleEngine: new FormControl('', Validators.required),
    vehicleMake: new FormControl('', Validators.required),
    vehicleModel: new FormControl('', Validators.required),
    hypoDetails: new FormControl('', [
      Validators.required,
      Validators.maxLength(210),
    ]),
    dateLoss: new FormControl('', Validators.required),
    timeLoss: new FormControl('', Validators.required),

    placeLoss: new FormControl('', Validators.required),
    driverName: new FormControl('', Validators.required),
    driverAddress: new FormControl('', Validators.required),
    driverNumber: new FormControl('', Validators.required),
    expiryDate: new FormControl('', Validators.required),
    issuingRTO: new FormControl('', Validators.required),
    underInfluence: new FormControl('', Validators.required),
    wasInjured: new FormControl('', Validators.required),
    accidentDescription: new FormControl('', Validators.required),
    pillionCarried: new FormControl(false),
    occupantsCarried: new FormControl('', [
      Validators.required,
      Validators.maxLength(2),
    ]),
    workshopAddress: new FormControl('', Validators.maxLength(90)),
    workshopContact: new FormControl('', Validators.maxLength(32)),
    estimatedLoss: new FormControl('', Validators.maxLength(7)),
    workshopMobile: new FormControl('', Validators.maxLength(28)),
    workshopPhone: new FormControl('', Validators.maxLength(28)),
    workshopFax: new FormControl('', Validators.maxLength(26)),
    workshopEmail: new FormControl('', Validators.maxLength(36)),
    theftVehicle: new FormControl(false),
    theftEffects: new FormControl(false),
    theftReported: new FormControl('', Validators.required),
    notReasons: new FormControl(''),
    reportingDate: new FormControl(''),
    stationName: new FormControl(''),
    firNo: new FormControl('', Validators.maxLength(14)),
    thirdPartyInvolved: new FormControl('', Validators.required),
    thirdPartyLoss: new FormControl(''),
    driverInjured: new FormControl(''),
    occupantsInjured: new FormControl(false),
    witnessName: new FormControl(''),
    witnessAddress: new FormControl(''),
    witnessPhone: new FormControl(''),
    courtesyCar: new FormControl(false),
    expectedRepairDate: new FormControl(''),
    medicalRequired: new FormControl(false),
    likelyExpense: new FormControl(''),
    lossEffects: new FormControl(''),
    accountNumber: new FormControl('', [
      Validators.required,
      Validators.maxLength(17),
    ]),
    bankName: new FormControl('', Validators.required),
    branchName: new FormControl('', Validators.required),
    ifscCode: new FormControl('', [
      Validators.required,
      Validators.maxLength(17),
    ]),
    dateOverall: new FormControl('', Validators.required),
    placeOverall: new FormControl('', Validators.required),
  });
  nationalFormGroup: FormGroup = new FormGroup({
    policyNo: new FormControl(''),
    claimNo: new FormControl(''),
    vehicleNo: new FormControl(''),
    engineNo: new FormControl(''),
    chassisNo: new FormControl(''),
    insuredName: new FormControl(''),
    insuredAdd: new FormControl(''),
    insuredMobile: new FormControl(''),
    insuredEmail: new FormControl(''),
    insuredDetails: new FormControl(''),
  });

  stepDetails = [
    'Insured Details',
    'Vehicle Details',
    'Loss Details',
    'Driver Details',
    'Accident Details',
    'Workshop Details',
    'Theft Details',
    'Add-On Covers',
    'Insured Bank Details',
    'Declaration'
  ]

  stepGroups = [
    ['policyNo insuredName insuredAddress insuredPincode insuredMobile insuredSTDCode insuredLandline insuredEmail'],
    ['vehicleReg vehicleChassis vehicleEngine vehicleMake vehicleModel hypoDetails'],
    ['dateLoss timeLoss placeLoss'],
    ['driverName driverNumber expiryDate issuingRTO underInfluence wasInjured driverAddress'],
    ['accidentDescription pillionCarried occupantsCarried'],
    ['workshopContact estimatedLoss workshopMobile workshopPhone workshopFax workshopEmail workshopAddress'],
    ['theftVehicle theftEffects theftReported reportingDate stationName firNo notReasons'],
    ['courtesyCar expectedRepairDate medicalRequired likelyExpense lossEffects'],
    ['accountNumber bankName branchName ifscCode'],
    ['dateOverall placeOverall']
  ]
  currentIndex = 0;
 
  previewImage: any;
  cropper: any;
  croppedDimension: any;
  cropperConfig = {
    aspectRatio: 19 / 4,
    viewMode: 0,
    modal: true,
    responsive: true,
    dragMode: 'move',
    background: true,
    movable: true,
    rotatable: true,
    scalable: true,
    zoomable: true,
    cropBoxResizable: false,
    toggleDragModeOnDblclick: false,
  };

  finalImage: any;
  maxDate = new Date();
  imageAttached: boolean = false;

  @ViewChild('cropperModal') cropperModal: TemplateRef<any>;
  @ViewChild('angularCropper') angularCropper: CropperComponent;
  constructor(private matDialog: MatDialog, private sanitizer: DomSanitizer) {}

  ngOnInit(): void {
    this.unitedFormGroup.get('theftReported').valueChanges.subscribe(changes => {
      if(changes) {
        this.unitedFormGroup.controls['reportingDate'].setValidators([Validators.required]);
        this.unitedFormGroup.controls['reportingDate'].updateValueAndValidity();
        this.unitedFormGroup.controls['stationName'].setValidators([Validators.required]);
        this.unitedFormGroup.controls['stationName'].updateValueAndValidity();
        this.unitedFormGroup.controls['firNo'].setValidators([Validators.required, Validators.maxLength(14)]);
        this.unitedFormGroup.controls['firNo'].updateValueAndValidity();
        this.unitedFormGroup.controls['notReasons'].clearValidators();
        this.unitedFormGroup.controls['notReasons'].updateValueAndValidity();

      }
      else {
        this.unitedFormGroup.controls['reportingDate'].clearValidators();
        this.unitedFormGroup.controls['reportingDate'].updateValueAndValidity();
        this.unitedFormGroup.controls['stationName'].clearValidators();
        this.unitedFormGroup.controls['stationName'].updateValueAndValidity();
        this.unitedFormGroup.controls['firNo'].clearValidators();
        this.unitedFormGroup.controls['firNo'].updateValueAndValidity();
        this.unitedFormGroup.controls['notReasons'].addValidators([Validators.required]);
        this.unitedFormGroup.controls['notReasons'].updateValueAndValidity();
      }
    })

  }

  async modifyPdf() {
    const url = 'assets/united-form.pdf';
    const existingPdfBytes = await fetch(url).then((res) => res.arrayBuffer());

    const pdfDoc = await PDFDocument.load(existingPdfBytes);
    const helveticaFont = await pdfDoc.embedFont(StandardFonts.Helvetica);
    const finalFinalUrl = await fetch(this.finalImage).then((res) => res.arrayBuffer())  
    const jpgImage = await pdfDoc.embedJpg(finalFinalUrl)
    const jpgScale = jpgImage.scale(0.5)
    const pages = pdfDoc.getPages();
    const firstPage = pages[0];
    const { width, height } = firstPage.getSize();
    let sampleAddressArray = [];
    let registrationArray = [];
    let hypothecationArray = [];
    let sampleDate = [];
    let expiryDate = [];
    let reportingDate = [];
    let emailArray = [];

    firstPage.drawImage(jpgImage, {
      x: 350,
      y: height / 2 - 340,
      height: jpgScale.height,
      width: jpgScale.width
    });

    for (let i = 0; i < this.unitedFormGroup.value.policyNo.length; i++) {
      firstPage.drawText(this.unitedFormGroup.value.policyNo[i], {
        x: 122 + 17.2 * i,
        y: height / 2 + 284,
        size: 10,
        font: helveticaFont,
      });
    }

    firstPage.drawText(this.unitedFormGroup.value.insuredName, {
      x: 118,
      y: height / 2 + 269,
      size: 6,
      font: helveticaFont,
    });

    if(this.unitedFormGroup.value.insuredAddress) {
      sampleAddressArray = [
        this.unitedFormGroup.value.insuredAddress.slice(0, 134),
        this.unitedFormGroup.value.insuredAddress.slice(134),
      ];
    }

    for (let i = 0; i < sampleAddressArray.length; i++) {
      firstPage.drawText(sampleAddressArray[i], {
        x: 118,
        y: height / 2 + 251 - 6 * i,
        size: 5,
        font: helveticaFont,
      });
    }

    for (let i = 0; i < this.unitedFormGroup.value.insuredPincode.length; i++) {
      firstPage.drawText(this.unitedFormGroup.value.insuredPincode[i], {
        x: 158 + 17.2 * i,
        y: height / 2 + 234,
        size: 5,
        font: helveticaFont,
      });
    }

    for (let i = 0; i < this.unitedFormGroup.value.insuredSTDCode.length; i++) {
      firstPage.drawText(this.unitedFormGroup.value.insuredSTDCode[i], {
        x: 158 + 17.2 * i,
        y: height / 2 + 224,
        size: 5,
        font: helveticaFont,
      });
    }

    for (let i = 0; i < this.unitedFormGroup.value.insuredMobile.length; i++) {
      firstPage.drawText(this.unitedFormGroup.value.insuredMobile[i], {
        x: 292 + 17.2 * i,
        y: height / 2 + 234,
        size: 5,
        font: helveticaFont,
      });
    }

    for (
      let i = 0;
      i < this.unitedFormGroup.value.insuredLandline.length;
      i++
    ) {
      firstPage.drawText(this.unitedFormGroup.value.insuredLandline[i], {
        x: 292 + 17.2 * i,
        y: height / 2 + 224,
        size: 5,
        font: helveticaFont,
      });
    }

    emailArray = this.unitedFormGroup.value.insuredEmail.split('@');
    for (let i = 0; i < emailArray.length; i++) {
      firstPage.drawText(emailArray[i], {
        x: 152 + 156 * i,
        y: height / 2 + 214,
        size: 5,
        font: helveticaFont,
      });
    }

    if(this.unitedFormGroup.value.vehicleReg) {
      registrationArray = [
        this.unitedFormGroup.value.vehicleReg.slice(0, 3),
        this.unitedFormGroup.value.vehicleReg.slice(3, 5),
        this.unitedFormGroup.value.vehicleReg.slice(5, 6),
        this.unitedFormGroup.value.vehicleReg.slice(6, 9),
        this.unitedFormGroup.value.vehicleReg.slice(9),
      ];
    }

    let lastMaxPosition = 175;
    for (let i = 0; i < registrationArray.length; i++) {
      for (let j = 0; j < registrationArray[i].length; j++) {
        let text = registrationArray[i];
        firstPage.drawText(text[j], {
          x: lastMaxPosition + 17.2 * j,
          y: height / 2 + 202,
          size: 5,
          font: helveticaFont,
        });
      }
      lastMaxPosition =
        lastMaxPosition + 17.2 * (registrationArray[i].length - 1) + 40 - 5 * i;
    }

    firstPage.drawText(this.unitedFormGroup.value.vehicleChassis, {
      x: 169,
      y: height / 2 + 192,
      size: 6,
      font: helveticaFont,
    });

    firstPage.drawText(this.unitedFormGroup.value.vehicleEngine, {
      x: 169,
      y: height / 2 + 182,
      size: 6,
      font: helveticaFont,
    });

    firstPage.drawText(this.unitedFormGroup.value.vehicleMake, {
      x: 169,
      y: height / 2 + 172,
      size: 6,
      font: helveticaFont,
    });

    firstPage.drawText(this.unitedFormGroup.value.vehicleModel, {
      x: 359,
      y: height / 2 + 172,
      size: 6,
      font: helveticaFont,
    });
    if(this.unitedFormGroup.value.hypoDetails) {
      hypothecationArray = [
        this.unitedFormGroup.value.hypoDetails.slice(0, 104),
        this.unitedFormGroup.value.hypoDetails.slice(104),
      ];
    }

    for (let i = 0; i < hypothecationArray.length; i++) {
      firstPage.drawText(hypothecationArray[i], {
        x: 169,
        y: height / 2 + 162 - 7 * i,
        size: 6,
        font: helveticaFont,
      });
    }

    let startingX = 171;
    if(this.unitedFormGroup.value.dateLoss) {
      sampleDate = this.unitedFormGroup.value.dateLoss
      .toLocaleDateString()
      .split('/');
      if(sampleDate[1].length == 1) {
        sampleDate[1] = '0' + sampleDate[1] 
      }
    }
    for (let i = 0; i < sampleDate.length; i++) {
      for (let j = 0; j < sampleDate[i].length; j++) {
        firstPage.drawText(sampleDate[i][j], {
          x: startingX,
          y: height / 2 + 143,
          size: 6,
          font: helveticaFont,
        });
        startingX += 17.2;
      }
      startingX += 21;
    }

    if(this.unitedFormGroup.value.timeLoss) {
      let startingX = 359
      let timeLoss = this.unitedFormGroup.value.timeLoss.split(':');
      let [h, m] = this.unitedFormGroup.value.timeLoss.split(':');
      if(h >= 12 && h<=23)
      for (let i = 0; i < timeLoss.length; i++) {
        for (let j = 0; j < timeLoss[i].length; j++) {
          firstPage.drawText(timeLoss[i][j], {
            x: startingX,
            y: height / 2 + 143,
            size: 6,
            font: helveticaFont,
          });
          startingX += 17.2;
        }
        startingX += 21;
      }
    }

    firstPage.drawText(this.unitedFormGroup.value.placeLoss, {
      x: 169,
      y: height / 2 + 132,
      size: 6,
      font: helveticaFont,
    });

    firstPage.drawText(this.unitedFormGroup.value.driverName, {
      x: 185,
      y: height / 2 + 114,
      size: 6,
      font: helveticaFont,
    });

    firstPage.drawText(this.unitedFormGroup.value.driverAddress, {
      x: 185,
      y: height / 2 + 95,
      size: 6,
      font: helveticaFont,
    });

    firstPage.drawText(this.unitedFormGroup.value.driverNumber, {
      x: 185,
      y: height / 2 + 79,
      size: 6,
      font: helveticaFont,
    });

    startingX = 187;
    if(this.unitedFormGroup.value.expiryDate) {
      expiryDate = this.unitedFormGroup.value.expiryDate
      .toLocaleDateString()
      .split('/');
      if(expiryDate[1].length == 1) {
        expiryDate[1] = '0' + expiryDate[1] 
      }
      expiryDate[2] = expiryDate[2].slice(-2);
    }
    for (let i = 0; i < expiryDate.length; i++) {
      for (let j = 0; j < expiryDate[i].length; j++) {
        firstPage.drawText(expiryDate[i][j], {
          x: startingX,
          y: height / 2 + 69,
          size: 6,
          font: helveticaFont,
        });
        startingX += 17.2;
      }
      startingX += 21;
    }

    firstPage.drawText(this.unitedFormGroup.value.issuingRTO, {
      x: 358,
      y: height / 2 + 69,
      size: 6,
      font: helveticaFont,
    });

    firstPage.drawText('X', {
      x: this.unitedFormGroup.value.underInfluence ? 261 : 294,
      y: height / 2 + 59,
      size: 6,
      font: helveticaFont,
    });

    firstPage.drawText('X', {
      x: this.unitedFormGroup.value.wasInjured ? 394 : 428,
      y: height / 2 + 59,
      size: 6,
      font: helveticaFont,
    });

    firstPage.drawText(this.unitedFormGroup.value.accidentDescription, {
      x: 118,
      y: height / 2 + 39,
      size: 6,
      font: helveticaFont,
    });

    firstPage.drawText('X', {
      x: this.unitedFormGroup.value.pillionCarried ? 279 : 312,
      y: height / 2 + 15,
      size: 6,
      font: helveticaFont,
    });

    for (
      let i = 0;
      i < this.unitedFormGroup.value.occupantsCarried.toString().length;
      i++
    ) {
      firstPage.drawText(
        this.unitedFormGroup.value.occupantsCarried.toString()[i],
        {
          x: 279 + 17.2 * i,
          y: height / 2 + 5,
          size: 6,
          font: helveticaFont,
        }
      );
    }

    firstPage.drawText(this.unitedFormGroup.value.workshopAddress, {
      x: 204,
      y: height / 2 - 15,
      size: 6,
      font: helveticaFont,
    });

    firstPage.drawText(this.unitedFormGroup.value.workshopContact, {
      x: 169,
      y: height / 2 - 30,
      size: 6,
      font: helveticaFont,
    });

    firstPage.drawText(this.unitedFormGroup.value.workshopMobile, {
      x: 169,
      y: height / 2 - 40,
      size: 6,
      font: helveticaFont,
    });

    firstPage.drawText(this.unitedFormGroup.value.workshopFax, {
      x: 169,
      y: height / 2 - 50,
      size: 6,
      font: helveticaFont,
    });

    for (let i = 0; i < this.unitedFormGroup.value.estimatedLoss.length; i++) {
      firstPage.drawText(this.unitedFormGroup.value.estimatedLoss[i], {
        x: 329 + 17.2 * i,
        y: height / 2 - 30,
        size: 6,
        font: helveticaFont,
      });
    }

    firstPage.drawText(this.unitedFormGroup.value.workshopPhone, {
      x: 324,
      y: height / 2 - 40,
      size: 6,
      font: helveticaFont,
    });

    firstPage.drawText(this.unitedFormGroup.value.workshopEmail, {
      x: 324,
      y: height / 2 - 50,
      size: 6,
      font: helveticaFont,
    });

    if (this.unitedFormGroup.value.theftVehicle) {
      firstPage.drawText('X', {
        x: 123,
        y: height / 2 - 65,
        size: 6,
        font: helveticaFont,
      });
    }

    if (this.unitedFormGroup.value.theftEffects) {
      firstPage.drawText('X', {
        x: 191,
        y: height / 2 - 65,
        size: 6,
        font: helveticaFont,
      });
    }

    firstPage.drawText('X', {
      x: this.unitedFormGroup.value.theftReported ? 211 : 246,
      y: height / 2 - 106,
      size: 6,
      font: helveticaFont,
    });

    firstPage.drawText(this.unitedFormGroup.value.notReasons, {
      x: 326,
      y: height / 2 - 106,
      size: 6,
      font: helveticaFont,
    });

    startingX = 211;
    if(this.unitedFormGroup.value.reportingDate) {
      reportingDate = this.unitedFormGroup.value.reportingDate.toLocaleDateString().split('/');
      if(reportingDate[1].length == 1) {
        reportingDate[1] = '0' + reportingDate[1] 
      }
    }
    for (let i = 0; i < reportingDate.length; i++) {
      for (let j = 0; j < reportingDate[i].length; j++) {
        firstPage.drawText(reportingDate[i][j], {
          x: startingX,
          y: height / 2 - 116,
          size: 6,
          font: helveticaFont,
        });
        startingX += 17.2;
      }
      startingX += 15;
    }

    firstPage.drawText(this.unitedFormGroup.value.stationName, {
      x: 204,
      y: height / 2 - 126,
      size: 6,
      font: helveticaFont,
    });

    for (let i = 0; i < this.unitedFormGroup.value.firNo.length; i++) {
      firstPage.drawText(this.unitedFormGroup.value.firNo[i], {
        x: 211 + 17 * i,
        y: height / 2 - 136,
        size: 6,
        font: helveticaFont,
      });
    }

    firstPage.drawText('X', {
      x: this.unitedFormGroup.value.thirdPartyInvolved ? 191 : 229,
      y: height / 2 - 151,
      size: 6,
      font: helveticaFont,
    });

    if (
      this.unitedFormGroup.value.thirdPartyLoss.toString().includes('death')
    ) {
      firstPage.drawText('X', {
        x: 191,
        y: height / 2 - 161,
        size: 6,
        font: helveticaFont,
      });
    }

    if (
      this.unitedFormGroup.value.thirdPartyLoss.toString().includes('injury')
    ) {
      firstPage.drawText('X', {
        x: 230,
        y: height / 2 - 161,
        size: 6,
        font: helveticaFont,
      });
    }

    if (
      this.unitedFormGroup.value.thirdPartyLoss.toString().includes('damage')
    ) {
      firstPage.drawText('X', {
        x: 264,
        y: height / 2 - 161,
        size: 6,
        font: helveticaFont,
      });
    }

    firstPage.drawText('X', {
      x: this.unitedFormGroup.value.driverInjured ? 191 : 230,
      y: height / 2 - 171,
      size: 6,
      font: helveticaFont,
    });

    firstPage.drawText('X', {
      x: this.unitedFormGroup.value.occupantsInjured ? 347 : 380,
      y: height / 2 - 171,
      size: 6,
      font: helveticaFont,
    });

    firstPage.drawText(this.unitedFormGroup.value.witnessName, {
      x: 189,
      y: height / 2 - 220,
      size: 6,
      font: helveticaFont,
    });

    firstPage.drawText(this.unitedFormGroup.value.witnessAddress, {
      x: 262,
      y: height / 2 - 220,
      size: 6,
      font: helveticaFont,
    });

    firstPage.drawText(this.unitedFormGroup.value.witnessPhone, {
      x: 378,
      y: height / 2 - 220,
      size: 6,
      font: helveticaFont,
    });

    firstPage.drawText('X', {
      x: this.unitedFormGroup.value.courtesyCar ? 230 : 264,
      y: height / 2 - 232,
      size: 6,
      font: helveticaFont,
    });

    firstPage.drawText('X', {
      x: this.unitedFormGroup.value.medicalRequired ? 230 : 264,
      y: height / 2 - 242,
      size: 6,
      font: helveticaFont,
    });

    firstPage.drawText(this.unitedFormGroup.value.lossEffects, {
      x: 231,
      y: height / 2 - 249,
      size: 6,
      font: helveticaFont,
    });

    firstPage.drawText(this.unitedFormGroup.value.likelyExpense, {
      x: 392,
      y: height / 2 - 242,
      size: 6,
      font: helveticaFont,
    });

    let completionDate = this.unitedFormGroup.value.expectedRepairDate ? this.unitedFormGroup.value.expectedRepairDate.toLocaleDateString() : '';

    firstPage.drawText(
      completionDate,
      {
        x: 392,
        y: height / 2 - 232,
        size: 6,
        font: helveticaFont,
      }
    );

    for (let i = 0; i < this.unitedFormGroup.value.accountNumber.length; i++) {
      firstPage.drawText(this.unitedFormGroup.value.accountNumber[i], {
        x: 174 + 17.2 * i,
        y: height / 2 - 264,
        size: 6,
        font: helveticaFont,
      });
    }

    firstPage.drawText(this.unitedFormGroup.value.bankName, {
      x: 174,
      y: height / 2 - 274,
      size: 6,
      font: helveticaFont,
    });

    firstPage.drawText(this.unitedFormGroup.value.branchName, {
      x: 375,
      y: height / 2 - 274,
      size: 6,
      font: helveticaFont,
    });

    for (let i = 0; i < this.unitedFormGroup.value.ifscCode.length; i++) {
      firstPage.drawText(this.unitedFormGroup.value.ifscCode[i], {
        x: 174 + 17.2 * i,
        y: height / 2 - 284,
        size: 6,
        font: helveticaFont,
      });
    }

    if(this.unitedFormGroup.value.dateOverall) {
      firstPage.drawText(this.unitedFormGroup.value.dateOverall.toLocaleDateString(), {
        x: 65,
        y: height / 2 - 338,
        size: 6,
        font: helveticaFont,
      });
    }

    firstPage.drawText(this.unitedFormGroup.value.placeOverall, {
      x: 65,
      y: height / 2 - 346,
      size: 6,
      font: helveticaFont,
    });

    const pdfBytes = await pdfDoc.save();
    download(pdfBytes, 'United_Claim_Form.pdf', 'application/pdf');
  }

  exportHTMLtoPDF() {
    var element = document.getElementById('content');
    var opt = {
      margin: 0.2,
      filename: 'national-form',
      image: {
        type: 'jpeg',
        quality: 1.0,
      },
      html2canvas: {
        scale: 5,
        dpi: 192,
        letterRendering: true,
        allowTaint: true,
      },
      jsPDF: {
        unit: 'in',
        format: [8.3, 11.7],
        orientation: 'portrait',
        compress: true,
      },
    };

    // New Promise-based usage:
    html2pdf().set(opt).from(element).save();
    // element = element.classList.add('max-w')
  }

  uploadClick(from = 'national') {
    let toClick =
      from == 'united' ? 'upload-file-button-united' : 'upload-file-button';
    let button = document.getElementById(toClick);
    button.click();
  }

  viewImage(event) {
    const image = <HTMLImageElement>document.getElementById('preview-image');
    const [file] = event.target.files;
    if (file) {
      this.previewImage = this.sanitizer.bypassSecurityTrustUrl(
        URL.createObjectURL(file)
      );
      this.openModal();
    }
  }

  openDialog(file) {
    let dialogRef = this.matDialog.open(this.cropperModal);
    dialogRef.afterOpened().subscribe((event) => {
      if (file) {
      }
    });
  }

  openModal() {
    var modal = document.getElementById('myModal');
    modal.style.display = 'block';
    window.onclick = function (event) {
      if (event.target == modal) {
        modal.style.display = 'none';
      }
    };
  }

  rotateImage(direction) {
    if (direction == 'l') {
      this.angularCropper.cropper.rotate(10);
    } else {
      this.angularCropper.cropper.rotate(-10);
    }
  }

  zoomImage(direction) {
    if (direction == '+') {
      this.angularCropper.cropper.zoom(0.1);
    } else {
      this.angularCropper.cropper.zoom(-0.1);
    }
  }

  cropImageFinal() {
    const image = <HTMLInputElement>(
      document.getElementById('final-image-display-united')
    );
    this.finalImage = 
      this.angularCropper.cropper
        .getCroppedCanvas({
          width: 190,
          height: 40,
          fillColor: 'white'
        }).toDataURL("image/jpeg")
    image.src = this.angularCropper.cropper
    .getCroppedCanvas({
      width: 190,
      height: 40,
    }).toDataURL("image/jpeg");
    this.imageAttached = true;
    var modal = document.getElementById('myModal');
    modal.style.display = 'none';
  }

  changeIndex(number) {
    this.currentIndex = this.currentIndex + number;
  }

  checkSection(index) {
    let statusAtIndex = Array.from(new Set(Object.keys(this.unitedFormGroup.controls).filter(item => { 
      if(this.stepGroups[index].toString().includes(item)) {
        return item;
      }
      else {
        return null
      }
    }).map((item) => this.unitedFormGroup.controls[item].status)));
    if(index == 9) {
      return !(statusAtIndex.length == 1 && statusAtIndex[0] == 'VALID' && this.imageAttached)
    }
    else {
      return !(statusAtIndex.length == 1 && statusAtIndex[0] == 'VALID')
    }
  }

}
