<div class="box" *ngIf="IsGarage">
  <div class="box-header table_header">
      <h3 class="box-title">Garage Details</h3>
      <!-- <div class="box-tools">
          <button type="button" class="btn btn-box-tool" data-widget="collapse"><i class="fa fa-minus"></i></button>
      </div> -->
  </div>

  <div class="box-body video_block">
      <p class="help-block garage_block" style="margin:0;">{{GarageDetails.Name}}<span> - {{GarageDetails.Address}}</span></p>
      <div class="row box-header with-border national_garage_det">
          <div class="col-md-4 col-xs-12  data_block">
              <ul class="list-group list-group-unbordered customer_data">
                  <li class="list-group-item customer_data">
                      <b>SPOC Name</b> <a class="pull-right-data ng-binding">{{GarageDetails.SPOCName}}</a>
                  </li>
                  <li class="list-group-item customer_data">
                      <b>Action</b> <a class="pull-right-data ng-binding">{{GarageDetails.ActionType}}</a>
                  </li>
              </ul>

          </div>
          <div class="col-md-4 col-sm-6 col-xs-12 data_block">
              <ul class="list-group list-group-unbordered customer_data">
                  <li class="list-group-item customer_data">
                      <b>SPOC Email</b> <a class="pull-right-data ng-binding">{{GarageDetails.SPOCEmail}}</a>
                  </li>
                  <li class="list-group-item customer_data">
                      <b>Date</b> <a class="pull-right-data ng-binding">{{GarageDetails.ActionDate}}</a>
                  </li>
              </ul>
          </div>
          <div class="col-md-4 col-sm-6 col-xs-12 data_block">
              <ul class="list-group list-group-unbordered customer_data">
                  <li class="list-group-item customer_data">
                      <b>SPOC Mobile</b> <a class="pull-right-data ng-binding">{{GarageDetails.SPOCNumber}}</a>
                  </li>
                  <li class="list-group-item customer_data">
                      <b>Towing Required</b> <a class="pull-right-data ng-binding">{{GarageDetails.isTowRequired==1?'Yes':'No'}}</a>
                  </li>
              </ul>

          </div>
      </div>
  </div>
</div>
