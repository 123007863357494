import { Component, OnInit } from '@angular/core';
import { SharedService } from './../../services/SharedService/sharedService';
import { PendingService } from './../../services/PendingService/pending.service';

@Component({
  selector: 'app-pending-cases',
  templateUrl: './pending-cases.component.html',
  styleUrls: ['./pending-cases.component.css']
})
export class PendingCasesComponent implements OnInit {

  constructor(private sharedService: SharedService, private pendingService: PendingService) { }
  LoginDetails: any;
  ngOnInit(): void {

    this.sharedService.userDetailsSubject$.subscribe(data => {
      if(typeof data == 'string'){
        this.LoginDetails = JSON.parse(data);
      }
      else{
        this.LoginDetails = data;
      }

    });
  }

}
