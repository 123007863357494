import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { SurveyorService } from './../../../services/SurveyorService/surveyor.service';
import { SharedService } from './../../../services/SharedService/sharedService';

@Component({
  selector: 'app-garage-details',
  templateUrl: './garage-details.component.html',
  styleUrls: ['./garage-details.component.css']
})
export class GarageDetailsComponent implements OnInit {

  constructor(private surveyorService: SurveyorService, private sharedService: SharedService) { }
  @Input() LoginDetails: any;
  @Input() ClaimDetail: any;
  IsGarage: boolean = false;
  GarageDetails: any;
  ErrorMsg: string = '';
  ngOnInit(): void {
    // if(this.ClaimDetail.ClaimId){
    //   this.GetGarageDetails();
    // }
  }

  ngOnChanges(changes: SimpleChanges){
    if(changes['ClaimDetail'] && this.ClaimDetail.ClaimId != 0 && !this.GarageDetails){
      this.GetGarageDetails();
    }
  }


  GetGarageDetails() {


    this.surveyorService.GetClaimGarageDetail(this.ClaimDetail.ClaimId).subscribe((res: any)=>{
      if (res && res.Data) {
        this.IsGarage = true;
        this.GarageDetails = res.Data;
        const timestamp = this.GarageDetails.ActionDate;
        var date = new Date(timestamp);
        var datevalues = ('0' + (date.getMonth() + 1)).slice(-2) + '/' + ('0' + date.getDate()).slice(-2) + '/' + date.getFullYear();
        this.GarageDetails.ActionDate = datevalues;
        this.sharedService.setGarageDetails(this.GarageDetails)
      } else {
          this.IsGarage = false;
          this.ErrorMsg = "Error : Something went wrong!";
      }
    })

  }

}
