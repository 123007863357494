import { Component, OnInit, Input, SimpleChanges } from '@angular/core';
import { SurveyorService } from './../../../services/SurveyorService/surveyor.service';
import jsPDF from 'jspdf';
declare let $: any;
@Component({
  selector: 'app-videos',
  templateUrl: './videos.component.html',
  styleUrls: ['./videos.component.css']
})
export class VideosComponent implements OnInit {

  constructor(private surveyorService: SurveyorService) { }
  @Input() LoginDetails: any;
  @Input() ClaimDetail: any;
  VideoDetails: any = [];
  VideoUrl: string = '';
  RegNo: string = '';
  ErrorMsg: string = '';
  clickCount = 0;
  ScreenshotFromVideo: any;
  canvas: any;
  createImageFromVideo:  any;
  Video: any;
  dataLink: any[] = [];
  newPDFDoc: any;
  captureDisabled: boolean = true;
  screenshotDisabled: boolean = false;

  ngOnInit(): void {
    // if(this.ClaimDetail.ClaimId){
    //   this.GetVideoDetails()
    // }
  }

  ngOnChanges(changes: SimpleChanges){
    if(changes['ClaimDetail'] && this.VideoDetails.length == 0 && this.ClaimDetail.ClaimId != 0){
      this.GetVideoDetails()
    }
  }

  GetVideoDetails() {
    let reqData = `${this.ClaimDetail.ClaimId}/1`
    this.surveyorService.GetInsurerVideoDetails(reqData).subscribe((res)=>{
      if(res.Data!=null){
          this.VideoDetails = res.Data;
          this.GetVideoURl(this.VideoDetails[0])
          this.RegNo = this.VideoDetails[0].RegistrationNo;

      } else {

          this.ErrorMsg = "Error : Something went wrong!";
      }
    })
  }

  OpenVideo(Video: any) {
    this.GetVideoURl(Video)
  };

  GetVideoURl(Video: any){
    this.surveyorService.getVideoLink(Video.ClaimID, btoa(Video.EncAppVideoID)).subscribe((data: any) => {
      this.VideoUrl = data.Data;
    },
    (error) => {
      this.VideoUrl = '';
    })
  }

  takeScreen() {

    // if (this.clickCount > 0) {
    //     return
    // }
    // this.clickCount += 1

    this.captureDisabled = false
    this.screenshotDisabled = true

    // $("#capture").disabled = false;
    // $("#capture").className = "btn btn-primary";
    // $("#screen").disabled = true;
    // $("#screen").className = "btn btn-primary disabled";


    let initializeScreenshot = ()=> {
      this.ScreenshotFromVideo = $("#screenshot");
      this.Video = $("#video").get(0);
      $("#capture").on('click',captureImage);
    };

    let captureImage = ()=> {
      this.canvas = document.createElement("canvas");
      this.canvas.width = this.Video.videoWidth;
      this.canvas.height = this.Video.videoHeight;
      this.canvas.getContext('2d')
            .drawImage(this.Video, 0, 0, this.canvas.width, this.canvas.height);
      this.canvas.setAttribute('crossOrigin', 'anonymous');
      this.dataLink.push(this.canvas.toDataURL());
      this.createImageFromVideo = document.createElement("img");
      this.createImageFromVideo.src = this.canvas.toDataURL();
      this.createImageFromVideo.setAttribute('style', 'margin: 0 5px;width: 50px;height: 50px;border:3px solid #d2d6de;border-radius:6px')
      this.ScreenshotFromVideo.prepend(this.createImageFromVideo);
      $("#screenshot").css('padding','10px');
      $("#screenshot").css('overflow','auto');
      $("#screenshot").css('white-space','nowrap');
      $("#screenshot").css('border', '1px solid #ddd');
      $("#screenshot").css('border-radius','5px');
      $("#screenshot").css('margin-top', '5px');
      $("#screenshot").css('margin-bottom','5px');

    };
    initializeScreenshot();
  }



  GeneratePdf() {
    let reqNo = 'SEND VIDEOS FOR - ' +this.RegNo;
    let fileSave =  reqNo+'.pdf';
    this.newPDFDoc = new jsPDF()
    this.newPDFDoc.setFontSize(15);
    this.newPDFDoc.text(35, 25, "Video Inspection report for - " + reqNo);
    var h = 15;
    var t = 30;
    var len = this.dataLink.length - 1;

    for (let i = 0 ; i < this.dataLink.length; i++) {
        this.newPDFDoc.addImage(this.dataLink[i], 'JPEG', h, t, 180, 80, undefined,'FAST')
        i++;
        if (i < len) {
            this.newPDFDoc.addImage(this.dataLink[i], 'JPEG', h, t + 90, 180, 80, undefined,'FAST')
            i++;
        }
        if (i <= len) {
            this.newPDFDoc.addImage(this.dataLink[i], 'JPEG', h, t + 180, 180, 80, undefined,'FAST' )
            this.newPDFDoc.addPage();
        }
    }
    this.newPDFDoc.save(fileSave.trim());
  }

}
