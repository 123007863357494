import { Component, OnInit, Input } from '@angular/core';
import { SharedService } from './../../services/SharedService/sharedService';
import { SurveyorService } from './../../services/SurveyorService/surveyor.service';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-claim-assignment',
  templateUrl: './claim-assignment.component.html',
  styleUrls: ['./claim-assignment.component.css']
})
export class ClaimAssignmentComponent implements OnInit {

  constructor(private sharedService: SharedService, private surveyorService: SurveyorService, private router: Router, private fb: FormBuilder) { }
  //@Input() LoginDetails: any;
  LoginDetails: any;
  InsurerList: any;
  AllInsurerList: any;
  SurveyorList: any;
  OfficerList: any;
  ErrorMsg: string = '';
  ClaimLists: any = [];
  SelectedSurveyor: any = { ID: 0 };
  IsAllChecked: boolean = false;
  ListShow: boolean = false;
  SelectedOfficer:any = { ID: 0 };
  SelectedInsurer: any = { InsurerId: 0 };
  ClaimAssignmentList: any = {
            CreatedBy: 0,
            AssignedByType: 'Insurer',
            ClaimAssign: [],

        }
  ClaimAssignment: any = {
      ClaimId: 0,
      AssignedToSurveyor: 0,
      AssignedToOfficer: 0
  };
  AssignType: any;
  AssignmentType =
    [
        { TypeValue: 1, TypeText: 'Assigned' },
        { TypeValue: 2, TypeText: 'UnAssigned' },
        { TypeValue: 3, TypeText: 'UnAssignedSurveyor' },
        { TypeValue: 4, TypeText: 'UnAssignedOfficer' },
    ];
  assignmentTypeForm!: FormGroup;
  assignCasesForm!: FormGroup;

  ngOnInit(): void {
    this.assignmentTypeForm = this.fb.group({
      assignType: ['', [Validators.required]]
    })
    this.assignCasesForm = this.fb.group({
      selectedSurveyor: ['', [Validators.required]],
      selectedOfficer: ['']
    })

    this.sharedService.userDetailsSubject$.subscribe(data => {
      if(typeof data == 'string'){
        this.LoginDetails = JSON.parse(data);
      }
      else{
        this.LoginDetails = data;
      }

    });

    if(Object.keys(this.LoginDetails).length > 0){
      this.SelectedInsurer.InsurerId = this.LoginDetails.InsurerId
      this.Search()
      this.GetInsurerSurveyorOfficerList()
      this.CheckCondition()
    }

  }


  GetInsurerSurveyorOfficerList() {
    this.surveyorService.GetInsurerSurveyorOfficerList(this.LoginDetails.ProductID).subscribe((res)=>{
      if(res){
        this.AllInsurerList = res.Data;
        this.SurveyorList = this.AllInsurerList.filter((x: any)=> x.InsurerId == this.SelectedInsurer.InsurerId && x.UserType == 3)
        this.OfficerList = this.AllInsurerList.filter((x: any)=> x.InsurerId == this.SelectedInsurer.InsurerId && x.UserType == 2)

      }
      else{
        this.ErrorMsg = "Error: Something Went Wrong!";
      }
    })
  }

  Search() {
    this.IsAllChecked = false;
    this.ClaimLists = [];
    let TypeAssign: any = {}
    if (this.SelectedSurveyor == null) {
        this.SelectedSurveyor = { ID: 0 };
        this.SelectedOfficer = { ID: 0 };
    }
    if (this.AssignType != undefined) {
        if (this.AssignType.TypeValue != undefined && this.AssignType.TypeValue != 0)
            TypeAssign = this.AssignType.TypeValue;
    } else {
        TypeAssign = 1;
        this.AssignType = { TypeValue: 1, TypeText: 'Assigned' };
    }

    if (TypeAssign != undefined && this.LoginDetails.UserId != undefined && this.LoginDetails.UserType != undefined) {
        let reqData = `${TypeAssign}/${this.LoginDetails.InsurerId}/${this.LoginDetails.UserId}/${this.LoginDetails.UserType}`;
        this.surveyorService.GetClaimSurveyorOfficerDetails(reqData).subscribe((res)=> {
          if(res){
            this.ClaimLists = res.Data;
                // this.ListShow = true;
                // $timeout(function () {
                //     $('#AssignClaims').DataTable({
                //         "order": [[0, "desc"]],
                //         columnDefs: [{ orderable: false, "targets": -1 }]
                //     });

                //     $('.sorting').click(function () {
                //         this.IsAllChecked = false;
                //         $('.CheckAll').prop('checked', false);
                //         $('.checkbox_new').prop('checked', false);
                //     });

                //     $('.paginate_button').click(function () {
                //         this.IsAllChecked = false;
                //         $('.CheckAll').prop('checked', false);
                //         $('.checkbox_new').prop('checked', false);
                //     });
                // }, 500);
          }
          else{
            alert('No record found.')
          }
        })
      } else {
        alert("Please select Type !!");
        return;
      }
  }

  CheckCondition() {
    if (this.LoginDetails.UserType == 2) {
        this.AssignmentType = this.AssignmentType.filter(x => x.TypeValue != 2 && x.TypeValue != 4)
    }
  }

  Case_Details(ClaimId: any, EncClaimId: any){
    this.router.navigate(['/case-details', btoa(`${ClaimId}$${EncClaimId}`)]);
  }
}
