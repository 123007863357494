import { Component, OnInit,Input } from '@angular/core';
import { CommonService } from './../../../services/commonService/common.service';
import { LoginService } from './../../../services/LoginService/login.service';
import { CookieService } from 'ngx-cookie-service';
import { Router } from '@angular/router';
import { SharedService } from './../../../services/SharedService/sharedService';

declare let $: any;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  constructor(
    private router: Router,
    private sharedService: SharedService,
    private commonService :CommonService,
    private loginService :LoginService,
    private cookieService: CookieService,
    ) { }
  @Input() LoginDetails: any;
  Notifications: any = [];
  temp: any;
  ErrorMsg: string = '';
  Logo: string = '';
  LogoSmall: string = '';
  ngOnInit(): void {
    this.Session()
    this.IsValidUserType()
    //this.GetNotification()
    console.log(`onInit: header component: ${JSON.stringify(this.LoginDetails)}`)
  }
  ngOnChanges(){
    console.log(this.LoginDetails)
  }
  GetNotification() {
    this.temp = [];
    let reqData = this.LoginDetails.InsurerId + "/" + this.LoginDetails.UserId + "/" + this.LoginDetails.UserType;

    this.commonService.GetNotification(reqData).subscribe((res)=> {
      if(res.Data!=null){

        this.temp = res.Data;
        this.Notifications = [];
        this.temp.forEach((value: any) => {
          var datetime = new Date();
          var t1 = datetime.getTime();
          var t2 = value.Date;
          let time: any = t2 - t1;
          time = time / 60000;
          if (time > 0) {
              if (time > 9) {
                  time = String(time).substring(0, 2) + ' mins left';
              }
              else {
                  time = String(time).substring(0, 1) + ' mins left';
              }
          }
          else {
              time = String(time).substring(1, 10);
              if (time > 9) {
                  time = time.substring(0, 2) + ' mins overdue';
              }
              else {
                  time = time.substring(0, 1) + ' mins overdue';
              }
          };

          value.Time = time;

          if (!value.IsSkipped) {
              this.Notifications.push(value);
          }
        });
        this.temp.forEach((value: any, key: any)=> {

            let datetime = new Date();
            let t1 = datetime.getTime();
            let t2 = value.Date;
            let time: any = t2 - t1;
            time = time / 60000;
            if (time > 0) {
                if (time > 9) {
                    time = String(time).substring(0, 2) + ' mins left';
                }
                else {
                    time = String(time).substring(0, 1) + ' mins left';
                }
            }
            else {
                time = String(time).substring(1, 10);
                if (time > 9) {
                    time = time.substring(0, 2) + ' mins overdue';
                }
                else {
                    time = time.substring(0, 1) + ' mins overdue';
                }
            };

            value.Time = time;

            if (!value.IsSkipped) {
                this.Notifications.push(value);
            }
        });

        //$scope.Notifications = response.data.Data;
      } else {

      }
    })
  }

  OpenClaimDetails(ClaimId: any){

  }

  //public Logout(){
    Logout(){
    this.loginService.Logout(this.LoginDetails.LoginID).subscribe((res)=> {
        if (res.Data!=null) {

          this.cookieService.deleteAll('/','.'.concat(window.location.hostname.split('.')[1],'.',window.location.hostname.split('.')[2]))
          this.cookieService.deleteAll()
          localStorage.clear();
          this.router.navigate(['/login']);
          this.sharedService.setUserDetails({})
            //rootScopeService.clearRootScope();
            //$window.location = "login.html";

            //let domain = window.location.hostname;
            //const myArray = domain.split('.');
            //let alterdomain = '.' + myArray[1] + '.' + myArray[2];

            //document.cookie = "LoginKey=; path=/;" + `;domain=${window.location.hostname == "localhost" ? window.location.hostname : alterdomain}; max-age=0`;
            //document.cookie = "ValidationKey=; path=/;" + `;domain=${window.location.hostname == "localhost" ? window.location.hostname : alterdomain}; max-age=0`;
        }
        else {
            this.ErrorMsg = "Error : Something went wrong!";
        }

    })
  }


  Session() {
    if (this.LoginDetails.InsurerId == 0) {
        //$window.location = "login.html";
    }
    else if (this.LoginDetails.InsurerId == 12) {
        // NIA
        $('head').append('<link rel="stylesheet" type="text/css" href="assets/css/themenewindia.css">');
        this.Logo = "assets/images/national.png";
        this.LogoSmall = "assets/images/logo_national.png";
    }
    else if (this.LoginDetails.InsurerId == 8) {
        // Oriental
        //$('head').append('<link rel="stylesheet" type="text/css" href="assets/css/themenewindia.css">');
        this.LogoSmall = "assets/images/oriental_logo_sml.png";
        this.Logo = "assets/images/oriental_logo.png";
    }
    else if (this.LoginDetails.InsurerId == 11) {
        // United
        $('head').append('<link rel="stylesheet" type="text/css" href="assets/css/unitedindiainsurer.css">');
        this.LogoSmall = "assets/images/united_sm.jpg";
        this.Logo = "assets/images/united_lg.jpg";
    }
    else if (this.LoginDetails.InsurerId == 6) {
        // National
        $('head').append(' <link rel="stylesheet" type="text/css" href="assets/css/nationalinsuranceindia.css">');
        this.LogoSmall = "assets/images/national_smm.png";
        this.Logo = "assets/images/national-insurance-company.jpg";
    }
    else {
        this.LogoSmall = "assets/images/pb_small.png";
        this.Logo = "assets/images/pb_logo.png";
    }
  }


  IsValidUserType() {
    try {
        var val = this.LoginDetails.EncUserTypeID.replace('PBCLAIM', '')
        if ((parseInt(val) / 29) / this.LoginDetails.UserId != this.LoginDetails.UserType) {
            //$window.location = "login.html";
        }
    } catch (e) {
        //$window.location = "login.html";
    }
}

}
