<div *ngIf="isPreviousClaims">
    <div class="box">
        <div class="box-header table_header">
            <h3 class="box-title">Previous Claims</h3>
        </div>
        <div class="box-body">
            <table class="table table-bordered">
                <thead>
                    <tr>
                        <th>Claim ID</th>
                        <th>Claim Created On</th>
                        <th>Current Status</th>
                        <th>Last Updated On</th>
                        <th>Details</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let prevClaims of previousClaimsDetails">
                        <td>{{prevClaims.ClaimId}}</td>
                        <td>{{prevClaims.CreatedOn | date:'mediumDate'}}</td>
                        <td>{{prevClaims.StatusMode}}</td>
                        <td> {{prevClaims.UpdatedOn | date: 'mediumDate' }} </td>
                        <td><a (click)="openClaim(prevClaims.ClaimId, prevClaims.EncClaimId)"><i class="fa fa-list-alt"></i></a></td>                    
                    </tr>
                </tbody>
            </table>
        </div>
      </div>
</div>
<div *ngIf="!isPreviousClaims">
    <div class="box">
        <div class="box-header table_header">
            <h3 class="box-title">Customer Documents</h3>
            <a  role="button" class="zip-download" (click)="DownloadZIP();" title="Zip Download All"><i class="fa fa-download"></i></a>
        </div>
        <div class="box-body">
            <table class="table table-bordered">
                <thead>
                    <tr>
                        <th>Document</th>
                        <th>Uploaded On</th>
                        <th>FileName</th>
                        <th>File</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let Docs of CustomerDocsLists">
                        <td>{{Docs.Document}}</td>
                        <td>{{Docs.CreatedOn | date:'medium'}}</td>
                        <td>{{Docs.DocsName}}</td>
                        <!--<td><a ng-show="Docs.DocumentUrl!=''" ng-href="{{Docs.DocumentUrl}}" target="_blank"><i class="fa fa-file-pdf-o"></i></a></td>-->
                        <!-- <td><a (click)="GetFileUrlByUniqueDocId(Docs.DocuUniqueId,Docs.DocumentID,Docs.MasterDocumentId)"><i class="fa fa-file-pdf-o"></i></a></td> -->
                        <td><a (click)="GetFileUrlByUniqueDocIdForDoc(Docs.DocuUniqueId,Docs.DocumentID,Docs.MasterDocumentId,Docs.EncDocuUniqueId,Docs.DocumentUrl)"><i class="fa fa-file-pdf-o"></i></a></td>
                    </tr>
                </tbody>
            </table>
        </div>
      </div>
</div>

