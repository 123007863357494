import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {

  constructor() { }
  @Input() LoginDetails: any;
  ngOnInit(): void {
    console.log(`sidebar: ${JSON.stringify(this.LoginDetails)}`)


  }



}
