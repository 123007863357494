import { Injectable } from "@angular/core";
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse, HttpErrorResponse } from "@angular/common/http";
// import { Observable, of } from "rxjs";
// import { catchError, finalize, tap, timeout} from "rxjs/operators";
import { Observable } from "rxjs";
import { finalize, timeout} from "rxjs/operators";
import { CookieService } from "ngx-cookie-service";
import { LoaderService } from '../../services/LoaderService/loader.service';
import { environment } from '../../../environments/environment';
import { SharedService } from './../../services/SharedService/sharedService';
import { ActivatedRoute } from '@angular/router';
//import { HeaderComponent } from "src/app/components/core/header/header.component";

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {
  constructor(
    public loaderService: LoaderService,
    private sharedService: SharedService,
    private cookieService:CookieService,
    private activatedRoute: ActivatedRoute
    //private headerComponent: HeaderComponent
  ) { }
  activeRequests = 0;
  LoginDetails: any;
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let authReq = req.clone({
      headers: req.headers
      .set('Content-Type', 'application/json; charset=utf-8')
      .set('Authorization', environment.authKey)
      .set('SourceKey', 'SurveyorPanel')
    })

    this.sharedService.userDetailsSubject$.subscribe(data => {
      if(Object.keys(data).length > 0) {

        if(typeof data == 'string'){
          this.LoginDetails = JSON.parse(data);
        }
        else{
          this.LoginDetails = data;
        }
      }
    });

    const ValidationKey = this.cookieService.get('ValidationKey')
    const LoginKey = this.cookieService.get('LoginKey')
    let EncClaimId: any = ''

    this.loaderService.triggerLoader(true);
    this.sharedService.encClaimIdSubject$.subscribe(data =>{
      if(data){
        EncClaimId = data
      }
    })
    if(ValidationKey){
      authReq = req.clone({
        headers: req.headers
          .set('Content-Type', 'application/json; charset=utf-8')
          .set('SourceKey', 'SurveyorPanel')
          .set('Authorization', environment.authKey)
          .set('LoginKey', `${this.LoginDetails?.LoginID ? this.LoginDetails?.LoginID: ''}`)
          .set('ValidationKey', ValidationKey)
          .set('EncUserTypeID', this.LoginDetails?.EncUserTypeID ? this.LoginDetails?.EncUserTypeID : '')
          .set('EncUserType', this.LoginDetails?.EncUserType ? this.LoginDetails?.EncUserType : '')
          .set('EncClaimID', EncClaimId ? EncClaimId : '')
          .set('EncUserID', this.LoginDetails?.EncUserID ? this.LoginDetails?.EncUserID : '')
      })
      this.activeRequests++;
      return next.handle(authReq)
      // .pipe(
      //   tap(evt => {
      //     if (evt instanceof HttpResponse) {
      //         if(evt?.body?.Error?.includes('Unauthorized Access')) {
      //           this.headerComponent.Logout();
      //         }
      //     }
  
      // }),
      // catchError((err: any) => {
      //   if(err instanceof HttpErrorResponse) {
      //     try {
      //           if(err.error.Error.includes('Unauthorized Access') || err.status == 401){
      //             this.headerComponent.Logout();
      //           }
      //       } catch(e) {
      //       }
      //     }
      //     return of(err);
      //   })
      // )
      .pipe(
        timeout(600000),
        finalize(() => {
          this.stopLoader();
        })
      )
    }
    else{
      this.activeRequests++;
      return next.handle(authReq)
      // .pipe(
      //   tap(evt => {
      //     if (evt instanceof HttpResponse) {
      //         if(evt?.body?.Error?.includes('Unauthorized Access')) {
      //           this.headerComponent.Logout();
      //         }
      //     }
  
      // }),
      // catchError((err: any) => {
      //   if(err instanceof HttpErrorResponse) {
      //     try {
      //           if(err.error.Error.includes('Unauthorized Access') || err.status == 401){
      //             this.headerComponent.Logout();
      //           }
      //       } catch(e) {
      //       }
      //     }
      //     return of(err);
      //   })
      // )
      .pipe(
        timeout(600000),
        finalize(() => {
          this.stopLoader();
        })
      )
    }
  }

  stopLoader() {
    this.activeRequests--;
    if (this.activeRequests === 0) {
      this.loaderService.triggerLoader(false);
    }
  }
}
