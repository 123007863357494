<div class="box">
  <div class="box-header table_header" ng-show="!isLoading">
      <h3 class="box-title">Videos</h3>
      <h3 class="box-title" id="regNo" style="visibility:hidden">{{RegNo}}</h3>
  </div>

  <div class="box-body video_block" ng-show="!isLoading">
      <div *ngIf="VideoDetails.length>0">
          <div class="table-responsive-video">
              <table class="table table-bordered" style="margin:0;">
                  <thead>
                      <tr>
                          <th> Created By</th>
                          <th> Video Date</th>
                      </tr>
                  </thead>
                  <tbody>
                      <tr *ngFor="let Video of VideoDetails">
                          <td><a  (click)="OpenVideo(Video);">{{Video.VideoSource}}&nbsp;({{Video.VideoType}})</a></td>
                          <td> {{Video.CreatedOn | date : "medium"}}</td>
                      </tr>
                  </tbody>
              </table>
          </div>
          <div class="video_container">
              <div>
                  <video id="video" controls="controls" src="{{VideoUrl}}" crossorigin="anonymous"></video>
              </div>
              <img id="output" width="10" />
              <div class="capture_btn">
                  <button id="capture" class="btn btn-primary" [disabled]="captureDisabled">Capture</button>
                  <select id="quality">
                      Pdf Quality
                      <option value="0.3">1</option>
                      <option value="0.35">2</option>
                      <option value="0.4" selected>3</option>
                      <option value="0.45">4</option>
                      <option value="0.5">5</option>
                  </select>
                  <input type="button" class="btn btn-primary" id="screen" value="Enable Screenshot" name="Enable Screenshot" [disabled]="screenshotDisabled" (click)="takeScreen()" />
                  <input type="button" class="btn btn-primary" value="GeneratePdf" name="GeneratePdf" (click)="GeneratePdf()" />
              </div>
              <div class="thumb_block" id="screenshot">
              </div>
          </div>
      </div>
      <div *ngIf="VideoDetails.length==0" style="text-align: center;">
          <span>No Video Found</span>
      </div>
  </div>
</div>
