import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiHttpService } from '../ApiHttpService/apiHttp.service';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PendingService {

  constructor(private api: ApiHttpService) { }
  private API_URL = environment.PathAPI

  getDashboardCount(reqData: any): Observable<any> {
    const url: string = `${this.API_URL}Claim/GetInsurerStatusDashboardCount/${reqData}`
    return this.api.get(url)
  }

  GetInsurerStatusDashboardDetails(reqData: any): Observable<any> {
    const url: string = `${this.API_URL}Claim/GetInsurerStatusDashboardDetails/${reqData}`
    return this.api.get(url)
  }

}
