<app-header [LoginDetails]="LoginDetails"></app-header>
<div class="content-wrapper">
  <section class="content dashboard_table card-eight">
      <div class="row" *ngIf="DashboardCount!=undefined">
          <div class="col-md-6 col-xl-3 small_block" [ngStyle]="{'width' : (100/DashboardCount.length) - 1 + '%'}"  *ngFor="let Counter of DashboardCount">
              <div class="card notification-card" [ngClass]="Counter.CssClass ? Counter.CssClass : 'bg-c-blue'">
                  <div class="card-block">
                      <div class="row align-items-center card-re docker-test" (click)="GetInsurerDashboardDetailsOnClick(Counter.BucketID,Counter.BucketName, Counter.SubRequestType);">
                          <h4>{{Counter.TotalClaims}}</h4>
                          <p>{{Counter.BucketName}}</p>
                          <div class="col-4 notify-icon" (click)="GetInsurerDashboardDetailsOnClick(Counter.BucketID,Counter.BucketName, Counter.SubRequestType);">
                              <i class="fa" [ngClass]="Counter.Icon"></i>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
          <div class="col-md-6 col-xl-3 small_block" [ngStyle]="{'width': (100/DashboardCount.length) - 1 + '%'}" *ngIf="OpenDashboardCount!=undefined">
              <div class="card notification-card bg-teal">
                  <div class="card-block">
                      <div class="row align-items-center card-re" (click)="GetInsurerOpenClaimDashboard(-1,OpenDashboardCount.BucketName);">
                          <h4>{{OpenDashboardCount.TotalClaims}}</h4>
                          <p>{{OpenDashboardCount.BucketName}}</p>
                          <div class="col-4 notify-icon" (click)="GetInsurerOpenClaimDashboard(-1,OpenDashboardCount.BucketName);">
                              <i class="fa ion-ios-list"></i>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
      <div class="row">
          <div class="col-xs-12">
              <div class="box box-primary">
                  <form role="form" [formGroup]="dashboardSeachForm">
                      <div class="box-body">
                          <div class="form-group insurer_filter_block">
                              <input formControlName="PolicyNo" type="text" class="form-control" placeholder="Enter Policy No">
                          </div>
                          <div class="form-group insurer_filter_block">
                              <input formControlName="RegistrationNo" type="text" class="form-control" placeholder="Enter Registration No">
                          </div>
                          <div class="form-group insurer_filter_block">
                              <input formControlName="PBClaimID" type="text" class="form-control" placeholder="Enter PB ReferenceID">
                          </div>
                          <div class="form-group insurer_filter_block dateBorder">
                          <mat-form-field appearance="fill">
                            <mat-date-range-input [formGroup]="dashboardSeachForm" [rangePicker]="picker">
                              <input matStartDate formControlName="start" placeholder="Start date" readonly>
                              <input matEndDate formControlName="end" placeholder="End date" readonly>
                            </mat-date-range-input>
                            <mat-datepicker-toggle matIconSuffix [for]="picker" class="dash_date"></mat-datepicker-toggle>
                            <mat-date-range-picker #picker></mat-date-range-picker>
                          </mat-form-field>
                          </div>
                          <!-- <div class="form-group insurer_filter_block">
                              <div class="input-group">
                                  <div class="input-group-addon"> <i class="fa fa-calendar"></i> </div>

                                  <input formControlName="IntimationDate" type="text" class="form-control pull-right" id="reservation" placeholder="Intimation Date" >
                              </div>
                          </div> -->
                          <div class="form-group filter_block submit_button">
                              <button type="submit" class="btn btn-primary submit_btn" (click)="GetSearchResult()">Search</button>
                          </div>
                      </div>
                  </form>
              </div>
              <div class="box">
                  <div class="box-header table_header table_hd width-100">
                      <h3 class="box-title">{{BucketName}}</h3>
                      <div class="ms-auto col-lg-2 col-md-2 col-sm-12 col-xs-12 float-right">
                        <input [formControl]="tableSearchControl" type="text" class="form-control" placeholder="Search">
                      </div>
                      <a  role="button" (click)="exportToExcelNew()" class="export_exl margin-top-5" *ngIf="ClaimLists.length>0"> Export to Excel</a>
                  </div>
                  <div class="box-body">
                    <app-pb-table [tableData]="ClaimLists" [labels]="dashboardTableLabels" [displayColumns]="dashboardTableColumns" [highPerformanceExport]="true" [exportFunction]="true" (exportTable)="storeExportTable($event)" (linkClicked)="openStatusPage($event)" [isPagination]="true" [isScroll]="true"></app-pb-table>
                    <!-- <p-table [value]="ClaimLists" responsiveLayout="scroll" styleClass="p-datatable-striped p-datatable-sm p-datatable-gridlines"
                            [paginator]="true" [rows]="10" [showCurrentPageReport]="true" [rowsPerPageOptions]="[4,10,15]"
                            paginatorDropdownAppendTo="body">
                      <ng-template pTemplate="header">
                        <tr>
                          <th>{{RefIdText}}ReferenceID</th>
                                  <th>PolicyHolder</th>
                                  <th>PolicyNo</th>
                                  <th>Registration No</th>
                                  <th>City</th>
                                  <th>State</th>
                                  <th>Assigned Garage</th>
                                  <th>Case Age (Days)</th>
                                  <th>Case Age Insurer</th>
                                  <th *ngIf="this.LoginDetails.UserType!=3">Surveyor</th>
                                  <th *ngIf="this.LoginDetails.UserType!=2">Officer</th>
                                  <th >Request Type</th>
                                  <th>InsurerClaimId</th>
                        </tr>
                      </ng-template>
                      <ng-template pTemplate="body" let-ClaimList>
                        <tr>
                          <td>PB{{ClaimList?.ClaimId ? ClaimList?.ClaimId : "--"}}</td>
                          <td class="surveyor">{{ClaimList?.PolicyHolderName ? ClaimList?.PolicyHolderName : '--'}}</td>
                          <td class="surveyor"><a   (click)="Case_Details(ClaimList.ClaimId, ClaimList.EncClaimId)">{{ClaimList?.PolicyNumber ? ClaimList?.PolicyNumber : "--"}}</a></td>
                          <td>{{ClaimList?.RegistrationNo ? ClaimList?.RegistrationNo : "--"}}</td>
                          <td>{{ClaimList?.City ? ClaimList?.City : "--"}}</td>
                          <td>{{ClaimList?.State ? ClaimList?.State : "--"}}</td>
                          <td>{{ClaimList?.GarageName ? ClaimList?.GarageName:'NA'}}</td>
                          <td>{{ClaimList?.CaseAge}}</td>
                          <td>{{ClaimList?.CaseAgeInsurer ? ClaimList?.CaseAgeInsurer : '--'}}</td>
                          <td *ngIf="this.LoginDetails.UserType!=3" class="surveyor">{{ClaimList?.Surveyor ? ClaimList?.Surveyor : '--'}}</td>
                          <td *ngIf="this.LoginDetails.UserType!=2" class="surveyor">{{ClaimList?.Officer ? ClaimList?.Officer : '--'}}</td>
                          <td>{{ClaimList?.RequestType}} ({{ClaimList?.SubRequestType}})</td>
                          <td>{{ClaimList?.InsurerClaimId ? ClaimList?.InsurerClaimId : "--"}}</td>
                        </tr>
                      </ng-template>
                    </p-table> -->
                      <!-- <table id="AllClaims" class="table table-bordered table-striped AssignClaims">
                          <thead>
                              <tr>
                                  <th style="width:87px !important">{{RefIdText}}ReferenceID</th>
                                  <th style="width:75px !important">PolicyHolder</th>
                                  <th style="width:55px !important">PolicyNo</th>
                                  <th>Registration No</th>
                                  <th style="width:40px !important">City</th>
                                  <th style="width:40px !important">State</th>
                                  <th>Assigned Garage</th>
                                  <th style="width:30px !important">Case Age (Days)</th>
                                  <th style="width:30px !important">Case Age Insurer</th>
                                  <th ng-hide="InsurerUserType==3" style="width:52px !important">Surveyor</th>
                                  <th style="width:52px !important" ng-hide="InsurerUserType==2">Officer</th>
                                  <th style="width:70px !important">Request Type</th>
                                  <th>InsurerClaimId</th>
                              </tr>
                          </thead>
                          <tbody>
                              <tr *ngFor="let ClaimList of ClaimLists">
                                  <td>PB{{ClaimList.ClaimId}}</td>
                                  <td class="surveyor">{{ClaimList.PolicyHolderName}}</td>
                                  <td class="surveyor"><a  ng-click="Details(ClaimList.ClaimId);">{{ClaimList.PolicyNumber}}</a></td>
                                  <td>{{ClaimList.RegistrationNo}}</td>
                                  <td>{{ClaimList.City}}</td>
                                  <td>{{ClaimList.State}}</td>
                                  <td>{{ClaimList.GarageName ? ClaimList.GarageName:'NA'}}</td>
                                  <td>{{ClaimList.CaseAge}}</td>
                                  <td>{{ClaimList.CaseAgeInsurer}}</td>
                                  <td ng-hide="InsurerUserType==3" class="surveyor">{{ClaimList.Surveyor}}</td>
                                  <td ng-hide="InsurerUserType==2" class="surveyor">{{ClaimList.Officer}}</td>
                                  <td>{{ClaimList.RequestType}} ({{ClaimList.SubRequestType}})</td>
                                  <td>{{ClaimList.InsurerClaimId}}</td>
                              </tr>
                          </tbody>
                      </table>
                      <table id="AllClaimsExcel" class="table table-bordered table-striped" style="display:none">
                          <thead>
                              <tr>
                                  <th>{{RefIdText}}ReferenceID</th>
                                  <th>PolicyHolder</th>
                                  <th>PolicyNo</th>
                                  <th>Registration No</th>
                                  <th>City</th>
                                  <th>State</th>
                                  <th>Assigned Garage</th>
                                  <th>Case Age (days)</th>
                                  <th ng-hide="InsurerUserType==3">Surveyor</th>
                                  <th ng-hide="InsurerUserType==2">Officer</th>
                                  <th>Request Type</th>
                                  <th>InsurerClaimId</th>
                              </tr>
                          </thead>
                          <tbody>
                              <tr *ngFor="let ClaimList of ClaimLists">
                                  <td>PB{{ClaimList.ClaimId}}</td>
                                  <td>PB{{ClaimList.PolicyHolderName}}</td>
                                  <td>'{{ClaimList.PolicyNumber}}</td>
                                  <td>{{ClaimList.RegistrationNo}}</td>
                                  <td>{{ClaimList.City}}</td>
                                  <td>{{ClaimList.State}}</td>
                                  <td>{{ClaimList.GarageName ? ClaimList.GarageName:'NA'}}</td>
                                  <td>{{ClaimList.CaseAge}}</td>
                                  <td ng-hide="InsurerUserType==3">{{ClaimList.Surveyor}}</td>
                                  <td ng-hide="InsurerUserType==2">{{ClaimList.Officer}}</td>
                                  <td>{{ClaimList.RequestType}} ({{ClaimList.SubRequestType}})</td>
                                  <td>{{ClaimList.InsurerClaimId}}</td>
                              </tr>
                          </tbody>
                      </table> -->
                  </div>
              </div>

          </div>
      </div>
  </section>
</div>
<div class="control-sidebar-bg"></div>
