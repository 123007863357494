import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { ClaimDetailsComponent } from './claim-details.component';
import { VideosComponent } from './videos/videos.component';
import { EstimateListComponent } from './estimate-list/estimate-list.component';
import { CustomerDocumentsComponent } from './customer-documents/customer-documents.component';
import { UploadReportComponent } from './upload-report/upload-report.component';
import { PaymentDetailsComponent } from './payment-details/payment-details.component';
import { GarageDetailsComponent } from './garage-details/garage-details.component';
import { GarageDocumentsComponent } from './garage-documents/garage-documents.component';
import { StatusLogComponent } from './status-log/status-log.component';
import { CommentLogComponent } from './comment-log/comment-log.component';
import { PreviousYearPolicyComponent } from './previous-year-policy/previous-year-policy.component';
import { CoreModule } from '../core/core.module';
import { MaterialModule } from './../../library-modules/material/material/material.module';

@NgModule({
  declarations: [
    ClaimDetailsComponent,
    VideosComponent,
    EstimateListComponent,
    CustomerDocumentsComponent,
    UploadReportComponent,
    PaymentDetailsComponent,
    GarageDetailsComponent,
    GarageDocumentsComponent,
    StatusLogComponent,
    CommentLogComponent,
    PreviousYearPolicyComponent
  ],
  imports: [
  CommonModule,
  FormsModule,
  ReactiveFormsModule,
  CoreModule,
  MaterialModule
  ],
  exports: [ClaimDetailsComponent]
})
export class ClaimDetailsModule { }
