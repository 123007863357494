import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiHttpService } from '../ApiHttpService/apiHttp.service';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  constructor(private api: ApiHttpService) { }

  private API_URL = environment.PathAPI
  GetInsurerLoginDetails_v2(reqData: any): Observable<any> {
    const url: string = `${this.API_URL}Login/GetInsurerDetail_v2/`+ reqData.UserName;
    return this.api.get(url)
  };

  VerifyOtp(reqData: any): Observable<any> {
    const headers = { "Content-Type": "application/json; charset=utf-8" }
    const url: string = this.API_URL + "Login/VerifyOTP_v2";
    return this.api.post(url, JSON.stringify(reqData), { 'headers': headers })
  };

  Logout(reqData: any): Observable<any> {
    const headers = { "Content-Type": "application/json; charset=utf-8" }
    const url: string = this.API_URL + "Login/SetInsurerLogoutDetails";
    return this.api.post(url, JSON.stringify(reqData), { 'headers': headers })
  }

}
