import { Injectable } from '@angular/core';
import { CommonService } from './../services/commonService/common.service';

@Injectable({
  providedIn: 'root'
})
export class Utils {

  constructor(private commonService:CommonService) { }

  GetFileUrlByUniqueDocId(DocuUniqueId: any, DocumentID: any, MasterDocumentId: any, ClaimDetail: any){
    if (!DocuUniqueId && DocumentID==58) {
      window.open(ClaimDetail.DocumentUrl, '_blank');
      return;
    }


    let reqData = {
      docId: DocuUniqueId,
      custId: ClaimDetail.CustomerId,
      documentId: DocumentID,
      masterDocumentId: MasterDocumentId
    }

    console.log(`reqData: ${JSON.stringify(reqData)}`)

    this.commonService.GetFileUrlByUniqueDocId(reqData).subscribe((res: any)=> {
      if(res){
        let ttLlDocUrl = res.Data;
        window.open(ttLlDocUrl, '_blank');
      } else {
          //this.ErrorMsg = "Error : Something went wrong!";
      }
    })
  }

  async get_file_array(file: any){
    return new Promise((acc: any, err: any) => {
        const reader = new FileReader();
        reader.onload = (event: any) => { acc(event.target.result) };
        reader.onerror = (err: any) => { err(err) };
        reader.readAsArrayBuffer(file);
    });
  }

  _arrayBufferToBase64(buffer: any) {
      var binary = '';
      var bytes = new Uint8Array(buffer);
      var len = bytes.byteLength;
      for (var i = 0; i < len; i++) {
          binary += String.fromCharCode(bytes[i]);
      }
      return window.btoa(binary);
  }


}
