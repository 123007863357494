<div class="box box-primary">
  <div class="box-header with-border">
      <h3 class="box-title">Comment Log</h3>
      <div style="float:right;">
          <a  data-toggle="modal" data-target="#modal-new" (click)="GetClaimHistory();">View More</a>
      </div>
  </div>
  <div class="box-body">
      <form [formGroup]="CommentForm">
        <div class="form-group">
          <textarea class="form-control" rows="3" placeholder="Enter Comment" formControlName="comment"></textarea>
        </div>
        <div>
            <a  class="btn btn-primary btn-block small_btn" (click)="SetComments();">
                <b>Add Comment</b>
            </a>
        </div>
      </form>

      <div class="direct-chat-messages chat_box">
          <!-- <div class="direct-chat-msg" ng-repeat="History in ClaimHistory | limitTo : 5"> -->
          <div class="direct-chat-msg" *ngFor="let History of ClaimHistory">
              <div class="direct-chat-info clearfix">
                  <span class="direct-chat-name pull-left">{{History.ActionBy}}</span>
                  <span class="direct-chat-timestamp pull-right comments">{{History.CreatedOn | date:'medium'}}</span>
              </div>
              <img class="direct-chat-img" [src]="userImg" alt="Message User Image"><!-- /.direct-chat-img -->
              <div class="direct-chat-text">
                  {{History.Comments}}
              </div>
          </div>
      </div>
  </div>
</div>

<div class="modal fade" id="modal-new">
  <div class="modal-dialog modal_container">
      <div class="modal-content ">
          <div class="modal-header">
              <h4 class="commnt_heading">Comments<button type="button" class="close" data-dismiss="modal" aria-label="Close"> <span aria-hidden="true">&times;</span></button></h4>
          </div>
          <div class="modal-body">
              <table class="table table-bordered white_box">
                  <tbody>
                      <tr>
                          <th>Status</th>
                          <th>Sub-Status</th>
                          <th width="40%">Comments</th>
                          <th>Date</th>
                          <th>Action By</th>
                      </tr>
                      <tr *ngFor="let History of ClaimHistory">
                          <td>{{History.StatusName}}</td>
                          <td>{{History.SubStatusName}}</td>
                          <td width="40%">{{History.Comments}}</td>
                          <td>{{History.CreatedOn | date:'d MMM y h:mm a'}}</td>
                          <td>{{History.ActionBy}}</td>
                      </tr>
                  </tbody>
              </table>
          </div>
      </div>
  </div>
</div>
