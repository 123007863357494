import { Injectable } from '@angular/core';
import { ApiHttpService } from '../ApiHttpService/apiHttp.service';
import { environment } from '../../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class LoggingService {

  constructor(private api: ApiHttpService) { }
  private API_URL = environment.PathAPI
  logError(message: string, stack: string) {
    console.log(message, stack);
  }

  postErrorOnServer(reqData: any){
    const headers = { "Content-Type": "application/json; charset=utf-8" }
    const url: string = `${this.API_URL}surveyorPanel/SetErrorLogs`;

    return this.api.post(url, JSON.stringify(reqData), { 'headers': headers })


  }
}
