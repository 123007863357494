<head>
    <link rel="preconnect" href="https://fonts.googleapis.com">
    <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
    <link href="https://fonts.googleapis.com/css2?family=Gabarito:wght@600&family=Roboto:wght@700&display=swap" rel="stylesheet">
</head>
<div class="container-flex">
    <div class="row">
        <div class="hide-overflow" [ngClass]="{'purple-back': badFeedback, 'green-back': !badFeedback, 'col-lg-4 col-md-4 col-sm-4 col-xs-4': feedbackData?.FeedbackType == 'ClaimsFeedbackPB', 'col-lg-12 col-md-12 col-sm-12 col-xs-12': feedbackData?.FeedbackType != 'ClaimsFeedbackPB'}">
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 transition-all" [ngClass]="{'text-center': feedbackData?.FeedbackType != 'ClaimsFeedbackPB'}">
                <img [ngClass]="{'pb-logo': feedbackData?.FeedbackType == 'ClaimsFeedbackPB', 'pb-logo-full': feedbackData?.FeedbackType != 'ClaimsFeedbackPB'}" src="../../../../Content/images/pb-white-logo.png">
            </div>
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8 m-l-v5 text-container" [ngClass]="{'full-screen text-center': feedbackData?.FeedbackType != 'ClaimsFeedbackPB'}">
                    <span class="main-text">{{textDisplay}}</span>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6" [ngClass]="{'col-lg-8 col-md-8 col-sm-8 col-xs-8 mx-auto': badFeedback, 'col-lg-6 col-md-6 col-sm-6 col-xs-6': !badFeedback}">
                    <img class="graphic" [ngClass]="{'margin-left-4N': !badFeedback}" [src]="!badFeedback ? '../../../../Content/images/all-done-csat.png' : '../../../../Content/images/csat-graphic.png'">
                </div>
                <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 ms-auto mirror-right margin-right-3N" *ngIf="!badFeedback" [ngClass]="{'mx-auto': badFeedback}">
                    <img class="graphic" [src]="!badFeedback ? '../../../../Content/images/all-done-csat.png' : '../../../../Content/images/csat-graphic.png'">
                </div>
            </div>
        </div>
        <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8 selection-container" *ngIf="feedbackData?.FeedbackType == 'ClaimsFeedbackPB'">
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 m-l-v15" [ngClass]="{'margin-top-18': !badFeedback, 'margin-top-10': badFeedback}">
                    <span class="question-text">{{feedbackText}}</span>
                </div>
            </div>
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 m-l-v15">
                    <mat-radio-group
                    aria-labelledby="example-radio-group-label"
                    class="example-radio-group"
                    [formControl]="selectionControl"
                    *ngIf="badFeedback"
                    >
                    <div class="radio-custom-button" [@borderChange]="selectionControl.value == reason ? !badFeedback ? 'onGood' : 'on' : 'off'" (click)="selectReason(reason)" *ngFor="let reason of reasons">
                        <mat-radio-button class="marginpx-top-10" [value]="reason">
                            {{reason}}
                        </mat-radio-button>
                    </div>
                  </mat-radio-group>
                  <div class="w-80 comment-box">
                    <mat-form-field appearance="outline" >
                        <textarea placeholder="Comments" [formControl]="commentControl" class="min-h-100" matInput></textarea>
                      </mat-form-field>
                  </div>
                </div>
            </div>
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 m-l-v15 submit-button">
                    <button class="w-100" [ngClass]="{'good-button': !badFeedback, 'bad-button': badFeedback}" mat-raised-button (click)="initFeedbackSubmit()">Submit</button>
                </div>
            </div>

        </div>
</div>
</div>


