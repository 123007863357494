import { Component, OnInit } from '@angular/core';
import { SharedService } from './../../services/SharedService/sharedService';
import { ActivatedRoute } from '@angular/router';
import { FeedbackService } from './../../services/FeedbackService/feedback.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { catchError } from 'rxjs';

@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.css']
})
export class FeedbackComponent implements OnInit {

  constructor(
    private sharedService: SharedService,
    private activatedRoute: ActivatedRoute,
    private feedbackService: FeedbackService,
    private snackBar: MatSnackBar
    ) { }
  pbLogo = ''
  loginDetails: any;
  urlParams: any;
  OnLoadVariables:any =  {
    requestType: '',
    encClaimId: '',
    agentRatingId: '',
    rating: ''
  }
  ClaimCustDetails: any = {
    EncClaimId: '',
    AgentRating: '',
    InsurerRating: 0,
    FeedbackNote: '',
    FeedbackMode: 'Email',
    IsSubmit: false,
    CreatedBy: 124,
    InsurerRatingForPlatinum: 0
  }
  feedbackDetails: any = {};
  ProductId: any = '';
  feedbackForm: FormGroup = new FormGroup({
    insurerRating: new FormControl(''),
    feedbackNote: new FormControl('', Validators['required']),
    insurerRatingForPlatinum: new FormControl('')
  })
  Ques: any;
  IsFeedBackSubmittedOneTime: any = false;
  ngOnInit(): void {
    this.pbLogo = 'assets/images/logopb.svg';
    this.sharedService.userDetailsSubject$.subscribe(data => {
      if(typeof data == 'string'){
        this.loginDetails = JSON.parse(data);
      }
      else{
        this.loginDetails = data;
      }
    });

    // if(this.loginDetails.length>0){
    //   this.feedbackDetails.IsFeedbackPageHide = true;
    //   this.feedbackDetails.LinkExpired = true;
    //   this.feedbackDetails.IsFeedBackSubmitted = true;
    //   this.feedbackDetails.IsFeedBackSubmittedOneTime = true;
    // }
    // else{
    //   this.feedbackDetails.LinkExpired = false;
    //   this.feedbackDetails.IsFeedBackSubmitted = false;
    //   this.feedbackDetails.IsFeedBackSubmittedOneTime = false;
    // }

    this.activatedRoute.params.subscribe((event: any)=>{
      if(event.params){
        this.urlParams = atob(decodeURIComponent(event.params)).split(':');
        this.OnLoadVariables.encClaimId = this.urlParams[0]
        this.OnLoadVariables.requestType = this.urlParams[1]
        this.OnLoadVariables.agentRatingId = this.urlParams[2]
        this.ClaimCustDetails.EncClaimId = this.urlParams[0]
        this.ClaimCustDetails.AgentRating = this.urlParams[2]
      }
    })

    if(this.OnLoadVariables.encClaimId){

      this.feedbackService.getCustomerFeedbackReport(btoa(this.OnLoadVariables.encClaimId)).subscribe((data)=>{
        if(data?.Data?.length > 0){
          this.feedbackDetails = data.Data[0]
          this.ProductId = this.feedbackDetails.ProductId
          if (!this.feedbackDetails.IsFeedbackPageHide) {
            if (!this.feedbackDetails.FeedbackSubmitted && !this.feedbackDetails.LinkExpired) {
              this.sendCustomerFeedback(false);
            }
        }
        }
        else {
          this.feedbackDetails = {}
          this.feedbackDetails.IsFeedbackPageHide = true;
        }

      })
    }

    //this.activatedRoute

  }

  sendCustomerFeedback(isSubmit: any){
    this.ClaimCustDetails.IsSubmit = isSubmit
    this.ClaimCustDetails.InsurerRating = this.feedbackForm.controls['insurerRating'].value ? parseInt(this.feedbackForm.controls['insurerRating'].value) : 0
    this.ClaimCustDetails.FeedbackNote = this.feedbackForm.controls['feedbackNote'].value ? this.feedbackForm.controls['feedbackNote'].value : ''
    if(this.ProductId == 2){
      this.ClaimCustDetails.InsurerRatingForPlatinum = this.feedbackForm.controls['insurerRatingForPlatinum'].value ? this.feedbackForm.controls['insurerRatingForPlatinum'].value : 0
    }
    this.ClaimCustDetails.AgentRating = isNaN(parseInt(this.ClaimCustDetails.AgentRating)) ? 0 : parseInt(this.ClaimCustDetails.AgentRating);
    this.feedbackService.sendCustomerFeedback(this.ClaimCustDetails).pipe(catchError(err => {
      this.snackBar.open("Something went wrong. Please try again later.", 'Okay!', {
        duration: 3000,
        panelClass: ['error-snack'],
      });
      return err;
    })).subscribe((data: any)=>{
      if(data.Data){
        this.feedbackDetails.LinkExpired = data.Data.LinkExpired
        this.IsFeedBackSubmittedOneTime = data.Data.IsFeedBackSubmitted
      }
      else {
        this.snackBar.open("Something went wrong. Please try again later.", 'Okay!', {
          duration: 3000,
          panelClass: ['error-snack'],
        });
      }
    })

  }

  setQues(){
      if (this.OnLoadVariables.agentRating == 1 && this.ClaimCustDetails.InsurerRating == 1 && this.ClaimCustDetails.InsurerRatingForPlatinum == 1)
          this.Ques = "Would you like to send a note of appreciation to our agent " + this.feedbackDetails.AgentName + " and tell us your experience at the hospital in regard to platinum benefits?";
      else
          this.Ques = "Help us to improve our services with your valuable suggestions and tell us your experience at the hospital in regard to platinum benefits.";
  }

}
