<nav role="navigation" class="navbar">
  <div class="container">
      <a href="//www.policybazaar.com/" >
        <img [src]="pbLogo" class="logo-box" />
      </a>
  </div>
</nav>
<div class="container feedbackWrapper" *ngIf="feedbackDetails?.IsFeedbackPageHide">
  <div class="feedbackMsg" >
     The page you are looking for does not exist. Sorry :(
  </div>
</div>
<div *ngIf="ProductId == 117 && !feedbackDetails?.IsFeedbackPageHide">
  <div class="container feedbackWrapper">
    <div class="feedbackMsg" *ngIf="!feedbackDetails?.LinkExpired && !feedbackDetails?.IsFeedBackSubmitted && !IsFeedBackSubmittedOneTime && ClaimCustDetails.AgentRating==1">
        <!-- We appreciate you rating our agent as <span>{{Rating}}</span>. We would request you to complete this short feedback survey. -->
    </div>
    <div class="feedbackMsg" *ngIf="!feedbackDetails?.LinkExpired && !feedbackDetails?.IsFeedBackSubmitted && !IsFeedBackSubmittedOneTime && ClaimCustDetails.AgentRating==2">
        Thank you for your rating, kindly complete the short feedback survey & let us know how we can improve our services.
    </div>
    <div class="feedbackMsg" *ngIf="!feedbackDetails?.LinkExpired && !feedbackDetails?.IsFeedBackSubmitted && !IsFeedBackSubmittedOneTime && ClaimCustDetails.AgentRating==3">
        We are sorry to hear that our services did not meet your expectations. We would appreciate if you let us know how we can improve our services through your feedback.
    </div>
    <div class="feedbackMsg" *ngIf="feedbackDetails?.LinkExpired">
        Sorry, The Feedback link has expired !!
    </div>
    <div class="feedbackMsg" *ngIf="feedbackDetails?.IsFeedBackSubmitted">
        Thank you for revisiting, we have already received your feedback for PB{{feedbackDetails?.ClaimId}}
    </div>
    <div class="feedbackMsg" *ngIf="IsFeedBackSubmittedOneTime">
        Thank you for your feedback for PB{{feedbackDetails?.ClaimId}}
    </div>
    <div class="feedbackBox" *ngIf="!feedbackDetails?.LinkExpired && !feedbackDetails?.IsFeedBackSubmitted && !IsFeedBackSubmittedOneTime">
        <form [formGroup]="feedbackForm">
            <div>
                <div class="questionBlock" *ngIf="OnLoadVariables?.requestType==5">
                    <p>How satisfied are you with the services provided by the Insurer, {{feedbackDetails?.InsurerName}}?</p>
                    <div class="options">
                        <label class="awesome">
                            <input type="radio" name="insurerRating" formControlName="insurerRating" value="1">
                            <span>Good</span>
                        </label>
                        <label class="sad">
                            <input type="radio" name="insurerRating" formControlName="insurerRating" value="3">
                            <span>Bad</span>
                        </label>
                    </div>
                </div>

                <div class="questionBlock">
                    <p *ngIf="ClaimCustDetails.AgentRating==1">Would you like to send a note of appreciation to {{feedbackDetails?.AgentName}}?</p>
                    <p *ngIf="OnLoadVariables?.agentRating>1">What could have been done better? Help us with your valuable suggestions to help us serve you better in the future.</p>
                    <textarea *ngIf="ClaimCustDetails.AgentRating>0" placeholder="Enter Comment" formControlName="feedbackNote" required></textarea>
                </div>
            </div>
            <div class="submitFeedback">
                <button type="submit" (click)="sendCustomerFeedback(true);" [disabled]="feedbackForm.invalid">Submit</button>
            </div>
        </form>
    </div>
  </div>
</div>

<div *ngIf="ProductId==2 && !feedbackDetails?.IsFeedbackPageHide">
  <div class="container feedbackWrapper" *ngIf="!feedbackDetails?.IsFeedbackPageHide">
      <div class="feedbackMsg" *ngIf="!feedbackDetails?.LinkExpired && !feedbackDetails?.IsFeedBackSubmitted && !IsFeedBackSubmittedOneTime && ClaimCustDetails.AgentRating==1">
          We appreciate you rating our agent Awesome. Please let us know what you liked about services.
      </div>
      <div class="feedbackMsg" *ngIf="!feedbackDetails?.LinkExpired && !feedbackDetails?.IsFeedBackSubmitted && !IsFeedBackSubmittedOneTime && ClaimCustDetails.AgentRating==2">
          Thank you for rating our agent. Please let us know how we could improve our services.
      </div>
      <div class="feedbackMsg" *ngIf="!feedbackDetails?.LinkExpired && !feedbackDetails?.IsFeedBackSubmitted && !IsFeedBackSubmittedOneTime && ClaimCustDetails.AgentRating==3">
          Apologies for not meeting your expectations. Please give us your valuable suggestions so that we can improve.
      </div>
      <div class="feedbackMsg" *ngIf="feedbackDetails?.LinkExpired">
          Sorry, The Feedback link has expired !!
      </div>
      <div class="feedbackMsg" *ngIf="feedbackDetails?.IsFeedBackSubmitted">
          Thank you for revisiting, we have already received your feedback for PB Ref. PB{{feedbackDetails?.ClaimId}}
      </div>
      <div class="feedbackMsg" *ngIf="IsFeedBackSubmittedOneTime">
          Thank you for your feedback for PB Ref. PB{{feedbackDetails?.ClaimId}}
      </div>
      <div class="feedbackBox" *ngIf="!feedbackDetails?.LinkExpired && !feedbackDetails?.IsFeedBackSubmitted && !IsFeedBackSubmittedOneTime">
          <form [formGroup]="feedbackForm">
              <div>
                  <div class="questionBlock" *ngIf="OnLoadVariables?.requestType==2 && OnLoadVariables?.agentRating<3">
                      <p>How was your experience with your insurer, {{feedbackDetails?.InsurerName}}?</p>
                      <div class="options" >
                          <label class="awesome">
                              <input type="radio" name="insurerRating" formControlName="insurerRating" value="1" (click)="setQues()">
                              <span>Good</span>
                          </label>
                          <label class="sad">
                              <input type="radio" name="insurerRating" formControlName="InsurerRating" value="3" (click)="setQues()">
                              <span>Bad</span>
                          </label>
                      </div>
                      <br />
                      <br/>
                      <div *ngIf="feedbackDetails?.IsPlatinum">
                          <p>How satisfactory has treatment experience been for you with our platinum benefits?</p>
                          <div class="options">
                              <label class="awesome">
                                  <input type="radio" formControlName="insurerRatingForPlatinum" value="1" (click)="setQues()">
                                  <span>Good</span>
                              </label>
                              <label class="sad">
                                  <input type="radio" name="InsurerRatingForPlatinum" ng-model="ClaimCustDetails.InsurerRatingForPlatinum" value="3" (click)="setQues()">
                                  <span>Not Satisfied</span>
                              </label>
                          </div>
                      </div>
                  </div>

                  <div class="questionBlock">
                      <p *ngIf="ClaimCustDetails.AgentRating<=2 && OnLoadVariables.requestType==2">{{Ques}}</p>
                      <p *ngIf="OnLoadVariables.agentRating==3 && !feedbackDetails?.IsPlatinum && OnLoadVariables.requestType==2">Apologies! Please tell us in brief how we could improve our services?</p>
                      <p *ngIf="OnLoadVariables.agentRating==3 && feedbackDetails?.IsPlatinum && OnLoadVariables.requestType==2">Apologies! Tell us your experience with platinum benefits and please let us know in brief how we could improve our services?</p>

                      <p *ngIf="OnLoadVariables.agentRating==1 && OnLoadVariables.requestType==1">Would you like to send a note of appreciation to our agent, {{feedbackDetails?.AgentName}}?</p>
                      <p *ngIf="OnLoadVariables.agentRating==2 && OnLoadVariables.requestType==1">Please let us know how we could serve you better and how you would assess our claim representative?</p>
                      <p *ngIf="OnLoadVariables.agentRating==3 && OnLoadVariables.requestType==1">Apologies! Tell us how you would assess our claim representative. Please let us know how we could improve our services?</p>

                      <textarea *ngIf="ClaimCustDetails.AgentRating>0" placeholder="Enter Comment" formControlName="feedbackNote"></textarea>
                  </div>
              </div>
              <div class="submitFeedback">
                  <button type="submit" (click)="sendCustomerFeedback(true);" [disabled]="feedbackForm.invalid">Submit</button>
              </div>
          </form>
      </div>
  </div>
</div>
