import { Component, OnInit, Input, SimpleChanges } from '@angular/core';
import { SharedService } from './../../../services/SharedService/sharedService';
import {FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Utils } from './../../../utils/utils';
import { SurveyorService } from './../../../services/SurveyorService/surveyor.service';
import { CommonService } from 'src/app/services/commonService/common.service';

@Component({
  selector: 'app-upload-report',
  templateUrl: './upload-report.component.html',
  styleUrls: ['./upload-report.component.css']
})
export class UploadReportComponent implements OnInit {

  constructor(
    private sharedService: SharedService,
    private surveyorService: SurveyorService,
    private commonService: CommonService,
    private fb: FormBuilder,
    private utils: Utils
    ) { }
  @Input() LoginDetails: any;
  @Input() ClaimDetail: any;
  EstimateDetails: any;
  UploadReportForm! :FormGroup;
  SurveyReportForm! : FormGroup;
  DocumentDataList: any;
  PreReport = "assets/ExcelDownload/PreliminaryReport.xlsx";
  SurveyorDocs = {
    Type: 0,
    DocID: 0,
    ProductID: 0,
    ReportType: 0,
    DocName: '',
    FileStream: '',
    Comment: '',
    DocStatusID: 0,
    DocFileURL: '',
    CreatedBy: 0,
    GarageId: 0,
    DocUniqueId: 0,
    CustomerID: 0
  };
  SurveyorDocsList: any;
  ErrorMsg: string = '';
  SurveyorDocsDetail: any = {
    ClaimID: '',
    SurveyorDocs: []
  };
  SendDO = "Send DO";
  IsMapping = false;
  IsSendMailDO = false;
  SurveyorDocsListLength: any;
  ngOnInit(): void {
    // if(this.ClaimDetail.ClaimId){
    //   this.sharedService.estimateDetailsSubject$.subscribe((data:any)=>{
    //     if(Object.keys(data).length > 0){
    //       this.EstimateDetails = data;
    //     this.EstimateDetails = this.EstimateDetails.filter((val: any, index: any, self: any)=>
    //   self.findIndex((i:any) => i.GarageID === val.GarageID) === index)
    //     console.log(`this.EstimateDetails: ${JSON.stringify(this.EstimateDetails)}`)
    //     }
    //   })
    //   this.SurveyorDocs.ProductID = this.ClaimDetail.ProductID
    //   this.SurveyorDocs.CreatedBy = this.LoginDetails.UserId
    //   this.IsMapping = true;
    //   this.IsSendMailDO = true;
    //   this.SurveyorDocsDetail.ClaimID = this.ClaimDetail.ClaimId
    //   this.GetSurveyorDocs();
    // }

    this.UploadReportForm = this.fb.group({
      SelectedGarage: ['', [Validators.required]],
      DocName: ['', [Validators.required]],
      ReportType: ['1', [Validators.required]]
    })

    this.SurveyReportForm = this.fb.group({
      ActionId: ['1', [Validators.required]],
      IsUploadedAtGCPortal: [false]
    })

  }

  ngOnChanges(changes: SimpleChanges){
    if(changes['ClaimDetail']){
      this.sharedService.estimateDetailsSubject$.subscribe((data:any)=>{
        if(Object.keys(data).length > 0){
          this.EstimateDetails = data;
        this.EstimateDetails = this.EstimateDetails.filter((val: any, index: any, self: any)=>
      self.findIndex((i:any) => i.GarageID === val.GarageID) === index)
        }
      })
      console.log(`this.EstimateDetails after sharedService being called: ${JSON.stringify(this.EstimateDetails)}`)
      this.SurveyorDocs.ProductID = this.ClaimDetail.ProductID
      this.SurveyorDocs.CreatedBy = this.LoginDetails.UserId
      this.IsMapping = true;
      this.IsSendMailDO = true;
      this.SurveyorDocsDetail.ClaimID = this.ClaimDetail.ClaimId
      this.GetSurveyorDocs();
    }
  }

  SetSurveyorDocs(){
    if(!this.DocumentDataList){
      alert('Please select the file first !!!');
      return;
    }

    let reqData = {
      CustomerId: this.ClaimDetail.CustomerId?this.ClaimDetail.CustomerId:0,
      LeadID: this.ClaimDetail.LeadId?this.ClaimDetail.LeadId: (this.ClaimDetail.ClaimId?this.ClaimDetail.ClaimId:0),
      ProductID: this.LoginDetails.ProductID,
      FileName: this.DocumentDataList[0].FileName,
      FileStream: this.DocumentDataList[0].FileStream,
      DocTypeId: 0,
      DocCategoryId: 0,
      UploadedBy: "MiscInsurerDoc"
    }

    this.surveyorService.uploadSurveyorDocs(reqData).subscribe((res: any)=>{
      if (res.Data != null) {
        this.SurveyorDocs.DocFileURL = res.Data.Url;
        this.SurveyorDocs.DocName = this.UploadReportForm.value.DocName;
        this.SurveyorDocs.DocUniqueId = res.Data.DocUniqueId;
        this.SetSurveyorDocument();
    }
    })

  }

  fileUpload(element: any){
    this.DocumentDataList = [];
    var reader = new FileReader();
    reader.onload = (e:any) => {
        this.uploadUnprotectedFile(element);
    }
    if (element.target.files.length > 0) {
      reader.readAsBinaryString(element.target.files[0]);
  }
    //reader.readAsText(element);
  }

  uploadUnprotectedFile(inputValue: any) {
    var file: File = inputValue.target.files[0];

    let DocumentData: any = {
        FileName: undefined,
        Size: undefined,
        FileStream: undefined
    };

    DocumentData.FileName = file.name;
    DocumentData.Size = file.size;

    var DocNameSplitLength = file.name.split('.').length;
    var FileExtension = (file.name.split('.')[DocNameSplitLength - 1]).toLowerCase();

    this.utils.get_file_array(file).then((res:any)=>{
      DocumentData.FileStream = this.utils._arrayBufferToBase64(res)

      if (DocumentData.FileStream) {
        this.DocumentDataList.push(DocumentData);
        return;
      }
    })
  }

  SetSurveyorDocument(){
    this.SurveyorDocsDetail.SurveyorDocs = [];
    if (this.DocumentDataList.length > 0) {
        this.SurveyorDocs.Type = 1;
        this.SurveyorDocs.CustomerID = 0;
        this.SurveyorDocs.FileStream = "";
        //this.SurveyorDocs.GarageId = this.SelectedGarage.GarageID;
        this.SurveyorDocsDetail.ClaimID = this.ClaimDetail.ClaimId
        this.SurveyorDocsDetail.SurveyorDocs.push(this.SurveyorDocs);

        let reqData = this.SurveyorDocsDetail;

        this.surveyorService.SetSurveyorDocs(reqData).subscribe((res: any)=>{

          if (res.Data) {
              //angular.element("input[type='file']").val(null);
              this.GetSurveyorDocs();
              alert("Report Uploaded Successfully!! Please Mark Status to process Next Level !!");
          }
          else {
              this.ErrorMsg = "Error : Something went wrong!";
          }

        })
    } else {
          alert("Please upload file first.");
    }
  }

  GetSurveyorDocs() {
    var reqData = this.ClaimDetail.ClaimId + "/" + this.LoginDetails.UserType;
    if(this.ClaimDetail?.ClaimId != 0 && this.LoginDetails?.UserType != 0) {
      this.surveyorService.GetSurveyorDocs(reqData).subscribe((res: any)=>{
        if (res && res.Data) {
          this.SurveyorDocsList = res.Data;
          this.SurveyorDocsList = this.SurveyorDocsList.map((item:any)=>{
            item.formControlActionId = `ActionId_${item.DocID}`
            item.formControlIsUploaded = `IsUploaded_${item.DocID}`
  
            this.SurveyReportForm.addControl(item.formControlActionId, new FormControl(false))
            this.SurveyReportForm.addControl(item.formControlIsUploaded, new FormControl(false))
            return item;
          })
  
          console.log(`this.SurveyorDocsList: ${JSON.stringify(this.SurveyorDocsList)}`)
  
          if(this.SurveyorDocsList.length<0){
            this.SurveyorDocsListLength = true
          }
          else{
            this.SurveyorDocsListLength = false
          }
  
        } else {
            this.ErrorMsg = "Error : Something went wrong!";
        }
      })
    }
  }

  // GetFileUrlByUniqueDocId(DocuUniqueId: any, DocumentID: any, MasterDocumentId: any){
  //   this.utils.GetFileUrlByUniqueDocId(DocuUniqueId, DocumentID, MasterDocumentId, this.ClaimDetail)
  // }

  GetFileUrlByUniqueDocId(DocuUniqueId: any, DocumentID: any, MasterDocumentId: any, EncDocuUniqueId: any, Url:any = null) {

    let GetFileUrlByUniqueDocIdRequest = {
      docId: DocuUniqueId,
      custId: this.ClaimDetail.CustomerId,
      documentId: DocumentID,
      masterDocumentId: MasterDocumentId,
      EncDocuUniqueId: EncDocuUniqueId

    }
    var reqData = GetFileUrlByUniqueDocIdRequest;
    this.commonService.GetFileUrlByUniqueDocId(reqData).subscribe(response => {
      if (response && response.Data) {
        window.open(response.Data, '_blank');
      }
      else if (this.ClaimDetail.DocumentUrl && DocumentID == 58)
        window.open(this.ClaimDetail.DocumentUrl, '_blank');
      else if (Url)
        window.open(Url, '_blank');
      else {
        alert("Error : Something went wrong!");
      }

    })
  }


  GetFileUrlByUniqueDocIdForDoc(DocuUniqueId: any, DocumentID: any, MasterDocumentId: any, EncDocuUniqueId:any, Url?:any) {
    if (!DocuUniqueId)
      window.open(Url, '_blank');
    else
      this.GetFileUrlByUniqueDocId(DocuUniqueId, DocumentID, MasterDocumentId, EncDocuUniqueId,Url);
  }


  ActionOnUploadedDoc(DocID: any, Type: any) {
    this.SurveyorDocsDetail.SurveyorDocs = [];
    if (Type == 2) {
      this.SurveyorDocsList.forEach((element: any) => {
        if ((element.DocStatusID == 0 || this.SurveyReportForm.controls[element.formControlIsUploaded].value == true || this.SurveyReportForm.controls[element.formControlIsUploaded].value == false) && this.SurveyReportForm.controls[element.formControlActionId].value != 'undefined') {
          let DocDetails: any = {
            Type: Type,
            DocID: element.DocID,
            ProductID: this.LoginDetails.ProductID,
            ReportType: element.ReportTypeID,
            DocName: element.DocName,
            FileStream: '',
            Comment: element.Comment,
            DocStatusID: this.SurveyReportForm.controls[element.formControlActionId].value,
            DocFileURL: element.DocFileURL,
            CreatedBy: this.LoginDetails.UserId,
            IsUploadedAtGCPortal: this.SurveyReportForm.controls[element.formControlIsUploaded].value
          }
          this.SurveyorDocsDetail.SurveyorDocs.push(DocDetails);
        }
      });
    }
    else if (Type == 3) {
      this.SurveyorDocsList.forEach((element: any) => {
        if (element.DocID == DocID) {
          let DocDetails = {
              Type: Type,
              DocID: element.DocID,
              ProductID: this.LoginDetails.ProductID,
              ReportType: element.ReportTypeID,
              DocName: element.DocName,
              FileStream: '',
              Comment: element.Comment,
              DocStatusID: element.ActionId,
              DocFileURL: element.DocFileURL,
            CreatedBy: this.LoginDetails.UserId,
            IsUploadedAtGCPortal: element.IsUploadedAtGCPortal
          }
          this.SurveyorDocsDetail.SurveyorDocs.push(DocDetails);
        }
      });
    }

    this.surveyorService.SetSurveyorDocs(this.SurveyorDocsDetail).subscribe((res: any)=>{
      if (res.Data) {
        alert("Document Status Updated Successfully!");
        this.GetSurveyorDocs();
      }
      else {
          this.ErrorMsg = "Error : Something went wrong!";
      }
    })
  }

  SendMailDO(){

  }

}
