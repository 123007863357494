import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { SurveyorService } from './../../../services/SurveyorService/surveyor.service';
import { SharedService } from './../../../services/SharedService/sharedService';

@Component({
  selector: 'app-status-log',
  templateUrl: './status-log.component.html',
  styleUrls: ['./status-log.component.css']
})
export class StatusLogComponent implements OnInit {

  constructor(private surveyorService: SurveyorService, private fb: FormBuilder, private sharedService: SharedService) { }
  @Input() LoginDetails: any;
  @Input() ClaimDetail: any;
  statusLogForm!: FormGroup;
  StatusMasters: any;
  status: any = [];
  subStatus: any = [];
  ErrorMsg: string = '';
  @Output('GetClaimDetails') GetClaimDetails: EventEmitter<any> = new EventEmitter();

  ngOnInit(): void {
    this.statusLogForm = this.fb.group({
      status: ['', [Validators.required]],
      subStatus: [{ value: '', disabled: true}, [Validators.required]]
    })
    if(this.ClaimDetail.ClaimId){

      this.sharedService.statusMasterSubject$.subscribe((data: any)=> {
        if(data.length>0){
          this.StatusMasters = data
          this.status = this.StatusMasters.filter((item: any, i: any, stat: any)=>stat.findIndex((t: any)=> t.StatusId == item.StatusId)==i)
          // this.subStatus = this.StatusMasters.filter((item: any, i: any, stat: any)=>stat.findIndex((t: any)=> t.SubStatusId == item.SubStatusId)==i)
          console.log(`status Master data found: ${JSON.stringify(this.StatusMasters)} ${JSON.stringify(this.status)}`)
        }
      })
    }
  }

  ngOnChanges(changes: SimpleChanges){
    if(changes['ClaimDetail']){
      this.sharedService.statusMasterSubject$.subscribe((data: any)=> {
        if(data.length>0){
          this.StatusMasters = data
          this.status = this.StatusMasters.filter((item: any, i: any, stat: any)=>stat.findIndex((t: any)=> t.StatusId == item.StatusId)==i)
          // this.subStatus = this.StatusMasters.filter((item: any, i: any, stat: any)=>stat.findIndex((t: any)=> t.SubStatusId == item.SubStatusId)==i)
          console.log(`status Master data found: ${JSON.stringify(this.StatusMasters)} ${JSON.stringify(this.status)}`)
        }
      })
    }
  }

  trackByFn(index: any, item: any){
    //console.log(`index: ${JSON.stringify(index)} item: ${JSON.stringify(item)}`)
  }

  statusChange(eventChange: any){
    this.subStatus = this.StatusMasters.filter((item) => item.StatusId == eventChange.target.value);
    //console.log(`JSON.stringify(eventChange): ${JSON.stringify(eventChange)} ${this.statusLogForm.controls['status'].value}`)
    this.statusLogForm.get('subStatus')?.enable();
  }

  UpdateStatus(){
    let UpStatus = {
      ClaimId: this.ClaimDetail.ClaimId,
      StatusId: 0,
      SubStatusId: 0,
      UserId: this.LoginDetails.UserId,
      ProductId: this.ClaimDetail.ProductId,
      RegistrationNo: '',
      CustomerMobileNo: '',
      SurveyorMobileNo: '',
      OfficerMobileNo: '',
      ActionBy: "Insurer",
      StatusMode: '',
      RequestType: 0
    }

    console.log(`this.status: ${JSON.stringify(this.status)}`)
    if (this.statusLogForm.controls['status'].value != undefined && this.statusLogForm.controls['status'].value != 0) {
      UpStatus.StatusId = this.statusLogForm.controls['status'].value;
      if (this.statusLogForm.controls['subStatus'].value != undefined && this.statusLogForm.controls['subStatus'].value != 0) {
          UpStatus.SubStatusId = this.statusLogForm.controls['subStatus'].value;
          UpStatus.StatusMode = this.status.filter((t: any)=> t.StatusId == this.statusLogForm.controls['status'].value)[0].StatusMode;
          UpStatus.RequestType = this.ClaimDetail.RequestTypeID;
      } else {
          alert("Please Select Sub-Status!!");
          return;
      };

      this.surveyorService.UpdateStatus(UpStatus).subscribe((res: any)=>{
        if (res.Data) {
          if (res.Data.ResultStatus) {
            this.GetClaimDetails.emit();
            this.statusLogForm.reset();
            this.statusLogForm.get('subStatus')?.enable();
              //this.GetClaimDetails(false)
              alert("Status Successfully Updated !!");
          }
          else {
              this.ErrorMsg = "Error : Something went wrong!";
          }
      } else {
          this.ErrorMsg = "Error : Something went wrong!";
      }
      })

    }
    else {
      alert("Please Select Status!!");
    }
  }

}
