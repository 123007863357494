import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiHttpService } from './../ApiHttpService/apiHttp.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FeedbackService {

  constructor(private api: ApiHttpService) { }
  path_API = environment.PathAPI
  getCustomerFeedbackReport(encClaimId: any): Observable<any>{
    const url: string = `${this.path_API}Claim/GetCustomerFeedbackReport/${encClaimId}`
    return this.api.get(url)
  }

  sendCustomerFeedback(data: any): Observable<any>{
    const url: string = `${this.path_API}Claim/SetCustomerFeedback`
    return this.api.post(url, JSON.stringify(data))
  }
}
