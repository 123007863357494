import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { SurveyorService } from './../../../services/SurveyorService/surveyor.service';
import { Utils } from './../../../utils/utils';
import { CommonService } from './../../../services/commonService/common.service';
import { DomSanitizer,SafeResourceUrl } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-customer-documents',
  templateUrl: './customer-documents.component.html',
  styleUrls: ['./customer-documents.component.css']
})
export class CustomerDocumentsComponent implements OnInit {

  constructor(
    private commonService: CommonService,
    private surveyorService: SurveyorService,
    private utils: Utils,
    private sanitizer: DomSanitizer,
    private router: Router
    ) { }
  @Input() LoginDetails: any;
  @Input() ClaimDetail: any;
  @Input() isPreviousClaims: boolean = false;
  CustomerDocsLists: any = [];
  previousClaimsDetails: any = [];
  ErrorMsg : string = '';
  ttLlDocUrl: any;
  ngOnInit(): void {
    // if(this.ClaimDetail.ClaimId){
    //   this.GetCustomerDocs()
    // }

  }

  ngOnChanges(changes: SimpleChanges){
    if(changes['ClaimDetail'] && this.ClaimDetail.ClaimId != 0 && !this.CustomerDocsLists.length){
      if(this.isPreviousClaims) {
        //this.getPreviousClaimDetails()
      }
      else {
        this.GetCustomerDocs()
      }
    }
  }

  GetCustomerDocs() {
    let reqData = this.ClaimDetail.ClaimId + "/0";
    this.surveyorService.GetCustomerDocs(reqData).subscribe((res: any)=>{
      if (res.Data) {
        //$scope.CustomerDocsLists = response.data.Data;
        res.Data.forEach((element: any) => {
          if (element.DocStatusID == 1 && this.CustomerDocsLists.findIndex((item: any) => item.EncDocuUniqueId == element.EncDocuUniqueId) == -1) {
            this.CustomerDocsLists.push(element);
        }
        });
    } else {
        this.ErrorMsg = "Error : Something went wrong!";
    }
    })
  }

  DownloadZIP() {

    let reqData = `${this.ClaimDetail.ClaimId}/`
    this.surveyorService.DownloadZIP(reqData).subscribe((res: any)=>{
      if (res.Data) {
        var ZipFileBase64 = res.Data;
        var ZipFilename = "Claimdocs_" + this.ClaimDetail.ClaimId + ".zip";
        var ZipBytes = base64ToArrayBuffer(ZipFileBase64);
        saveByteArray(ZipFilename, ZipBytes);

        function base64ToArrayBuffer(base64: any) {
            var binaryString = window.atob(base64);
            var binaryLen = binaryString.length;
            var bytes = new Uint8Array(binaryLen);
            for (var i = 0; i < binaryLen; i++) {
                var ascii = binaryString.charCodeAt(i);
                bytes[i] = ascii;
            }
            return bytes;
        }

        function saveByteArray(reportName: any, byte: any) {
            var blob = new Blob([byte], { type: "application/zip" });
            var link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = reportName;
            link.click();
        }

      } else {
          alert('Something went wrong!');
          this.ErrorMsg = "Error : Something went wrong!";
      }
    })
  }

  // GetFileUrlByUniqueDocId(DocuUniqueId: any, DocumentID: any, MasterDocumentId: any){
  //   this.utils.GetFileUrlByUniqueDocId(DocuUniqueId, DocumentID, MasterDocumentId, this.ClaimDetail)
  // }

  GetFileUrlByUniqueDocId(DocuUniqueId: any, DocumentID: any, MasterDocumentId: any, EncDocuUniqueId: any, Url:any = null) {

    let GetFileUrlByUniqueDocIdRequest = {
      docId: DocuUniqueId,
      custId: this.ClaimDetail.CustomerId,
      documentId: DocumentID,
      masterDocumentId: MasterDocumentId,
      EncDocuUniqueId: EncDocuUniqueId

    }
    var reqData = GetFileUrlByUniqueDocIdRequest;
    this.commonService.GetFileUrlByUniqueDocId(reqData).subscribe(response => {
      if (response && response.Data) {
        this.ttLlDocUrl = this.sanitizer.bypassSecurityTrustResourceUrl(response.Data);
        window.open(response.Data, '_blank');
      }
      else if (this.ClaimDetail.DocumentUrl && DocumentID == 58)
        window.open(this.ClaimDetail.DocumentUrl, '_blank');
      else if (Url)
        window.open(Url, '_blank');
      else {
        alert("Error : Something went wrong!");
      }

    })
  }


  GetFileUrlByUniqueDocIdForDoc(DocuUniqueId: any, DocumentID: any, MasterDocumentId: any, EncDocuUniqueId:any, Url?:any) {
    if (!DocuUniqueId)
      window.open(Url, '_blank');
    else
      this.GetFileUrlByUniqueDocId(DocuUniqueId, DocumentID, MasterDocumentId, EncDocuUniqueId,Url);
  }

  getPreviousClaimDetails() {
    let payload = {
      "ClaimId": this.ClaimDetail?.ClaimId,
      "PolicyNumber": this.ClaimDetail?.PolicyNumber
    }
    this.surveyorService.getPreviousClaims(payload).subscribe((data: any) => {
      if(data.Data.length) {
        this.previousClaimsDetails = data.Data;
      }
    }) 
  }

  openClaim(ClaimId: any, EncClaimId: any){
    window.open(environment.PathURL + 'case-details/' + btoa(`${ClaimId}$${EncClaimId}`))
  }
}
