<div class="box">
  <div class="box-body video_block upload-report">
    <form [formGroup]="UploadReportForm" *ngIf="LoginDetails.UserType==3">
        <div class="box-header table_header">
            <h3 class="box-title">Upload Report</h3>
        </div>
        <div class="box-body up-form">
            <div class="form-inline form-details">
                <div class="row">
                    <div class="col-md-4">
                        <label for="txt">Select Garage</label>
                    </div>
                    <div class="col-md-4">
                        <select class="form-control garage" name="garage" formControlName="SelectedGarage" required>
                            <option value="">Select</option>
                            <option *ngFor="let ed of EstimateDetails" [value]="ed.GarageID">{{ed.Name}}</option>
                        </select>
                    </div>
                    <div class="col-md-4">
                        <a class="btn-block report_download" href="{{PreReport}}" download="PreReport.xlsx" target="_blank">Sample Preliminary Report &nbsp;<i class="fa fa-file-excel-o"></i></a>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-4">
                        <label for="txt">Report Type</label>
                    </div>
                    <div class="col-md-8">
                        <input name="" type="radio" formControlName="ReportType" value="1" />&nbsp; Pre-Repair Report
                        <input name="" type="radio" formControlName="ReportType" value="2" />&nbsp;Post-Repair Report
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-4">
                        <label for="txt">File Name</label>
                    </div>
                    <div class="col-md-4">
                        <input type="text" class="form-control" id="txt" style="width:100%;" formControlName="DocName" required />
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-4">
                        <label for="txt">Attach File</label>
                    </div>
                    <div class="col-md-4">
                        <input name="" type="file" style="padding:0" onclick="this.value=null;" (change)="fileUpload($event)" required />
                    </div>
                    <div class="col-md-2  col-md-offset-2">
                        <button type="submit" class="btn btn-primary fix_btn" (click)="SetSurveyorDocs();" [disabled]="UploadReportForm.invalid">Upload</button>
                    </div>
                </div>
            </div>
        </div>
    </form>
    <form role="form" [formGroup]="SurveyReportForm">
      <div class="box-header table_header">
          <h3 class="box-title">Survey Upload Report</h3>
      </div>
      <div class="box-body">
          <table class="table table-bordered">
              <thead>
                <tr>
                  <th>File Name</th>
                  <th>Uploaded On</th>
                  <!--<th>Updated Date</th>-->
                  <th>Uploaded By</th>
                  <th>Status</th>
                  <th>File</th>
                  <th>Action</th>
                  <th>Uploaded At GC Portal</th>
                  <th>Date of upload</th>
                </tr>
              </thead>
              <tbody>

                <tr *ngFor="let Doc of SurveyorDocsList">
                  <td>{{Doc.DocName}}</td>
                  <td>{{Doc.CreatedOn | date:'medium'}}</td>
                  <td>{{Doc.CreatedBy!=0?Doc.CreatedBy:Doc.UpdatedBy}}</td>
                  <td>{{Doc.DocStatus}}</td>
                  <td>
                    <!-- <a *ngIf="Doc.DocuUniqueId!=''" (click)="GetFileUrlByUniqueDocId(Doc.DocuUniqueId,0,0)">
        <i class="fa fa-file-pdf-o"></i>
    </a> -->
                    <a *ngIf="Doc.DocuUniqueId!=''" (click)="GetFileUrlByUniqueDocIdForDoc(Doc.DocuUniqueId,0,0,Doc.EncDocuUniqueId)">
                      <i class="fa fa-file-pdf-o"></i>
                    </a>
                  </td>
                  <td *ngIf="LoginDetails.UserType==3"><a href="javascript:void();" (click)="ActionOnUploadedDoc(Doc.DocID,3);" *ngIf="Doc.DocStatusID<=0">Delete</a></td> <!--Surveyor-->
                  <td *ngIf="LoginDetails.UserType!=3">
                    <input type="radio" [formControlName]="Doc.formControlActionId" value="1" [disabled]="Doc.DocStatusID>0" />Approve &nbsp;
                    <input type="radio" [formControlName]="Doc.formControlActionId" value="2" [disabled]="Doc.DocStatusID>0" />Reject &nbsp;
                    <input type="radio" [formControlName]="Doc.formControlActionId" value="3" [disabled]="Doc.DocStatusID>0" />Re-Submit &nbsp;
                  </td>
                  <td>
                    <input name="" type="checkbox" [formControlName]="Doc.formControlIsUploaded" [checked]="Doc.IsUploadedAtGCPortal">
                  </td>
                  <td>{{Doc.IsUploadedAtGCPortal==true?Doc.UploadedOnGCPortal>0? (Doc.UploadedOnGCPortal| date:'medium') : "":""}}</td>
                </tr>

              </tbody>
          </table>
          <div class="row_custom" *ngIf="SurveyorDocsList?.length" style="margin:10px 0px;">
              <button type="submit" class="btn btn-primary fix_btn" (click)="ActionOnUploadedDoc(0,2);" [disabled]="!SurveyReportForm.valid">
                  Submit
              </button>
              <button type="submit" class="btn btn-primary fix_btn" (click)="SendMailDO();" *ngIf="(LoginDetails.UserType==2 && IsMapping && ClaimDetail.InsurerId==11)" [disabled]="IsSendMailDO" style="margin-right:10px;">
                  {{SendDO}}
              </button>
          </div>
      </div>
    </form>
  </div>
</div>
