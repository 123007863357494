import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BehaviorSubject } from 'rxjs';
import { Subject } from 'rxjs';

@Injectable()
export class LoaderService {
  private loaderSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  loader$!: Observable<boolean>;
  constructor() {
    this.loader$ = this.loaderSubject.asObservable();
  }
  triggerLoader(value:any) {
    this.loaderSubject.next(value);
  }
  hideLoader() {
    this.loaderSubject.next(false);
  }
}
