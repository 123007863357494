import { Component, OnInit, Input, SimpleChanges } from '@angular/core';
import { SurveyorService } from './../../../services/SurveyorService/surveyor.service';
import { SharedService } from './../../../services/SharedService/sharedService';
import { CommonService } from './../../../services/commonService/common.service';
import { Utils } from './../../../utils/utils';

@Component({
  selector: 'app-estimate-list',
  templateUrl: './estimate-list.component.html',
  styleUrls: ['./estimate-list.component.css']
})
export class EstimateListComponent implements OnInit {

  constructor(
    private surveyorService: SurveyorService,
    private commonService: CommonService,
    private sharedService: SharedService,
    private utils: Utils) { }
  @Input() LoginDetails:  any;
  @Input() ClaimDetail: any;
  EstimateDetails: any;
  EstimateUrl: string = '';
  ErrorMsg: string = '';
  countEstimate = 0;
  EstCount = 0;
  InvoiceCount = 0;


  ngOnInit(): void {

  }

  ngOnChanges(changes: SimpleChanges){
    if(changes['ClaimDetail'] && this.ClaimDetail.ClaimId != 0 && !this.EstimateDetails) {
      this.GetEstimateDetails();
    }
  }

  GetEstimateDetails() {
    this.surveyorService.GetEstimateDetails(`${this.ClaimDetail.ClaimId}/1`).subscribe((res: any) => {
      if (res.Data != null) {
        this.EstimateDetails = res.Data;
        this.sharedService.setEstimateDetails(res.Data);
        this.EstimateUrl = this.EstimateDetails[0].EstimateURL;
        //this.DocUniqueId = this.EstimateDetails[0].DocUniqueId;

      }
      else {
        this.ErrorMsg = "Error : Something went wrong!";
        //alert(`Error : Estimate is not available for ${this.ClaimDetailForVideo.ClaimId}!`);
      }
    })
  }

  // GetFileUrlByUniqueDocId(DocuUniqueId: any, DocumentID: any, MasterDocumentId: any){
  //   this.utils.GetFileUrlByUniqueDocId(DocuUniqueId, DocumentID, MasterDocumentId, this.ClaimDetail)
  // }
  GetFileUrlByUniqueDocId(DocuUniqueId: any, DocumentID: any, MasterDocumentId: any, EncDocuUniqueId: any, Url:any = null) {

    let GetFileUrlByUniqueDocIdRequest = {
      docId: DocuUniqueId,
      custId: this.ClaimDetail.CustomerId,
      documentId: DocumentID,
      masterDocumentId: MasterDocumentId,
      EncDocuUniqueId: EncDocuUniqueId

    }
    var reqData = GetFileUrlByUniqueDocIdRequest;
    this.commonService.GetFileUrlByUniqueDocId(reqData).subscribe(response => {
      if (response && response.Data) {
        window.open(response.Data, '_blank');
      }
      else if (this.ClaimDetail.DocumentUrl && DocumentID == 58)
        window.open(this.ClaimDetail.DocumentUrl, '_blank');
      else if (Url)
        window.open(Url, '_blank');
      else {
        alert("Error : Something went wrong!");
      }

    })
  }


  GetFileUrlByUniqueDocIdForDoc(DocuUniqueId: any, DocumentID: any, MasterDocumentId: any, EncDocuUniqueId:any, Url?:any) {
    if (!DocuUniqueId)
      window.open(Url, '_blank');
    else
      this.GetFileUrlByUniqueDocId(DocuUniqueId, DocumentID, MasterDocumentId, EncDocuUniqueId,Url);
  }

  EstimateApprove() {
    let ClaimEstimateDetails: any = {
      ClaimId: this.ClaimDetail.ClaimId.toString(),
      IsAgent: true,
      EstimateDetail: []
    };
    this.EstimateDetails.forEach((value:any)=> {
      let EDetails = {
        EstimateID: value.EstimateID,
        IsApproved: value.IsApproved,
        UpdatedBy: this.LoginDetails.UserId
      };
      ClaimEstimateDetails.EstimateDetail.push(EDetails);
      if (value.IsInsurerApproved) {
        this.countEstimate = this.countEstimate + 1;
    }
    if (value.EstimateType == 'Repaired') {
        this.InvoiceCount = this.InvoiceCount + 1;
    }
    });

    // if (rootScopeService.getPaymentAllowedAmount() == 0 && $scope.InvoiceCount > 0) {
    //   alert("Please Input Allowed Amount in Payment Details Section !!");
    //   return false;
    // }
    if(this.countEstimate > 0){
      let reqData = ClaimEstimateDetails;
      this.surveyorService.UpdateEstimateDetails(reqData).subscribe(data => {
        if (data && data.Data) {
          this.countEstimate = 0;
          this.EstCount = 0;
          this.InvoiceCount = 0;
          alert("Successfully Approved.");
          this.GetEstimateDetails();
        } else {
          this.ErrorMsg = "Error : Something went wrong!";
        }
      })
    }


  }

}
