<div class="box">
  <div class="box-header table_header">
      <h3 class="box-title">Estimate List</h3>
  </div>
  <div class="box-body video_block">
      <div class="table-responsive-video">
          <table class="table table-bordered" style="margin:0;">
              <thead>
                  <tr>
                      <th>Select</th>
                      <th>GarageId</th>
                      <th>Garage</th>
                      <th>Amount</th>
                  </tr>
              </thead>
              <tbody>
                  <tr *ngFor="let Estimate of EstimateDetails">
                      <td><input name="" type="checkbox" [(ngModel)]="Estimate.IsInsurerApproved"></td>
                      <td>{{Estimate.GarageID}} </td>
                      <td>{{Estimate.Name}} </td>
                      <!--<td>{{Estimate.EstimateAmount}}<a  ng-if="Estimate.EstimateURL" ng-click="OpenDoc(Estimate.EstimateURL);"> ({{(Estimate.EstimateType=='Damaged')?'Estimate':'Invoice'}})</a></td>-->
                      <!-- <td>{{Estimate.EstimateAmount}}<a  *ngIf="Estimate.EstimateURL" (click)="GetFileUrlByUniqueDocId(Estimate.DocUniqueId,0,0);"> ({{(Estimate.EstimateType=='Damaged')?'Estimate':'Invoice'}})</a></td> -->
                      <td>{{Estimate.EstimateAmount}}<a  *ngIf="Estimate.EstimateURL" (click)="GetFileUrlByUniqueDocIdForDoc(Estimate.DocUniqueId,0,0,true,Estimate.EncDocuUniqueId)"> ({{(Estimate.EstimateType=='Damaged')?'Estimate':'Invoice'}})</a></td>
                  </tr>
              </tbody>
          </table>
          <div class="form-group">
              <p class="changes_block">
                  <a class="btn btn-primary submit_nw_btn fix_btn" role="button" (click)="EstimateApprove();">Approve</a>
                  <!--<a  class="btn btn-default submit_nw_btn" role="button">Cancel</a>-->
              </p>
          </div>
      </div>

      <div class="video_container">
          <!--PDF Body-->
          <object  *ngIf="EstimateUrl" data="{{EstimateUrl}}" type="application/pdf" width="100%" height="600">
              <!--pdf file will generate here-->
              <!--<embed ng-src="{{testUrl}}" width="100%" height="600" alt="pdf-viewer">-->
          </object>
          <!--PDF body End-->
      </div>
  </div>
</div>
