import { NgModule, ErrorHandler } from '@angular/core';
import {  LocationStrategy, HashLocationStrategy } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { CookieService } from 'ngx-cookie-service';
import { DatePipe } from '@angular/common';
import { AppComponent } from './app.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { ApiHttpService } from './services/ApiHttpService/apiHttp.service';
import { LoaderComponent } from './components/shared/loader/loader.component';
import { LoaderService } from './services/LoaderService/loader.service';
import { LoaderInterceptor } from './intercepters/LoaderInterceptor/loader.interceptor';
import { GlobalErrorHandler } from './library-modules/error-handlers/global-error-handler';
import { MaterialModule } from './library-modules/material/material/material.module';
import { PrimeNgModule } from './library-modules/prime-ng/prime-ng.module';
import { LoginComponent } from './components/login/login.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { ClaimAssignmentComponent } from './components/claim-assignment/claim-assignment.component';
import { CoreModule } from './components/core/core.module';
import { ClaimDetailsModule } from './components/claim-details/claim-details.module';
import { PendingCasesComponent } from './components/pending-cases/pending-cases.component';
import { RenderCountBlocksComponent } from './components/shared/common/render-count-blocks/render-count-blocks.component';
import { FeedbackComponent } from './components/feedback/feedback.component';
import { PbTableComponent } from './components/pb-table/pb-table.component';
import { BadFeedbackComponent } from './components/bad-feedback/bad-feedback.component';
import { DynamicDigitalFormsComponent } from '../app/components/dynamic-digital-forms/dynamic-digital-forms.component';
import { AngularCropperjsModule } from 'angular-cropperjs';


@NgModule({
  declarations: [
    AppComponent,
    PageNotFoundComponent,
    LoaderComponent,
    LoginComponent,
    DashboardComponent,
    // HeaderComponent,
    // SidebarComponent,
    ClaimAssignmentComponent,
    PendingCasesComponent,
    RenderCountBlocksComponent,
    FeedbackComponent,
    PbTableComponent,
    BadFeedbackComponent,
    DynamicDigitalFormsComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    AppRoutingModule,
    MaterialModule,
    PrimeNgModule,
    CoreModule,
    ClaimDetailsModule,
    AngularCropperjsModule
  ],
  providers: [
              LoaderService,

              { provide: LocationStrategy, useClass: HashLocationStrategy },
              { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
              { provide: ErrorHandler, useClass: GlobalErrorHandler },
              CookieService,
              ApiHttpService,
              DatePipe
            ],
  bootstrap: [AppComponent]
})
export class AppModule { }
