<div class="box" *ngIf="IsGarage">
  <div class="box-header table_header">
      <h3 class="box-title">Garage Documents</h3>
  </div>
  <div class="box-body">
      <table class="table table-bordered">
          <thead>
              <tr>
                  <th>Document</th>
                  <th>FileName</th>
                  <th>File</th>
              </tr>
          </thead>
          <tbody>
              <tr *ngFor="let Docs of GarageDocsLists">
                  <td>{{Docs.Document}}</td>
                  <td>{{Docs.DocName}}</td>
                  <td><a ng-show="Docs.DocFileURL!=''" href="{{Docs.DocFileURL}}" target="_blank"><i class="fa fa-file-pdf-o"></i></a></td>
              </tr>
          </tbody>
      </table>
  </div>
</div>
