<div class="box box-primary">
  <div class="box-header with-border">
      <h3 class="box-title">Previous Year Policy</h3>
  </div>
  <div class="box-body" id="PYP">
      <div class="form-group">
          <table id="ClaimRequestList" class="table table-bordered">
              <thead>
                  <tr>
                      <!--<th>LeadID</th>-->
                      <th>Uploaded On</th>
                      <th>PYP Doc</th>
                  </tr>
              </thead>
              <tbody>
                  <tr *ngFor="let PYP of ClaimDetail.PreviousPolicy">
                      <td>{{PYP.PolicyUploadedOn | date:'medium'}}</td>
                      <td>
                          <a href="{{PYP.PreviousYearPolicyUrl}}" target="_blank" class="pull-right-data" style="margin:0 0 0 5px;color:#ff0000;">
                              <i class="fa fa-file-pdf-o"></i>
                          </a>

                      </td>
                  </tr>
              </tbody>
          </table>
      </div>
  </div>
</div>
