<app-header [LoginDetails]="LoginDetails"></app-header>
<div class="content-wrapper">
  <section class="content dashboard_table card-eight">
      <div class="row">
          <div class="assign_box-header with-border">
              <h3 class="assign_box-title">Manual Assignment</h3>
          </div>
          <div class="col-md-12 col-xs-12">
              <div class="box box-default">

                  <div class="box-body body-assign">
                    <div class="row">
                        <div class="col-md-12 col-sm-12 col-xs-12 form_block" >
                            <div class="col-md-6 claimass">
                              <form [formGroup]="assignmentTypeForm">
                                <div class="col-md-5 col-sm-3 col-xs-12">
                                    <div class="form-group">
                                        <label>Select Assignment Type</label>
                                        <span class="data_mode">
                                            <select class="form-control email_pointer" name="AssignmentType" formControlName="assignType" required>
                                                <option value="">Select</option>
                                                <option *ngFor="let AssignType of AssignmentType">{{AssignType.TypeText}}</option>
                                            </select>
                                        </span>
                                    </div>
                                </div>
                                <div class="col-md-2 col-sm-2 col-xs-12 assign_right">
                                    <button type="submit" class="btn btn-primary assign_button default-assign" id="Search" (click)="Search();">Search</button>
                                </div>
                              </form>
                            </div>
                            <div class="col-md-6 claimass">
                              <form [formGroup]="assignCasesForm">
                                <div class="col-md-5 col-sm-3 col-xs-12">
                                    <div class="form-group">
                                        <label>Select Surveyor</label>
                                        <span class="data_mode">
                                            <!-- <select class="form-control email_pointer" name="Surveyor" formControlName="selectedSurveyor"
                                                    ng-options="InsurerList.UserName for InsurerList in AllInsurerList | unique:'ID' | filter:{InsurerId: SelectedInsurer.InsurerId,UserType:3}:true track by InsurerList.ID ">
                                                <option value="">Select</option>
                                            </select> -->
                                            <select class="form-control email_pointer" name="Surveyor" formControlName="selectedSurveyor">
                                                <option value="">Select</option>
                                                <option *ngFor="let sL of SurveyorList" [value]="sL.Id">{{sL.UserName}}</option>
                                            </select>
                                        </span>
                                    </div>
                                </div>
                                <div class="col-md-5 col-sm-3 col-xs-12 padding-ctr" ng-show="UserType==1">
                                    <div class="form-group">
                                        <label>Select Officer</label>
                                        <span class="data_mode">
                                            <select class="form-control email_pointer" name="Officer" ng-model="SelectedOfficer"
                                                    ng-options="InsurerList.UserName for InsurerList in AllInsurerList | unique:'ID' | filter:{InsurerId: SelectedInsurer.InsurerId,UserType:2}:true track by InsurerList.ID ">
                                                <option value="">Select</option>
                                            </select>
                                        </span>
                                    </div>
                                </div>
                                <div class="col-md-2 col-sm-2 col-xs-12 assign_right">
                                    <button type="submit" class="btn btn-primary assign_button default-assign" id="Assign" ng-click="AssignCases();">Assign</button>
                                </div>
                              </form>
                            </div>
                        </div>
                        <div class="box-body col-md-12 col-sm-12 col-xs-12 form_block table_agent claiminsurer">
                            <div class="box-header table_header table_hd claim_stat">
                                <h3 class="box-title">
                                    Claim List
                                </h3>
                            </div>
                            <p-table [value]="ClaimLists" responsiveLayout="scroll" styleClass="p-datatable-striped p-datatable-sm p-datatable-gridlines"
                                    [paginator]="true" [rows]="4" [showCurrentPageReport]="true" [rowsPerPageOptions]="[4,8,15]"
                                    paginatorDropdownAppendTo="body">
                              <ng-template pTemplate="header">
                                <tr>
                                  <th>PBReferenceID</th>
                                          <th>PolicyHolder</th>
                                          <th>PolicyNo</th>
                                          <th>Registration No</th>
                                          <th>City</th>
                                          <th>State</th>
                                          <th>Assigned Garage</th>
                                          <th>Case Age (Days)</th>
                                          <th >Surveyor</th>
                                          <th >Officer</th>
                                          <th >Request Type</th>
                                          <th>Select</th>
                                </tr>
                              </ng-template>
                              <ng-template pTemplate="body" let-ClaimList>
                                <tr>
                                  <td>PB{{ClaimList.ClaimId}}</td>
                                  <td class="surveyor">PB{{ClaimList.CustomerName}}</td>
                                  <td class="surveyor"><a  (click)="Case_Details(ClaimList.ClaimId, ClaimList.EncClaimId)">{{ClaimList.PolicyNumber}}</a></td>
                                  <td>{{ClaimList.RegistrationNo}}</td>
                                  <td>{{ClaimList.City}}</td>
                                  <td>{{ClaimList.State}}</td>
                                  <td>{{ClaimList.GarageName ? ClaimList.GarageName:'NA'}}</td>
                                  <td>{{ClaimList.CaseAge}}</td>
                                  <td  class="surveyor">{{ClaimList.Surveyor}}</td>
                                  <td  class="surveyor">{{ClaimList.Officer}}</td>
                                  <td>{{ClaimList.RequestType}} ({{ClaimList.SubRequestType}})</td>
                                  <td>
                                    <label>
                                        <input ng-disabled="IsAllChecked" type="checkbox" class="checkbox_new" ng-model="ClaimList.Checked" ng-change="CheckUncheckHeader()">
                                    </label>
                                </td>
                                </tr>
                              </ng-template>
                            </p-table>
                        </div>
                    </div>
                  </div>
              </div>
          </div>
      </div>
  </section>
</div>
<div class="control-sidebar-bg"></div>
