import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { SharedService } from './../../services/SharedService/sharedService';
import { Router, ActivatedRoute } from '@angular/router';
import { SurveyorService } from './../../services/SurveyorService/surveyor.service';
import { CommonService } from './../../services/commonService/common.service';
import { Utils } from './../../utils/utils';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-claim-details',
  templateUrl: './claim-details.component.html',
  styleUrls: ['./claim-details.component.css']
})
export class ClaimDetailsComponent implements OnInit {

  constructor(
    private sharedService: SharedService, private router: Router,
    private activatedRoute : ActivatedRoute, private surveyorService: SurveyorService,
    private commonService: CommonService,
    private fb: FormBuilder,
    private utils: Utils,
    private matDialog:MatDialog,
    private sanitizer: DomSanitizer) { }
  LoginDetails: any;
  addClaimIdForm!: FormGroup;
  RefIdText: string = 'PB';
  ClaimId: any;
  ClaimDetail: any = {
		"ClaimId": 0,
		"EncryptedClaimId": "",
		"LeadId": 0,
		"ProductId": 0,
		"CustomerId": 0,
		"EncCustomerId": "",
		"Name": "",
		"Mobile": "",
		"Email": "",
		"AlternateNumber": "",
		"CityId": 0,
		"City": "",
		"StateID": 0,
		"State": "",
		"Address": "",
		"PinCode": 0,
		"InsurerId": 0,
		"InsurerName": "",
		"InsurerClaimId": "",
		"PlanId": 0,
		"PlanName": "",
		"IntimationDate": 0,
		"ClaimType": "",
		"PolicyNumber": "",
		"IsPBCustomer": false,
		"RequestType": "",
		"SubRequestType": "",
		"ExpiryDate": 0,
		"SPOCName": "",
		"InsurerNumber": 0,
		"URL": "",
		"StatusId": 0,
		"StatusName": "",
		"SubStatusId": 0,
		"SubStatusName": "",
		"StatusMode": "",
		"FollowupDate": 0,
		"FollowupWith": "",
		"DocumentUrl": "",
		"TPAId": 0,
		"TPAName": null,
		"TPAUrl": null,
		"PushToInsurer": true,
		"IsOnline": true,
		"Priority": 0,
		"IsOnlineOffline": true,
		"IsTransition": true,
		"ActionableBy": 0,
		"ZeroDep": true,
		"RegistrationNo": "",
		"PushToInsurerDate": 0,
		"BookingDate": 0,
		"IsBreakIn": false,
		"PolicyStartDate": 0,
		"IsAssisted": "",
		"OfficerMobileNo": 0,
		"SurveyorMobileNo": 0,
		"PendencyBy": 0,
		"ClosingAmount": 0,
		"IsPushToInsurer": true,
		"Endorsement": [

		],
		"PreviousPolicy": null,
		"ClaimRequestDate": 0,
		"TotalVideoCount": 0,
		"AssignedToUserID": 0,
		"AssignedToUser": "",
		"MaskMobile": "",
		"MaskAlternateNumber": "0",
		"QFCLeadID": 0,
		"ProcessType": false,
		"IsProcessTransition": false,
		"AudatexTaskId": "",
		"MultiDestinationTraveled": "",
		"GeographicalZone": "",
		"IsDOReleased": false,
		"Pendency": "",
		"StatusOrdering": 0,
		"SubRequestTypeID": 0,
		"RequestTypeID": 0,
		"RMFollowupDate": 0,
		"RMFollowupWith": "",
		"AssignedToRMUserID": 0,
		"AssignedToRMUser": "",
		"MappingID": 0,
		"OnlineProcessType": "",
		"BenefitType": "",
		"CountryCode": "",
		"CountryCodeAlt": "",
		"SecondaryProductId": 0,
		"ProductName": "",
		"HospitalName": "",
		"MakeId": 0,
		"NCBPercentage": 0,
		"IsPSU": true,
		"CoverType": "",
		"CaseIntimation": "",
		"SurveyorName": "",
		"OfficerName": "",
		"AdmissionDate": 0,
		"DischargeDate": 0,
		"Diagnosis": "",
		"CompanyName": "",
		"InsurerStatus": "",
		"InsurerUpdateDate": 0,
		"HospitalizationExpenses": "",
		"CaseAge": 0,
		"IsVideoEnabled": false,
		"EncryptedEmailID": "",
		"LossDate": 0,
		"OEMPilotGarage": false,
		"SRNumber": "",
		"InsurerMemberID": 0,
		"InsurerMemberName": "",
		"ClaimSource": "",
		"TicketID": 0,
		"DocUniqueId": "",
		"IsCallRequired": true,
		"IsMis_sell": false,
		"Mis_sellComment": "",
		"Mis_sellCategoryId": 0,
		"IsInsurerDataFilled": true,
		"IsInsurerIntimation": false,
		"EstimateAmount": 0,
		"IsApproved": true,
		"DocumentLastShareDate": 0,
		"IsAnswered": false,
		"RejectionId": 0,
		"MakeName": "",
		"IsVIPEmail": false,
		"IsIntimationFilled": true
	};
  SendDO: string = '';
  IsSendMailDO = false;
  IsMapping: boolean = false;
  ErrorMsg: string = '';
  StatusMasters: any = [];
  ttLlDocUrl: any;
  @ViewChild('addClaimId') addClaimId!: TemplateRef<any>;

  ngOnInit(): void {

    this.addClaimIdForm = this.fb.group({
      InsurerClaimId: ['', [Validators.required]],
      IntimationDate: ['']
    })
    let params: any = this.activatedRoute.snapshot.paramMap.get("claimId");
    params = atob(params.toString())
    this.ClaimId = params.split('$')[0];
    // this.ClaimDetail.ClaimId = this.ClaimId;
    let EncClaimID = params.split('$')[1]
    this.sharedService.setEncClaimId(EncClaimID)
    this.sharedService.userDetailsSubject$.subscribe(data => {
      if(typeof data == 'string'){
        this.LoginDetails = JSON.parse(data);
      }
      else{
        this.LoginDetails = data;
      }
    });

    if(this.ClaimId){
      this.GetClaimDetails();
    }
  }

  ngOnChanges(){
    // if(this.ClaimId){
    //   this.GetClaimDetails();
    // }
  }

  GetClaimDetails() {
    let reqData = this.ClaimId + "/0/FALSE";

    this.surveyorService.GetClaimDetails(reqData).subscribe((res: any)=> {
      if (res){
        if (res && res.Data) {
          this.ClaimDetail = res.Data;
          if (this.ClaimDetail.IsDOReleased) {
              this.SendDO = "DO Released";
              this.IsSendMailDO = true;
              this.IsMapping = true;
          } else {
              if ((this.ClaimDetail.StatusOrdering >= 460 && this.ClaimDetail.SubRequestTypeID == 24) || (this.ClaimDetail.StatusOrdering >= 495 && this.ClaimDetail.SubRequestTypeID == 25)) {
                  this.IsMapping = true;
              }
          }
          this.addClaimIdForm.patchValue({
            InsurerClaimId: this.ClaimDetail.InsurerClaimId,
            IntimationDate: new Date(this.ClaimDetail.IntimationDate)
          })
          this.GetStatusMaster();
      } else {
          this.ErrorMsg = "Error : Something went wrong!";
      }
      }
    })
  }

  GetStatusMaster(){
    this.StatusMasters = [];
    let reqData = this.ClaimId + "/" + this.LoginDetails.UserType;
    this.surveyorService.GetClaimStamps(reqData).subscribe((res: any)=> {
      if (res && res.Data) {
        if (res.Data != null) {
            res.Data.forEach((value: any) =>{
                if (value.ActionableBy == 2 || value.ActionableBy == 4 || value.ActionableBy == 6) {
                    this.StatusMasters.push(value);
                }
            });
            this.sharedService.setStatusMaster(this.StatusMasters)
        }
    } else {
        this.ErrorMsg = "Error : Something went wrong!";
    }
    })
  }

  // GetFileUrlByUniqueDocId(DocuUniqueId: any, DocumentID: any, MasterDocumentId: any){
  //   this.utils.GetFileUrlByUniqueDocId(DocuUniqueId, DocumentID, MasterDocumentId, this.ClaimDetail)
  // }

  GetFileUrlByUniqueDocId(DocuUniqueId: any, DocumentID: any, MasterDocumentId: any, EncDocuUniqueId: any, Url:any = null) {

    let GetFileUrlByUniqueDocIdRequest = {
      docId: DocuUniqueId,
      custId: this.ClaimDetail.CustomerId,
      documentId: typeof(DocumentID)!='number'?0:DocumentID,
      masterDocumentId: typeof(MasterDocumentId)!='number'?0:MasterDocumentId,
      EncDocuUniqueId: EncDocuUniqueId

    }

    var reqData = GetFileUrlByUniqueDocIdRequest;
    this.commonService.GetFileUrlByUniqueDocId(reqData).subscribe(response => {
      if (response && response.Data) {
        this.ttLlDocUrl = this.sanitizer.bypassSecurityTrustResourceUrl(response.Data);
        window.open(response.Data, '_blank');
      }
      else if (this.ClaimDetail.DocumentUrl && DocumentID == 58)
        window.open(this.ClaimDetail.DocumentUrl, '_blank');
      else if (Url)
        window.open(Url, '_blank');
      else {
        alert("Error : Something went wrong!");
      }

    })
  }


  GetFileUrlByUniqueDocIdForDoc(DocuUniqueId: any, DocumentID: any, MasterDocumentId: any, EncDocuUniqueId:any, Url?:any) {
    if (!DocuUniqueId)
      window.open(Url, '_blank');
    else
      this.GetFileUrlByUniqueDocId(DocuUniqueId, DocumentID, MasterDocumentId, EncDocuUniqueId,Url);
  }


  SetInsurerDetails(){
    console.log(`this.addClaimIdForm: ${this.addClaimIdForm}`)

    if (this.addClaimIdForm.controls['IntimationDate'].value == "" || this.addClaimIdForm.controls['IntimationDate'].value == 'undefined') {
      this.addClaimIdForm.patchValue({
        IntimationDate: new Date(this.ClaimDetail.ClaimRequestDate)
      })
  }

  let reqData = {
    ClaimId: this.ClaimDetail.ClaimId,
    InsurerClaimId: this.addClaimIdForm.controls['InsurerClaimId'].value,
    IntimationDate: this.addClaimIdForm.controls['IntimationDate'].value,
    CreatedBy: this.LoginDetails.UserId
  }

  this.surveyorService.UpdateClaimInsurerDetails(reqData).subscribe((res=>{
    if(res.Data){
      this.GetClaimDetails()
      alert("Saved Successfully!");

      this.matDialog.closeAll();
    }
    else{
      this.ErrorMsg = "Error: Something Went Wrong!"
    }
  }))

  }
  openAddClaimIdModal(){
    this.matDialog.open(this.addClaimId, {width: '80%'})
  }
  closeAddClaimIdModal(){
    this.matDialog.closeAll()
  }
  updateDateChange(event?:any){
    console.log(event)
    // this.addClaimIdForm.upda
    this.addClaimIdForm.patchValue({
      IntimationDate: new Date(event)
    })

  }


}
