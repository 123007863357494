<div class="login-box">
  <div class="login-box-body login_container">
      <span class="login_heading">Claims Management System</span>
      <form [formGroup]="UserNameForm" *ngIf="userForm">
          <div class="frm_grp">
              <div class="form-group has-feedback">
                  <input type="text" name="UserName" class="form-control" placeholder="User Name" formControlName="UserName" required (keypress)="enterKeyPressed($event, 'userNameForm')">
              </div>
              <div class="row login_btn">
                  <button class="btn btn-primary btn-block btn-flat sign_in" [ngClass]="{'small_loader': otpSent}" [disabled]="UserNameForm.invalid || disableOTPButtonOnSent" (click)="GetInsurerDetail()">{{OTPText}}</button>
              </div>
              <span>{{respMessage}}</span>
          </div>
      </form>
      <form [formGroup]="OTPForm" *ngIf="!userForm">
          <div class="frm_grp">
            <div class="form-group has-feedback" *ngIf="OtpData?.MaskMobileNo && OtpData?.MaskMobileNo!=0">
              One Time Password sent to your registered mobile number {{OtpData?.MaskMobileNo}}
            </div>
            <div class="form-group has-feedback" *ngIf="OtpData?.MaskMobileNo && OtpData?.MaskMobileNo<=0">
              One Time Password sent to your Email {{OtpData?.MaskEmail}}
            </div>
              <div class="form-group has-feedback">
                  <input type="text" name="otp" class="form-control" placeholder="Enter OTP" formControlName="otp" required (keypress)="enterKeyPressed($event, 'otpForm')">
              </div>
              <div class="row login_btn">
                <button class="btn btn-primary sign_in" [ngClass]="{'small_loader': otpVerify}" [disabled]="OTPForm.invalid" (click)="VerifyOTP()">Submit</button>
                <button class="btn btn-primary sign_in" [ngClass]="{'small_loader': otpSent}" [disabled]="UserNameForm.invalid || disableOTPButtonOnSent" (click)="GetInsurerDetail()">{{OTPText}}</button>

              </div>
              <span>{{respMessage}}</span>
          </div>
      </form>
  </div>
</div>
