import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { TableModule } from 'primeng/table';
import { BlockUIModule } from 'primeng/blockui';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { ButtonModule } from 'primeng/button';
import { ToastModule } from 'primeng/toast';
import { CheckboxModule } from 'primeng/checkbox';
import { RadioButtonModule } from 'primeng/radiobutton';
import { InputNumberModule } from 'primeng/inputnumber';
import { FileUploadModule } from 'primeng/fileupload';
import { CalendarModule } from 'primeng/calendar';
import { ConfirmPopupModule } from 'primeng/confirmpopup';
import { TooltipModule } from 'primeng/tooltip';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { InputSwitchModule } from 'primeng/inputswitch';
import { TabMenuModule } from 'primeng/tabmenu';
import { TabViewModule } from 'primeng/tabview';
import { BadgeModule } from 'primeng/badge';
import { ChipModule } from 'primeng/chip';
import {PaginatorModule} from 'primeng/paginator';
import { AccordionModule } from 'primeng/accordion';

const primelModules = [
  TableModule,
  BlockUIModule,
  ProgressSpinnerModule,
  DialogModule,
  DropdownModule,
  InputTextModule,
  ButtonModule,
  ToastModule,
  CheckboxModule,
  RadioButtonModule,
  InputNumberModule,
  FileUploadModule,
  CalendarModule,
  ConfirmPopupModule,
  TooltipModule,
  ConfirmDialogModule,
  InputSwitchModule,
  TabMenuModule,
  TabViewModule,
  BadgeModule,
  ChipModule,
  PaginatorModule,
  AccordionModule
];

@NgModule({
  imports: [
    CommonModule,
    ...primelModules
  ],
  exports: [
    ...primelModules
  ],
})

export class PrimeNgModule { }
