<app-header [LoginDetails]="LoginDetails"></app-header>
<div class="content-wrapper">
  <section class="content-header">
    <h1> Case Details ({{RefIdText}}{{ClaimId}})</h1>
  </section>
  <section class="content dashboard_table">
    <div class="row">
      <div class="col-md-9 col-xs-12">
        <div class="box box-default">
          <div class="box-header with-border">
            <div class="col-md-4 col-xs-12 data_block">
              <ul class="list-group list-group-unbordered customer_data">
                <li class="list-group-item customer_data">
                    <b>Name</b> <a class="pull-right-data">{{ClaimDetail.Name}}</a>
                </li>
                <li class="list-group-item customer_data" *ngIf="RefIdText=='PB'">
                    <b>LeadID</b> <a class="pull-right-data">{{ClaimDetail.LeadId}}</a>
                </li>
                <li class="list-group-item customer_data">
                    <b>Status</b>
                        <!--<a *ngIf="ClaimDetail.DocumentUrl!=''" ng-href="{{ClaimDetail.DocumentUrl}}" target="_blank" class="pull-right-data" style="margin:0 0 0 5px;color:#ff0000;">
                          <i class="fa fa-file-pdf-o"></i>
                        </a>-->
                        <!-- <a *ngIf="ClaimDetail.DocumentUrl!=''" (click)="GetFileUrlByUniqueDocId(ClaimDetail.DocUniqueId,58,'')" class="pull-right-data" style="margin:0 0 0 5px;color:#ff0000;">
                            <i class="fa fa-file-pdf-o"></i>
                        </a> -->
                        <a *ngIf="ClaimDetail.DocUniqueId!=''" (click)="GetFileUrlByUniqueDocIdForDoc(ClaimDetail.DocUniqueId,58,'','',ClaimDetail.DocumentUrl)" class="pull-right-data" style="margin:0 0 0 5px;color:#ff0000;">
                            <i class="fa fa-file-pdf-o"></i>
                        </a>
                    <a class="pull-right-data">{{ClaimDetail.StatusName}}</a>
                </li>
                <li class="list-group-item customer_data">
                    <b>Request Type</b> <a class="pull-right-data">{{ClaimDetail.SubRequestType}}</a>
                </li>
              </ul>
            </div>
            <div class="col-md-4 col-xs-12 data_block">
              <ul class="list-group list-group-unbordered customer_data">
                  <li class="list-group-item customer_data">
                      <b>Preferred Mobile</b> <a class="pull-right-data">{{ClaimDetail.MaskMobile}}</a>
                      <!--<a ng-click="Call(ClaimDetail.Mobile,'C');"><i class="fa fa-phone-square call_icon call_cust" aria-hidden="true"></i></a>-->
                  </li>
                  <!--<li class="list-group-item customer_data">
                      <b>Alternate Phone</b> <a class="pull-right-data">{{ClaimDetail.MaskAlternateNumber}}</a>
                      <a ng-click="Call(ClaimDetail.AlternateNumber,'C');"><i class="fa fa-phone-square call_icon call_cust" aria-hidden="true"></i></a>
                  </li>-->
                  <li class="list-group-item customer_data">
                      <b>Registration No.</b> <a class="pull-right-data">{{ClaimDetail.RegistrationNo}}</a>
                      &nbsp;
                  </li>
                  <li class="list-group-item customer_data">
                      <b>Sub Status</b> <a class="pull-right-data" title="{{ClaimDetail.SubStatusName}}">{{ClaimDetail.SubStatusName }}</a>
                  </li>
                  <!-- <li class="list-group-item customer_data" *ngIf="RefIdText=='PB' && ClaimDetail.Endorsement.length>0">
                      <b>Endorsement Doc</b>
                      <div *ngIf="ClaimDetail.Endorsement.length>0">
                        <a *ngFor="let EndorseDocs of ClaimDetail.Endorsement" [href]="{{EndorseDocs.EndorsementDoc}}" target="_blank" class="pull-right-data" style="margin:0 0 0 5px;color:#ff0000;">
                          <i class="fa fa-file-pdf-o"></i>
                      </a>
                      </div>
                  </li> -->
              </ul>
          </div>
          <div class="col-md-4 col-xs-12 data_block">
              <ul class="list-group list-group-unbordered customer_data">
                  <li class="list-group-item customer_data">
                      <b>Email</b> <a class="pull-right-data">{{ClaimDetail.Email}}</a>
                  </li>
                  <li class="list-group-item customer_data">
                      <b>Insurer ClaimID</b>
                      <a class="pull-right-data" *ngIf="ClaimDetail.InsurerClaimId!=''">{{ClaimDetail.InsurerClaimId}}</a>
                      <a class="pull-right-data" style="color: #00a65a;" data-toggle="modal" data-target="#modal-claimId" *ngIf="ClaimDetail.InsurerClaimId==''" (click)="openAddClaimIdModal()">Add Claim ID</a>
                  </li>
                  <li class="list-group-item customer_data">
                      <b>Intimation Date</b>
                      <a class="pull-right-data">{{(ClaimDetail.IntimationDate>0) ? (ClaimDetail.IntimationDate | date:'d MMM y'):(ClaimDetail.ClaimRequestDate | date:'d MMM y')}}</a>
                  </li>
              </ul>
          </div>
          </div>
        </div>
        <app-videos [LoginDetails]="LoginDetails" [ClaimDetail]="ClaimDetail"></app-videos>
        <app-estimate-list [LoginDetails]="LoginDetails" [ClaimDetail]="ClaimDetail"></app-estimate-list>
        <app-customer-documents [isPreviousClaims]="true" [LoginDetails]="LoginDetails" [ClaimDetail]="ClaimDetail"></app-customer-documents>
        <app-customer-documents [LoginDetails]="LoginDetails" [ClaimDetail]="ClaimDetail"></app-customer-documents>
        <app-upload-report [LoginDetails]="LoginDetails" [ClaimDetail]="ClaimDetail"></app-upload-report>
        <!-- <app-payment-details [LoginDetails]="LoginDetails" [ClaimDetail]="ClaimDetail"></app-payment-details> -->
        <app-garage-details [LoginDetails]="LoginDetails" [ClaimDetail]="ClaimDetail"></app-garage-details>
        <app-garage-documents [LoginDetails]="LoginDetails" [ClaimDetail]="ClaimDetail"></app-garage-documents>
      </div>
      <div class="col-md-3 col-md-6 col-xs-12">
        <app-status-log [LoginDetails]="LoginDetails" [ClaimDetail]="ClaimDetail" (GetClaimDetails)="GetClaimDetails()"></app-status-log>
        <app-comment-log [LoginDetails]="LoginDetails" [ClaimDetail]="ClaimDetail"></app-comment-log>
        <app-previous-year-policy [LoginDetails]="LoginDetails" [ClaimDetail]="ClaimDetail"></app-previous-year-policy>

      </div>
    </div>
  </section>


</div>

<ng-template #addClaimId>
  <form [formGroup]="addClaimIdForm">
    <div class="col-xs-12 form_block">
      <div class="form-group">
          <label>Insurer Claim ID</label>
          <span class="data_mode"> <input type="text" class="form-control" placeholder="Enter Claim ID" formControlName="InsurerClaimId"></span>
      </div>
      <div class="form-group">
        <label>Date</label>
        <mat-form-field class="dateBorder intimation_filter_block" appearance="fill">

          <input matInput [matDatepicker]="picker" formControlName="IntimateDate" required placeholder="Enter Intimation Date" class="intimationDate_placeholder" [readonly]="true" (dateChange)="updateDateChange($event.value)">
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
      </div>
      <div class="form-group">
          <label>&nbsp;</label>
          <p >
              <button class="btn btn-primary submit_nw_btn fix_btn" role="button" (click)="SetInsurerDetails();">Save</button>
              <button class="btn btn-default submit_nw_btn fix_btn" role="button" (click)="closeAddClaimIdModal()">Cancel</button>
          </p>
      </div>
    </div>
  </form>

</ng-template>
