<div class="box box-primary">
  <div class="box-header with-border">
      <h3 class="box-title">Status Log</h3>
  </div>
  <div class="box-body">
    <form [formGroup]="statusLogForm">
      <div class="form-group">
        <span class="data_mode">
            <!-- <select class="form-control select2" name="Status" formControlName="status" required
                    ng-options="StatusMaster.StatusName for StatusMaster in StatusMasters | unique : 'StatusId' track by StatusMaster.StatusId">
                <option value="">Select</option>
            </select> -->
            <select class="form-control select2" name="Status" formControlName="status" required (change)="statusChange($event)">
                <option value="">Select Status</option>
                <option *ngFor="let statM of status; trackBy:trackByFn" [value]="statM.StatusId">{{statM.StatusName}}</option>
            </select>
        </span>
      </div>
      <div class="form-group">
          <span class="data_mode">
              <!-- <select class="form-control select2" name="SubStatus" formControlName="subStatus" required [disabled]="!statusLogForm.controls['status'].value"
                      ng-options="StatusMaster.SubStatusName for StatusMaster in StatusMasters | filter:{StatusId: SelectedStatus.StatusId}:true track by StatusMaster.SubStatusId">
                  <option value="">Select</option>
              </select> -->
              <select class="form-control select2" name="SubStatus" formControlName="subStatus" required>
                  <option value="" selected>Select Sub Status</option>
                  <option *ngFor="let statM of subStatus" [value]="statM.SubStatusId">{{statM.SubStatusName}}</option>
              </select>
          </span>
      </div>
    </form>

      <a  class="btn btn-primary btn-block" (click)="UpdateStatus();"><b>Update Status</b></a>
  </div>
</div>
