<div class="m-12">
    <div class="row right-padding-1" *ngIf="isSearchable">
        <div [ngClass]="fullWidthSearch ? 'col-lg-12 col-md-12 col-sm-12 col-xs-12 ms-auto' : 'col-lg-2 col-md-2 col-sm-12 col-xs-12 ms-auto'">
            <mat-form-field appearance="outline" class="width-100">
                <mat-label>Search</mat-label>
                <input matInput [formControl]="tableSearch">
            </mat-form-field>
        </div>
    </div>
  <table *ngIf="sortingActive" mat-table id="dataTable" [dataSource]="tableDataNew"
      [ngClass]="isScroll ? 'smaller-font-table mat-table-scroll' : 'smaller-font-table mat-table-no-scroll'"
      matSort [matSortActive]="sortByColumn ? sortByColumn : finalColumns[0]" matSortDisableClear [matSortDirection]="sortDirection">
      <ng-container *ngFor="let column of finalColumns; let j = index" [matColumnDef]="column">
          <th mat-header-cell *matHeaderCellDef [hidden]="checkLabels(labels[j], 'hidden')"
              [mat-sort-header]="checkLabels(labels[j], ['check','button', 'icon']) ? null : column"
              [disabled]="checkLabels(labels[j], ['button', 'icon']) ? true : false" disableClear class="table-column-100">
              {{checkColumn(labels[j])[0]}}<mat-checkbox (change)="changeAll($event)" id="allCheck"
                  class="table-check" *ngIf="checkLabels(labels[j], 'check')" [checked]="allCheck"></mat-checkbox>
          </th>
          <div *ngIf="!checkLabels(labels[j], ['icon', 'button', 'check', 'multi'])">
              <td mat-cell class="table-column-100" *matCellDef="let element; let i = index" [hidden]="checkLabels(labels[j], 'hidden')">
                  <a class="action-anchor" (click)="linkClick(element)" *ngIf="checkLabels(labels[j], 'link')">
                      <span [ngClass]="checkLabels(labels[j], 'label') ? 'label' : ''">
                      {{
                          checkAvail(this.displayColumns[j]) == 'object' ?
                          checkLabels(labels[j], 'date') ?
                          (element[this.displayColumns[j][0]] | date : dateFormat) + " - " + (element[this.displayColumns[j][1]] | date : dateFormat) :
                          element[this.displayColumns[j][0]] + " (" + element[this.displayColumns[j][1]] + ")" :
                          element[column]?.toString() ?
                          checkLabels(labels[j], 'date') ? element[column] > 0 ? (element[column] | date : dateFormat) : "--" :
                          element[column] :
                          "--"
                      }}</span></a>
                  <span *ngIf="!checkLabels(labels[j], 'link')"
                      [ngClass]="checkLabels(labels[j], 'label') ? 'label' : ''">
                      {{
                          checkAvail(this.displayColumns[j]) == 'object' ?
                          checkLabels(labels[j], 'date') ?
                          (element[this.displayColumns[j][0]] | date : dateFormat) + " - " + (element[this.displayColumns[j][1]] | date : dateFormat) :
                          element[this.displayColumns[j][0]] + " (" + element[this.displayColumns[j][1]] + ")" :
                          element[column]?.toString() ?
                          checkLabels(labels[j], 'date') ? element[column] > 0 ? (element[column] | date : dateFormat): "--" :
                          element[column] :
                          "--"
                      }}</span>
              </td>
          </div>
          <div *ngIf="checkLabels(labels[j], 'icon')">
              <td mat-cell class="table-column-150" *matCellDef="let element; let l = index">
                <div *ngIf="!checkLabels(labels[j], 'condition')">
                    <span *ngIf="extraLabelData[column].length > 3">
                        {{
                            checkAvail(this.displayColumns[j]) == 'object' ?
                            checkLabels(labels[j], 'date') ?
                            (element[this.displayColumns[j][0]] | date : dateFormat) + " - " + (element[this.displayColumns[j][1]] | date : dateFormat) :
                            element[this.displayColumns[j][0]] + " (" + element[this.displayColumns[j][1]] + ")" :
                            element[column]?.toString() ?
                            checkLabels(labels[j], 'date') ? element[column] > 0 ? (element[column] | date : dateFormat): "--" :
                            element[column] :
                            "--"
                        }}
                    </span>
                    <a
                    *ngIf="extraLabelData[column].length > 3 ? element[extraLabelData[column][extraLabelData[column].length-2]] : true"
                    (click)="iconClick(element, labels[j])"
                    class="action-anchor"><mat-icon>{{extraLabelData[column][extraLabelData[column].length-1]}}</mat-icon></a>
                </div>
                <div *ngIf="checkLabels(labels[j], 'condition')">
                    <div [ngSwitch]="extraLabelData[column].length > 3">
                        <div *ngSwitchCase="true">
                            <div *ngIf="element[extraLabelData[column][extraLabelData[column].length-2]]">
                                <a
                                (click)="iconClick(element, labels[j])"
                                class="action-anchor"><mat-icon>{{extraLabelData[column][extraLabelData[column].length-1]}}</mat-icon></a>
                            </div>
                            <div *ngIf="!element[extraLabelData[column][extraLabelData[column].length-2]]">
                                <mat-icon>{{extraLabelData[column][extraLabelData[column].length-1]}}</mat-icon>
                            </div>
                        </div>
                        <div *ngSwitchCase="false">
                            <a
                            *ngIf="extraLabelData[column].length > 3 ? element[extraLabelData[column][extraLabelData[column].length-2]] : true"
                            (click)="iconClick(element, labels[j])"
                            class="action-anchor"><mat-icon>{{extraLabelData[column][extraLabelData[column].length-1]}}</mat-icon></a>
                        </div>
                    </div>

                </div>

              </td>
          </div>
          <div *ngIf="checkLabels(labels[j], 'check')">
              <td mat-cell class="table-column-100" *matCellDef="let element; let l = index"><mat-checkbox
                      (change)="selectionChange($event, element)" [checked]="element.isSelected"></mat-checkbox>
              </td>
          </div>
          <div *ngIf="checkLabels(labels[j], 'multi')">
              <td mat-cell class="table-column-100" *matCellDef="let element; let l = index">
                  <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 align-left" *ngFor="let item of element[column]; let k = index">
                      <mat-checkbox *ngIf="checkLabels(labels[j], 'multiselect')"
                      (change)="selectionChange($event, element, true, k)" [checked]="element.isSelected[k]">{{item}}</mat-checkbox>
                      <span *ngIf="!checkLabels(labels[j], 'multiselect')">{{item}}</span>
                  </div>
              </td>
          </div>
          <div *ngIf="checkLabels(labels[j], 'button')">
              <td mat-cell class="table-column-100" *matCellDef="let element; let l = index">
                  <button mat-raised-button class="color-primary" (click)="buttonClicked(element, labels[j])" [disabled]="checkLabels(labels[j], 'condition') ? element[extraLabelData[column][extraLabelData[column].length-2]] : false">{{extraLabelData[column][extraLabelData[column].length-1]}}</button>
              </td>
          </div>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="finalColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: finalColumns"></tr>
  </table>
  <!-- No Sort Table -->
  <table *ngIf="!sortingActive" mat-table id="dataTable" [dataSource]="tableDataNew"
  [ngClass]="isScroll ? 'smaller-font-table mat-table-scroll' : 'smaller-font-table mat-table-no-scroll'">
  <ng-container *ngFor="let column of finalColumns; let j = index" [matColumnDef]="column">
      <th mat-header-cell *matHeaderCellDef [hidden]="checkLabels(labels[j], 'hidden')" disableClear class="table-column-100">
          {{checkColumn(labels[j])[0]}}<mat-checkbox (change)="changeAll($event)" id="allCheck"
              class="table-check" *ngIf="checkLabels(labels[j], 'check')" [checked]="allCheck"></mat-checkbox>
      </th>
      <div *ngIf="!checkLabels(labels[j], ['icon', 'button', 'check', 'multi'])">
          <td mat-cell class="table-column-100" *matCellDef="let element; let i = index" [hidden]="checkLabels(labels[j], 'hidden')">
              <a class="action-anchor" (click)="linkClick(element)" *ngIf="checkLabels(labels[j], 'link')">
                  <span [ngClass]="checkLabels(labels[j], 'label') ? 'label' : ''">
                  {{
                      checkAvail(this.displayColumns[j]) == 'object' ?
                      checkLabels(labels[j], 'date') ?
                      (element[this.displayColumns[j][0]] | date : dateFormat) + " - " + (element[this.displayColumns[j][1]] | date : dateFormat) :
                      element[this.displayColumns[j][0]] + " (" + element[this.displayColumns[j][1]] + ")" :
                      element[column]?.toString() ?
                      checkLabels(labels[j], 'date') ? element[column] > 0 ? (element[column] | date : dateFormat) : "--" :
                      element[column] :
                      "--"
                  }}</span></a>
              <span *ngIf="!checkLabels(labels[j], 'link')"
                  [ngClass]="checkLabels(labels[j], 'label') ? 'label' : ''">
                  {{
                      checkAvail(this.displayColumns[j]) == 'object' ?
                      checkLabels(labels[j], 'date') ?
                      (element[this.displayColumns[j][0]] | date : dateFormat) + " - " + (element[this.displayColumns[j][1]] | date : dateFormat) :
                      element[this.displayColumns[j][0]] + " (" + element[this.displayColumns[j][1]] + ")" :
                      element[column]?.toString() ?
                      checkLabels(labels[j], 'date') ? element[column] > 0 ? (element[column] | date : dateFormat): "--" :
                      element[column] :
                      "--"
                  }}</span>
          </td>
      </div>
      <div *ngIf="checkLabels(labels[j], 'icon')">
          <td mat-cell class="table-column-150" *matCellDef="let element; let l = index">
            <div *ngIf="!checkLabels(labels[j], 'condition')">
                <span *ngIf="extraLabelData[column].length > 3">
                    {{
                        checkAvail(this.displayColumns[j]) == 'object' ?
                        checkLabels(labels[j], 'date') ?
                        (element[this.displayColumns[j][0]] | date : dateFormat) + " - " + (element[this.displayColumns[j][1]] | date : dateFormat) :
                        element[this.displayColumns[j][0]] + " (" + element[this.displayColumns[j][1]] + ")" :
                        element[column]?.toString() ?
                        checkLabels(labels[j], 'date') ? element[column] > 0 ? (element[column] | date : dateFormat): "--" :
                        element[column] :
                        "--"
                    }}
                </span>
                <a
                *ngIf="extraLabelData[column].length > 3 ? element[extraLabelData[column][extraLabelData[column].length-2]] : true"
                (click)="iconClick(element, labels[j])"
                class="action-anchor"><mat-icon>{{extraLabelData[column][extraLabelData[column].length-1]}}</mat-icon></a>
            </div>
            <div *ngIf="checkLabels(labels[j], 'condition')">
                <div [ngSwitch]="extraLabelData[column].length > 3">
                    <div *ngSwitchCase="true">
                        <div *ngIf="element[extraLabelData[column][extraLabelData[column].length-2]]">
                            <a
                            (click)="iconClick(element, labels[j])"
                            class="action-anchor"><mat-icon>{{extraLabelData[column][extraLabelData[column].length-1]}}</mat-icon></a>
                        </div>
                        <div *ngIf="!element[extraLabelData[column][extraLabelData[column].length-2]]">
                            <mat-icon>{{extraLabelData[column][extraLabelData[column].length-1]}}</mat-icon>
                        </div>
                    </div>
                    <div *ngSwitchCase="false">
                        <a
                        *ngIf="extraLabelData[column].length > 3 ? element[extraLabelData[column][extraLabelData[column].length-2]] : true"
                        (click)="iconClick(element, labels[j])"
                        class="action-anchor"><mat-icon>{{extraLabelData[column][extraLabelData[column].length-1]}}</mat-icon></a>
                    </div>
                </div>

            </div>

          </td>
      </div>
      <div *ngIf="checkLabels(labels[j], 'check')">
          <td mat-cell class="table-column-100" *matCellDef="let element; let l = index"><mat-checkbox
                  (change)="selectionChange($event, element)" [checked]="element.isSelected"></mat-checkbox>
          </td>
      </div>
      <div *ngIf="checkLabels(labels[j], 'multi')">
          <td mat-cell class="table-column-100" *matCellDef="let element; let l = index">
              <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 align-left" *ngFor="let item of element[column]; let k = index">
                  <mat-checkbox *ngIf="checkLabels(labels[j], 'multiselect')"
                  (change)="selectionChange($event, element, true, k)" [checked]="element.isSelected[k]">{{item}}</mat-checkbox>
                  <span *ngIf="!checkLabels(labels[j], 'multiselect')">{{item}}</span>
              </div>
          </td>
      </div>
      <div *ngIf="checkLabels(labels[j], 'button')">
          <td mat-cell class="table-column-100" *matCellDef="let element; let l = index">
              <button mat-raised-button class="color-primary" (click)="buttonClicked(element, labels[j])" [disabled]="checkLabels(labels[j], 'condition') ? element[extraLabelData[column][extraLabelData[column].length-2]] : false">{{extraLabelData[column][extraLabelData[column].length-1]}}</button>
          </td>
      </div>
  </ng-container>
  <tr mat-header-row *matHeaderRowDef="finalColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: finalColumns"></tr>
</table>
</div>
<mat-paginator *ngIf="isPagination && tableData" [pageSize]="maxPageSize ? pageSizeArray[pageSizeArray.length-1] : pageSizeArray[0]" [pageSizeOptions]="pageSizeArray" #paginator showFirstLastButtons
  aria-label="Select page of periodic elements" (page)="updatePageSize($event)"></mat-paginator>
<!-- Hidden Table For Export -->
<div class="m-12" *ngIf="exportFunction" [hidden]="true">
<table mat-table id="exportTable" [dataSource]="exportTableData"
  [ngClass]="isScroll ? 'smaller-font-table mat-table-scroll' : 'smaller-font-table mat-table-no-scroll'"
  matSort [matSortActive]="finalColumns[0]" matSortDisableClear matSortDirection="desc">
  <ng-container *ngFor="let column of finalColumns; let j = index" [matColumnDef]="column">
      <th mat-header-cell *matHeaderCellDef
          [mat-sort-header]="checkLabels(labels[j], 'check') ? null : column"
          [disabled]="checkLabels(labels[j], 'check') ? true : false" disableClear class="table-column-100">
          {{checkColumn(labels[j])[0]}}<mat-checkbox (change)="changeAll($event)" id="allCheck"
              class="table-check" *ngIf="checkLabels(labels[j], 'check')" [checked]="allCheck"></mat-checkbox>
      </th>
      <div *ngIf="!checkLabels(labels[j], 'icon') && !checkLabels(labels[j], 'check')">
          <td mat-cell class="table-column-100" *matCellDef="let element; let i = index">
              <a class="action-anchor" (click)="linkClick(element)" *ngIf="checkLabels(labels[j], 'link')">
                  <span [ngClass]="checkLabels(labels[j], 'label') ? 'label' : ''">
                      {{ checkAvail(this.displayColumns[j]) == 'object' ?
                      element[this.displayColumns[j][0]] + " (" + element[this.displayColumns[j][1]] + ")" :
                      element[column]?.toString() ?
                      checkLabels(labels[j], 'date') ? element[column] > 0 ? (element[column] | date : dateFormat) : "--" :
                      element[column] :
                      "--"
                      }}</span></a>
              <span *ngIf="!checkLabels(labels[j], 'link')"
                  [ngClass]="checkLabels(labels[j], 'label') ? 'label' : ''">
                  {{ checkAvail(this.displayColumns[j]) == 'object' ?
                  element[this.displayColumns[j][0]] + " (" + element[this.displayColumns[j][1]] + ")" :
                  element[column]?.toString() ?
                  checkLabels(labels[j], 'date') ? element[column] > 0 ? (element[column] | date : dateFormat) : "--" : element[column] :
                  "--"
                  }}</span>
          </td>
      </div>
      <div *ngIf="checkLabels(labels[j], 'icon')">
          <td mat-cell class="table-column-100" *matCellDef="let element; let l = index"><a
                  (click)="iconClick(element, labels[j])"
                  class="action-anchor"><mat-icon>{{extraLabelData[column]}}</mat-icon></a>
          </td>
      </div>
      <div *ngIf="checkLabels(labels[j], 'check')">
          <td mat-cell class="table-column-100" *matCellDef="let element; let l = index"><mat-checkbox
                  (change)="selectionChange($event, element)" [checked]="element.isSelected"></mat-checkbox>
          </td>
      </div>
  </ng-container>
  <tr mat-header-row *matHeaderRowDef="finalColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: finalColumns"></tr>
</table>
</div>