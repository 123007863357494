import { Component, OnInit, Input, SimpleChanges } from '@angular/core';
import { SurveyorService } from './../../../services/SurveyorService/surveyor.service';
import { SharedService } from './../../../services/SharedService/sharedService';

@Component({
  selector: 'app-garage-documents',
  templateUrl: './garage-documents.component.html',
  styleUrls: ['./garage-documents.component.css']
})
export class GarageDocumentsComponent implements OnInit {

  constructor(private surveyorService: SurveyorService, private sharedService: SharedService) { }
  @Input() LoginDetails: any;
  @Input() ClaimDetail: any;
  IsGarage: boolean = false;
  GarageDetails: any;
  GarageDocsLists: any;
  ErrorMsg: string = '';
  ngOnInit(): void {
    if(this.ClaimDetail.ClaimId){
      this.sharedService.garageDetailSubject$.subscribe(data => {
        if(data){
          this.GarageDetails = data;
        }
      });
    }
  }

  ngOnChanges(changes: SimpleChanges){
    if(changes['ClaimDetail']){
      this.sharedService.garageDetailSubject$.subscribe(data => {
        if(data){
          this.GarageDetails = data;
          this.IsGarage = true;
        }

      });
    }
  }

  GetGarageDocs() {

    this.surveyorService.GetGarageDocs(this.GarageDetails.GarageId).subscribe((res: any)=>{
      if (res && res.Data) {
        this.GarageDocsLists = res.Data;
        this.IsGarage = true;
    } else {
        this.ErrorMsg = "Error : Something went wrong!";
    }
    })
}



}
